// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__3khY2 {
    position: fixed;
    bottom: 0;
    right: 0;
}

.styles_container__3khY2 > img {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/numbers/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".container {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n}\n\n.container > img {\n    cursor: pointer;\n    user-select: none;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__3khY2`
};
export default ___CSS_LOADER_EXPORT___;
