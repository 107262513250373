import {createContext, useState} from "react";

const MainContext = createContext("");

export function ContextProvider({children}) {
    const [settings, setSettings] = useState({
        filters: true
    });

    return (
        <MainContext.Provider value={{setSettings, settings}}>
            {children}
        </MainContext.Provider>
    )
}

export default MainContext;