import React, {useEffect, useState} from 'react';
import styles from './index.module.css';

function Restaurant({data, region}) {
    return (
        <div className={styles.restaurantContainer}>
            <div className={styles.restaurantTitle}>
                <div>{data.name}</div>
            </div>
            <div className={styles.restaurantDescription}>
                <div className={'d-flex align-items-center mb-2'}>
                    <img src={'/decoration/arrow.png'} alt={'arrow'} height={'16px'}/>
                    <div className={'ps-2'}>{region}</div>
                </div>
                <div>
                    {data?.description}
                </div>
            </div>
            <div className={styles.restaurantImage}>
                <img src={`/${data?.photo}`} alt={data?.photo}/>
            </div>
        </div>
    )
}

export default function Restaurants({gastronomy}) {
    const [restaurants, setRestaurants] = useState(null);

    useEffect(() => {
        if(!gastronomy || Object.keys(gastronomy).length <= 0) {
            return;
        }
        console.log(gastronomy);
        setRestaurants(gastronomy.restaurants);
        console.log(gastronomy.restaurants);
            Object.keys(gastronomy.restaurants)?.map((data) => {
                console.log(data);
            });
    }, [gastronomy]);

    return (
        <div className={styles.container}>
            <svg width="1440" height="32" viewBox="0 0 1440 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="5" width="1440" height="6" fill="#BE6B1D"/>
                <rect width="1440" height="5" fill="#894320"/>
                <rect y="11" width="1440" height="8" fill="#94160B"/>
                <rect y="16" width="1440" height="3" fill="#B2281A"/>
                <rect y="19" width="1440" height="7" fill="#BE6B1D"/>
                <rect y="23" width="1440" height="9" fill="#173230"/>
                <rect y="23" width="1440" height="6" fill="#347A7D"/>
            </svg>

            <div className={styles.mainContainer}>
                {(restaurants && Object.keys(restaurants).length > 0) && Object.keys(gastronomy.restaurants)?.map((key, kIndex) => {
                    return (
                        <React.Fragment key={kIndex}>
                            {restaurants[key]?.data?.map((item, index) => {
                                return (<Restaurant data={item} key={index} region={restaurants[key]?.name}/>);
                            })}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}