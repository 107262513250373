import React, {useEffect} from 'react';
import Footer from "../../components/footer";
import Inquiries from "../../components/inquiries";
import styles from './index.module.css';
import {getString} from "../../localization";
import GastronomyAwards from "../../components/gastronomy_awards";
import Restaurants from "../../components/restaurants";
import Highlights from "../../components/highlights";
import Logo from "../../components/logo";
import LanguageContainer from "../../components/language_container";
import Menu from "../../components/menu";

export default function Gastronomy() {
    const [gastronomy, setGastronomy] = React.useState({});

    useEffect(() => {
        setGastronomy(getString("gastronomy"));
    }, []);

    return (
        <div>
            <div className={styles.header}>
                <img alt={'header'} src={'/gastronomy/header.png'}/>
                <div className={styles.logoContainer}>
                    <Logo width={'72px'}/>
                </div>
                <LanguageContainer/>
                <div className={styles.headerText}>{gastronomy?.title}</div>
                <Menu top={'90%'}/>
            </div>

            <GastronomyAwards/>
            <Restaurants gastronomy={gastronomy}/>
            <Inquiries/>
            <Footer/>
        </div>
    )
}