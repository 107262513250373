import React from 'react';
import styles from './styles.module.css';


export default function Numbers() {
    const phone_number = "51999336048"


    const wame = () => {
        window.open(`https://wa.me/${phone_number}`, '_blank');
    }

    return (
        <div className={styles.container}>
            <img alt={'wa'} src={'/WhatsApp_icon.png'} width={100} height={100} onClick={wame}/>
        </div>
    )
}