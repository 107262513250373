// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal_loading_container__7p93u {
    width: 192px;
    height: 4px;
    gap: 0;
    border-radius: 12px;
    background-color: var(--dark-text);
    position: relative;
}

.horizontal_loading_loading__WM-XB {
    width: 100%;
    height: 100%;
    background-color: var(--gold);
    border-radius: 12px;
    position: absolute;
    animation: horizontal_loading_loading__WM-XB 6s infinite;
}

@keyframes horizontal_loading_loading__WM-XB {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/horizontal_loading/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,MAAM;IACN,mBAAmB;IACnB,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;IAClB,wDAA8B;AAClC;;AAEA;IACI;QACI,QAAQ;IACZ;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".container {\n    width: 192px;\n    height: 4px;\n    gap: 0;\n    border-radius: 12px;\n    background-color: var(--dark-text);\n    position: relative;\n}\n\n.loading {\n    width: 100%;\n    height: 100%;\n    background-color: var(--gold);\n    border-radius: 12px;\n    position: absolute;\n    animation: loading 6s infinite;\n}\n\n@keyframes loading {\n    0% {\n        width: 0;\n    }\n    100% {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `horizontal_loading_container__7p93u`,
	"loading": `horizontal_loading_loading__WM-XB`
};
export default ___CSS_LOADER_EXPORT___;
