// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #2F120E;
  --secondary-color: #2A1411;
  --light-text: #F1BC6B;
  --dark-text: #302D24;
  --gold: #DAD3BE;
  --light-gold: rgb(200, 190, 152);
  --gold-text: #7B602D;
  --beige: #F0EDE3;
}

.App {
  text-align: center;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
  background-color: var(--main-color);
  font-family: "Mulish Variable", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,qBAAqB;EACrB,oBAAoB;EACpB,eAAe;EACf,gCAAgC;EAChC,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;EAGE,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;EACnC,0CAA0C;AAC5C","sourcesContent":[":root {\n  --main-color: #2F120E;\n  --secondary-color: #2A1411;\n  --light-text: #F1BC6B;\n  --dark-text: #302D24;\n  --gold: #DAD3BE;\n  --light-gold: rgb(200, 190, 152);\n  --gold-text: #7B602D;\n  --beige: #F0EDE3;\n}\n\n.App {\n  text-align: center;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow-x: hidden;\n  background-color: var(--main-color);\n  font-family: \"Mulish Variable\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
