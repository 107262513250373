import React, {useEffect} from 'react';
import styles from './index.module.css';
import {useNavigate} from "react-router-dom";
import {getString} from "../../localization";

export default function Menu({top}) {
    const navigate = useNavigate();
    const [menuData, setMenuData] = React.useState([]);

    useEffect(() => {
        setMenuData(getString("menu"));
    }, [])

    const goto = (route) => {
        navigate(route);
    }

    return (
        <div className={styles.mainContainer} style={{
            top: top ? top : 'auto',
        }}>
            <div className={styles.container}>
                {menuData?.map((item, index) => (
                    <div key={index} onClick={() => goto(item.url)}>{item.name}</div>
                ))}
            </div>
            <img src={'/decoration/menu_deco.png'} alt={'menu_deco.png'}/>
        </div>
    )
}
