import React from "react";

export default function TourSide() {
    return (
        <svg width="20" height="754" viewBox="0 0 20 754" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5948_2628)">
                <rect y="754" width="754" height="6" transform="rotate(-90 0 754)" fill="#94160B"/>
                <rect x="4" y="754" width="754" height="2" transform="rotate(-90 4 754)" fill="#B2281A"/>
                <rect x="6" y="754" width="754" height="5" transform="rotate(-90 6 754)" fill="#BE6B1D"/>
                <rect x="11" y="754" width="754" height="6" transform="rotate(-90 11 754)" fill="#225551"/>
                <rect x="11" y="754" width="754" height="4" transform="rotate(-90 11 754)" fill="#347A7D"/>
                <rect x="17" y="754" width="754" height="3" transform="rotate(-90 17 754)" fill="#A8261A"/>
            </g>
            <defs>
                <clipPath id="clip0_5948_2628">
                    <path d="M0 8.00002C0 3.58174 3.58172 0 8 0H20V754H8C3.58172 754 0 750.418 0 746V8.00002Z" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}