import React, {useState} from "react";
import styles from './index.module.css';

function TextInput({placeholder, value, onChange, style}) {
    return (
        <input type={'text'} className={styles.textInput} placeholder={placeholder} style={style} value={value}  onChange={(event) => {onChange(event.target.value)}}/>
    )
}

function TextArea({placeholder, value, onChange}) {
    return (
        <textarea className={styles.textInput} placeholder={placeholder} value={value} onChange={(event) => {onChange(event.target.value)}}/>
    )
}

function Title() {
    const [form, setForm] = useState('');

    const send = () => {
        console.log(form);
    }

    return (
        <div className={styles.titleContainer}>
            <div>HAVE A QUESTION?</div>
            <div>GENERAL INQUIRIES</div>
            <div style={{color: "#BB9281"}} className={'mb-5 mt-2'}>Thank you for visiting Tays. We invite you to share any questions you may have using the form below. We will follow up with you as soon as we can.</div>

            <div>
                <div className={'mb-4'}>
                    <div className={styles.inputLabel}>FULL NAME</div>
                    <TextInput placeholder={'Enter your name'} value={form['name']} onChange={(x) => {
                        setForm((prev) => {
                            return {
                                ...prev,
                                name: x
                            }
                        })
                    }}/>
                </div>
                <div className={'d-flex mb-4'}>
                    <div style={{
                        flexBasis: "48%",
                        marginRight: '4%'
                    }}>
                        <div className={styles.inputLabel}>EMAIL</div>
                        <TextInput placeholder={'Enter your email address'} value={form['email']} onChange={(x) => {
                            setForm((prev) => {
                                return {
                                    ...prev,
                                    email: x
                                }
                            })
                        }}/>
                    </div>
                    <div style={{
                        flexBasis: "48%",
                    }}>
                        <div className={styles.inputLabel}>PHONE NUMBER</div>
                        <TextInput placeholder={'Enter your phone number'} value={form['phone_number']} onChange={(x) => {
                            setForm((prev) => {
                                return {
                                    ...prev,
                                    phone_number: x
                                }
                            })
                        }}/>
                    </div>
                </div>

                <div className={'mb-4'}>
                    <div className={styles.inputLabel}>PHONE NUMBER</div>
                    <TextArea placeholder={'Enter your message'} value={form['message']} onChange={(x) => {
                        setForm((prev) => {
                            return {
                                ...prev,
                                message: x
                            }
                        })
                    }}/>
                </div>

                <div className={'d-flex'}>
                    <div className={styles.formButton} onClick={send}>
                        <div className={'pe-2'}>Send</div>
                        <img className={'ms-1'} alt={'arrow'} src={'/decoration/dark_arrow.png'} width={'24px'} height={'20px'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Inquiries() {
    return (
        <div>
            <svg width="1440" height="65" viewBox="0 0 1440 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-41.5" y="0.5" width="1524" height="64" fill="#2A1411"/>
                <rect x="-41.5" y="0.5" width="1524" height="64" stroke="#69321B"/>
                <rect x="14" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="-23" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="-23" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="-16" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="-16" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="28" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="72" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="35" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="35" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="42" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="42" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="42" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="86" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="130" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="93" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="93" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="100" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="100" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="100" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="144" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="188" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="151" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="151" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="158" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="158" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="158" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="202" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="246" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="209" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="209" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="216" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="216" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="216" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="260" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="304" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="267" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="267" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="274" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="274" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="274" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="318" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="362" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="325" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="325" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="332" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="332" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="332" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="376" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="420" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="383" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="383" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="390" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="390" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="390" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="434" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="478" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="441" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="441" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="448" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="448" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="448" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="492" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="536" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="499" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="499" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="506" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="506" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="506" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="550" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="594" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="557" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="557" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="564" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="564" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="564" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="608" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="652" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="615" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="615" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="622" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="622" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="622" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="666" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="710" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="673" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="673" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="680" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="680" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="680" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="724" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="768" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="731" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="731" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="738" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="738" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="738" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="782" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="826" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="789" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="789" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="796" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="796" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="796" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="840" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="884" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="847" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="847" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="854" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="854" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="854" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="898" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="942" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="905" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="905" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="912" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="912" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="912" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="956" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1000" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="963" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="963" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="970" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="970" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="970" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1014" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1058" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1021" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1021" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1028" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1028" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1028" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1072" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1116" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1079" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1079" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1086" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1086" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1086" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1130" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1174" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1137" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1137" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1144" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1144" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1144" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1188" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1232" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1195" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1195" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1202" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1202" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1202" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1246" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1290" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1253" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1253" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1260" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1260" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1260" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1304" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1348" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1311" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1311" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1318" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1318" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1318" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1362" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1406" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1369" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1369" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1376" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1376" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1376" y="42" width="7" height="9" fill="#69321B"/>
                <rect x="1420" y="7" width="7" height="51" fill="#69321B"/>
                <rect x="1427" y="7" width="30" height="7" fill="#69321B"/>
                <rect x="1427" y="21" width="37" height="7" fill="#69321B"/>
                <rect x="1434" y="35" width="30" height="7" fill="#69321B"/>
                <rect x="1434" y="51" width="23" height="7" fill="#69321B"/>
                <rect x="1434" y="42" width="7" height="9" fill="#69321B"/>
            </svg>

            <div className={styles.container}>
                <div className={'px-3'}>
                    <svg width="77" height="712" viewBox="0 0 77 712" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.68655 34.0412C-1.5317 37.3619 2.39894 112.654 2.73779 118.889C17.3082 109.401 31.5399 99.371 45.2971 88.799C46.3136 83.8518 7.41388 38.3107 3.68655 34.0412Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M39.2646 78.0899C13.0378 97.6753 13.4444 97.743 12.699 97.0653C11.818 96.2521 9.78487 75.0402 10.6659 57.0135C15.2064 59.8599 16.4263 66.1624 26.8628 75.6502C24.5586 77.6833 22.0512 79.513 19.4081 81.0039" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M32.9725 160.778C5.97228 179.282 6.37582 179.367 5.66123 178.592C4.81396 177.743 3.6408 156.534 5.25554 138.49C9.67683 141.519 10.6371 147.933 20.6799 157.837C18.2951 159.775 15.7182 161.433 13.0141 162.883" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M55.9325 64.5968C50.782 34.6427 56.4068 44.266 42.1075 54.2281C44.1406 58.8364 50.7142 77.6764 52.7473 76.8631C64.0648 72.3903 63.7937 71.7804 63.726 69.6796C63.1838 57.6166 64.9458 43.6561 62.0995 31.9997C35.1272 32.203 38.5157 31.7286 15.4062 32.8129C42.0397 54.7702 32.9586 51.0429 49.02 40.6064C42.9885 38.3022 36.076 38.2345 29.8412 36.5402" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M60.9325 152.291C55.782 182.246 61.4068 172.622 47.1075 162.728C49.1406 158.052 55.7142 139.212 57.7473 140.025C69.0648 144.498 68.7937 145.108 68.726 147.277C68.1838 159.272 69.9458 173.232 67.0995 184.889C40.1272 184.685 43.5157 185.227 20.4062 184.143C47.0397 162.118 37.9586 165.913 54.02 176.282C47.9885 178.586 41.076 178.722 34.8412 180.416" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M41.5824 138.093C45.5671 135.105 45.1947 133.156 43.1665 130.682C26.5029 110.305 29.4345 114.497 18.757 121.904C8.53564 128.948 3.93911 126.675 17.4446 141.108C19.5977 143.372 22.3192 147.438 25.6028 147.947C31.6893 148.853 37.2699 141.303 41.5824 138.093Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.1351 126.039C23.324 124.864 20.4877 130.371 22.3224 134.348C27.3782 145.487 39.894 129.027 28.1351 126.039Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M64.022 134.889C62.1038 133.193 35.8667 109.541 36.4147 107.432C36.7572 106.103 65.9401 87.4471 68.4062 85.8887C67.6755 98.6314 67.4243 111.374 67.6527 124.117C60.7566 119.228 54.0432 114.231 47.5124 109.128C51.7597 106.699 55.5959 103.949 58.9527 100.969" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.68655 347.736C-1.5317 344.415 2.39894 269.123 2.73779 262.889C17.3082 272.376 31.5399 282.406 45.2971 292.978C46.3136 297.926 7.41388 343.467 3.68655 347.736Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M39.2646 303.687C13.0378 284.102 13.4444 284.034 12.699 284.712C11.818 285.525 9.78487 306.737 10.6659 324.764C15.2064 321.917 16.4263 315.615 26.8628 306.127C24.5586 304.094 22.0512 302.264 19.4081 300.773" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M32.9725 221C5.97228 202.495 6.37582 202.411 5.66123 203.186C4.81396 204.034 3.6408 225.243 5.25554 243.287C9.67683 240.259 10.6371 233.844 20.6799 223.941C18.2951 222.003 15.7182 220.344 13.0141 218.894" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M55.9325 317.18C50.782 347.135 56.4068 337.511 42.1075 327.549C44.1406 322.941 50.7142 304.101 52.7473 304.914C64.0648 309.387 63.7937 309.997 63.726 312.098C63.1838 324.161 64.9458 338.121 62.0995 349.778C35.1272 349.574 38.5157 350.049 15.4062 348.964C42.0397 327.007 32.9586 330.734 49.02 341.171C42.9885 343.475 36.076 343.543 29.8412 345.237" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M60.9325 229.486C55.782 199.532 61.4068 209.155 47.1075 219.049C49.1406 223.725 55.7142 242.565 57.7473 241.752C69.0648 237.279 68.7937 236.669 68.726 234.501C68.1838 222.506 69.9458 208.545 67.0995 196.889C40.1272 197.092 43.5157 196.55 20.4062 197.634C47.0397 219.659 37.9586 215.864 54.02 205.495C47.9885 203.191 41.076 203.056 34.8412 201.361" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M41.5824 243.684C45.5671 246.673 45.1947 248.621 43.1665 251.095C26.5029 271.472 29.4345 267.281 18.757 259.874C8.53564 252.83 3.93911 255.102 17.4446 240.669C19.5977 238.406 22.3192 234.339 25.6028 233.831C31.6893 232.924 37.2699 240.475 41.5824 243.684Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.1351 255.738C23.324 256.914 20.4877 251.406 22.3224 247.429C27.3782 236.291 39.894 252.751 28.1351 255.738Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M64.022 246.889C62.1038 248.585 35.8667 272.237 36.4147 274.345C36.7572 275.674 65.9401 294.33 68.4062 295.889C67.6755 283.146 67.4243 270.403 67.6527 257.66C60.7566 262.55 54.0432 267.546 47.5124 272.649C51.7597 275.079 55.5959 277.829 58.9527 280.808" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.68655 363.819C-1.5317 367.14 2.39894 442.432 2.73779 448.667C17.3082 439.179 31.5399 429.149 45.2971 418.577C46.3136 413.63 7.41388 368.089 3.68655 363.819Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M39.2646 407.868C13.0378 427.454 13.4444 427.521 12.699 426.844C11.818 426.03 9.78487 404.819 10.6659 386.792C15.2064 389.638 16.4263 395.941 26.8628 405.428C24.5586 407.462 22.0512 409.291 19.4081 410.782" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M32.9725 490.556C5.97228 509.061 6.37582 509.145 5.66123 508.37C4.81396 507.522 3.6408 486.312 5.25554 468.269C9.67683 471.297 10.6371 477.711 20.6799 487.615C18.2951 489.553 15.7182 491.212 13.0141 492.662" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M55.9325 394.375C50.782 364.421 56.4068 374.044 42.1075 384.006C44.1406 388.615 50.7142 407.455 52.7473 406.641C64.0648 402.169 63.7937 401.559 63.726 399.458C63.1838 387.395 64.9458 373.434 62.0995 361.778C35.1272 361.981 38.5157 361.507 15.4062 362.591C42.0397 384.549 32.9586 380.821 49.02 370.385C42.9885 368.081 36.076 368.013 29.8412 366.319" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M60.9325 482.07C55.782 512.024 61.4068 502.401 47.1075 492.506C49.1406 487.83 55.7142 468.99 57.7473 469.804C69.0648 474.276 68.7937 474.886 68.726 477.055C68.1838 489.05 69.9458 503.011 67.0995 514.667C40.1272 514.464 43.5157 515.006 20.4062 513.922C47.0397 491.896 37.9586 495.692 54.02 506.06C47.9885 508.364 41.076 508.5 34.8412 510.194" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M41.5824 467.872C45.5671 464.883 45.1947 462.934 43.1665 460.461C26.5029 440.084 29.4345 444.275 18.757 451.682C8.53564 458.726 3.93911 456.453 17.4446 470.887C19.5977 473.15 22.3192 477.217 25.6028 477.725C31.6893 478.631 37.2699 471.081 41.5824 467.872Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.1351 455.818C23.324 454.642 20.4877 460.15 22.3224 464.126C27.3782 475.265 39.894 458.805 28.1351 455.818Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M64.022 464.667C62.1038 462.971 35.8667 439.319 36.4147 437.21C36.7572 435.881 65.9401 417.225 68.4062 415.667C67.6755 428.41 67.4243 441.152 67.6527 453.895C60.7566 449.006 54.0432 444.01 47.5124 438.906C51.7597 436.477 55.5959 433.727 58.9527 430.747" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.68655 677.514C-1.5317 674.194 2.39894 598.902 2.73779 592.667C17.3082 602.155 31.5399 612.185 45.2971 622.757C46.3136 627.704 7.41388 673.245 3.68655 677.514Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M39.2646 633.466C13.0378 613.88 13.4444 613.813 12.699 614.49C11.818 615.304 9.78487 636.515 10.6659 654.542C15.2064 651.696 16.4263 645.393 26.8628 635.905C24.5586 633.872 22.0512 632.043 19.4081 630.552" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M32.9725 550.778C5.97228 532.273 6.37582 532.189 5.66123 532.964C4.81396 533.812 3.6408 555.022 5.25554 573.065C9.67683 570.037 10.6371 563.623 20.6799 553.719C18.2951 551.781 15.7182 550.122 13.0141 548.672" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M55.9325 646.959C50.782 676.913 56.4068 667.29 42.1075 657.328C44.1406 652.719 50.7142 633.879 52.7473 634.693C64.0648 639.165 63.7937 639.775 63.726 641.876C63.1838 653.939 64.9458 667.9 62.0995 679.556C35.1272 679.353 38.5157 679.827 15.4062 678.743C42.0397 656.785 32.9586 660.513 49.02 670.949C42.9885 673.253 36.076 673.321 29.8412 675.015" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M60.9325 559.264C55.782 529.31 61.4068 538.933 47.1075 548.828C49.1406 553.504 55.7142 572.344 57.7473 571.53C69.0648 567.058 68.7937 566.448 68.726 564.279C68.1838 552.284 69.9458 538.323 67.0995 526.667C40.1272 526.87 43.5157 526.328 20.4062 527.412C47.0397 549.438 37.9586 545.642 54.02 535.274C47.9885 532.97 41.076 532.834 34.8412 531.14" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M41.5824 573.462C45.5671 576.451 45.1947 578.4 43.1665 580.873C26.5029 601.25 29.4345 597.059 18.757 589.652C8.53564 582.608 3.93911 584.881 17.4446 570.447C19.5977 568.184 22.3192 564.117 25.6028 563.609C31.6893 562.703 37.2699 570.253 41.5824 573.462Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M28.1351 585.516C23.324 586.692 20.4877 581.184 22.3224 577.208C27.3782 566.069 39.894 582.529 28.1351 585.516Z" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M64.022 576.667C62.1038 578.363 35.8667 602.015 36.4147 604.123C36.7572 605.453 65.9401 624.109 68.4062 625.667C67.6755 612.924 67.4243 600.181 67.6527 587.439C60.7566 592.328 54.0432 597.324 47.5124 602.428C51.7597 604.857 55.5959 607.607 58.9527 610.587" stroke="#69321B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <rect x="75.5545" y="8.48535" width="10" height="10" transform="rotate(135 75.5545 8.48535)" stroke="#69321B" stroke-width="2"/>
                        <rect x="68.9844" y="16.4707" width="679.059" height="1" transform="rotate(90 68.9844 16.4707)" stroke="#69321B"/>
                        <rect x="75.5545" y="703.515" width="10" height="10" transform="rotate(135 75.5545 703.515)" stroke="#69321B" stroke-width="2"/>
                    </svg>

                </div>
                <Title/>
                <div>
                    <img alt={'peru'} src={'/decoration/peru1.png'} width={'100%'}/>
                </div>
            </div>
            <img src={'/landscapes/machupiccu_fade.png'} alt={'machupiccu'} width={'100%'}/>
        </div>
    )
}