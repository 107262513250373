// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gastronomy_header__Mj8FL {
    position: relative;
}

.gastronomy_header__Mj8FL > img {
    width: 100%;
}

.gastronomy_headerText__pg9UD {
    font-size: 80px;
}

@media (max-width: 1200px) {
    .gastronomy_header__Mj8FL > img {
        height: 200px;
    }

    .gastronomy_headerText__pg9UD {
        font-size: 64px;
    }
}

.gastronomy_headerText__pg9UD {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--light-text);
    font-family: "DM Serif Display", sans-serif;
    font-weight: 400;
    line-height: 72px;
    text-align: center;

}

.gastronomy_logoContainer__XXBX9 {
    position: absolute;
    top: 30px;
    left: 30px;
}

@media (max-width: 768px) {
    .gastronomy_logoContainer__XXBX9 {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .gastronomy_logoContainer__XXBX9 img {
        width: 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/gastronomy/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,wBAAwB;IACxB,2CAA2C;IAC3C,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,SAAS;QACT,6BAA6B;IACjC;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".header {\n    position: relative;\n}\n\n.header > img {\n    width: 100%;\n}\n\n.headerText {\n    font-size: 80px;\n}\n\n@media (max-width: 1200px) {\n    .header > img {\n        height: 200px;\n    }\n\n    .headerText {\n        font-size: 64px;\n    }\n}\n\n.headerText {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: var(--light-text);\n    font-family: \"DM Serif Display\", sans-serif;\n    font-weight: 400;\n    line-height: 72px;\n    text-align: center;\n\n}\n\n.logoContainer {\n    position: absolute;\n    top: 30px;\n    left: 30px;\n}\n\n@media (max-width: 768px) {\n    .logoContainer {\n        position: absolute;\n        top: 20px;\n        left: 50%;\n        transform: translate(-50%, 0);\n    }\n\n    .logoContainer img {\n        width: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `gastronomy_header__Mj8FL`,
	"headerText": `gastronomy_headerText__pg9UD`,
	"logoContainer": `gastronomy_logoContainer__XXBX9`
};
export default ___CSS_LOADER_EXPORT___;
