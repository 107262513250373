import React, {useEffect} from 'react';
import styles from "../gastronomy/index.module.css";
import {getString} from "../../localization";
import Logo from "../../components/logo";
import LanguageContainer from "../../components/language_container";
import WorldDestinations from "../../components/world_destinations";
import Inquiries from "../../components/inquiries";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

export default function Destinations() {
    const [destinations, setDestinations] = React.useState({});

    useEffect(() => {
        setDestinations(getString("world_destinations"));
        console.log(destinations);
    }, []);
    return (
        <div>
            <div className={styles.header}>
                <img alt={'header'} src={'/destinations/header.png'}/>
                <div className={styles.logoContainer}>
                    <Logo width={'72px'}/>
                </div>
                <LanguageContainer/>
                <div className={styles.headerText}>{destinations?.title}</div>
                <Menu top={'90%'}/>
            </div>

            <WorldDestinations destinations={destinations}/>

            <Inquiries/>
            <Footer/>
        </div>
    )
}