import React from "react";

export default function Logo({width, height}) {

    const home = () => {
        window.location.replace('/?l=1');
    }
    return (
        <img onClick={home} role={'button'} alt={'logo'} src={'/logo.png'} width={width ? width : 'auto'} height={height ? height : 'auto'}/>
    )
}