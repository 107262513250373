// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gastronomy_awards_container__WE3iI {
    background-color: #F0EDE3;
}

.gastronomy_awards_awardContainer__28PJN {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 40px 0;
}

.gastronomy_awards_award__OIKQV {
    display: flex;
}

.gastronomy_awards_award__OIKQV > img {
    height: 236px;
    margin-right: 40px;
}

.gastronomy_awards_awardInfo__cAFX4 {
    max-width: 676px;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    display: flex;
    justify-content: space-between;
    color: #302D24;
}

.gastronomy_awards_awardInfo__cAFX4>div {
    flex-basis: 45%;
}

.gastronomy_awards_awardTitle__K7XOG {
    text-align: left;
}

.gastronomy_awards_awardTitle__K7XOG>div:first-child {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .gastronomy_awards_award__OIKQV {
        flex-direction: column !important;
        max-width: 400px;
    }

    .gastronomy_awards_award__OIKQV >img {
        height: 100%;
        margin-right: 0;
    }

    .gastronomy_awards_award__OIKQV>div {
        margin-top: 30px;
    }

    .gastronomy_awards_awardInfo__cAFX4 {
        flex-direction: column;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/gastronomy_awards/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI;QACI,iCAAiC;QACjC,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".container {\n    background-color: #F0EDE3;\n}\n\n.awardContainer {\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    padding: 40px 0;\n}\n\n.award {\n    display: flex;\n}\n\n.award > img {\n    height: 236px;\n    margin-right: 40px;\n}\n\n.awardInfo {\n    max-width: 676px;\n    font-family: \"Mulish\", sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: right;\n    display: flex;\n    justify-content: space-between;\n    color: #302D24;\n}\n\n.awardInfo>div {\n    flex-basis: 45%;\n}\n\n.awardTitle {\n    text-align: left;\n}\n\n.awardTitle>div:first-child {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 32px;\n    margin-bottom: 10px;\n}\n\n@media (max-width: 768px) {\n    .award {\n        flex-direction: column !important;\n        max-width: 400px;\n    }\n\n    .award >img {\n        height: 100%;\n        margin-right: 0;\n    }\n\n    .award>div {\n        margin-top: 30px;\n    }\n\n    .awardInfo {\n        flex-direction: column;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gastronomy_awards_container__WE3iI`,
	"awardContainer": `gastronomy_awards_awardContainer__28PJN`,
	"award": `gastronomy_awards_award__OIKQV`,
	"awardInfo": `gastronomy_awards_awardInfo__cAFX4`,
	"awardTitle": `gastronomy_awards_awardTitle__K7XOG`
};
export default ___CSS_LOADER_EXPORT___;
