import React from 'react';
import styles from './index.module.css';

export default function GastronomyAwards() {
    return (
        <div className={styles.container}>
            <svg width="1440" height="65" viewBox="0 0 1440 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-42.5" y="0.5" width="1524" height="64" fill="#F0EDE3"/>
                <rect x="-42.5" y="0.5" width="1524" height="64" stroke="#DAD3BE"/>
                <rect x="13" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="-24" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="-24" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="-17" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="-17" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="27" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="71" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="34" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="34" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="41" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="41" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="41" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="85" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="129" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="92" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="92" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="99" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="99" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="99" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="143" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="187" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="150" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="150" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="157" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="157" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="157" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="201" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="245" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="208" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="208" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="215" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="215" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="215" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="259" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="303" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="266" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="266" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="273" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="273" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="273" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="317" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="361" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="324" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="324" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="331" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="331" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="331" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="375" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="419" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="382" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="382" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="389" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="389" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="389" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="433" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="477" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="440" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="440" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="447" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="447" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="447" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="491" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="535" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="498" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="498" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="505" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="505" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="505" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="549" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="593" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="556" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="556" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="563" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="563" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="563" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="607" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="651" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="614" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="614" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="621" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="621" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="621" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="665" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="709" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="672" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="672" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="679" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="679" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="679" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="723" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="767" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="730" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="730" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="737" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="737" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="737" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="781" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="825" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="788" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="788" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="795" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="795" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="795" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="839" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="883" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="846" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="846" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="853" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="853" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="853" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="897" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="941" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="904" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="904" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="911" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="911" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="911" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="955" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="999" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="962" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="962" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="969" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="969" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="969" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1013" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1057" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1020" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1020" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1027" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1027" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1027" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1071" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1115" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1078" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1078" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1085" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1085" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1085" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1129" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1173" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1136" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1136" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1143" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1143" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1143" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1187" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1231" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1194" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1194" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1201" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1201" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1201" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1245" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1289" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1252" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1252" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1259" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1259" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1259" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1303" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1347" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1310" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1310" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1317" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1317" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1317" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1361" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1405" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1368" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1368" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1375" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1375" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1375" y="42" width="7" height="9" fill="#DBD3BB"/>
                <rect x="1419" y="7" width="7" height="51" fill="#DBD3BB"/>
                <rect x="1426" y="7" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1426" y="21" width="37" height="7" fill="#DBD3BB"/>
                <rect x="1433" y="35" width="30" height="7" fill="#DBD3BB"/>
                <rect x="1433" y="51" width="23" height="7" fill="#DBD3BB"/>
                <rect x="1433" y="42" width="7" height="9" fill="#DBD3BB"/>
            </svg>

            <div className={styles.awardContainer}>
                <div className={styles.award}>
                    <img src={'/gastronomy/awards/main.png'} alt={'img'}/>
                    <div>
                        <svg width="676" height="17" viewBox="0 0 676 17" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="8.48438" y="1.41421" width="10" height="10" transform="rotate(45 8.48438 1.41421)"
                                  stroke="#DAD3BE" stroke-width="2"/>
                            <rect x="16.4688" y="7.98523" width="643.059" height="1" stroke="#DAD3BE"/>
                            <rect x="667.516" y="1.41421" width="10" height="10" transform="rotate(45 667.516 1.41421)"
                                  stroke="#DAD3BE" stroke-width="2"/>
                        </svg>

                        <div className={styles.awardInfo}>
                            <div className={styles.awardTitle}>
                                <div>“THE WORLD’S BEST FEMALE CHEF AWARD 2021”</div>
                                <div>Pía León</div>
                            </div>

                            <div>La copropietaria del exclusivo rest. Central y dueña de Kjolle en Lima, y Mil en Cuzco recibió el premio mejor chef en 2021, en la categoría femenina.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}