// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__a79H6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem auto;
}

.styles_container__a79H6>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.styles_pill__aCHYE {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #302D24;
    border: 1px solid #DBD3BB;
    border-radius: 32px;
    padding: 4px 12px 4px 4px;
    height: 32px;
    display: flex;
    justify-content: start;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.styles_pill__aCHYE img {
    width: 24px;
    height: 24px;
}

.styles_selected__vvCFW {
    background-color: #DBD3BB;
}

@media screen and (max-width: 768px) {
    .styles_container__a79H6>div {
        flex-wrap: wrap;
    }

    .styles_pill__aCHYE {
        margin-bottom: 0.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/country_pills/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: 1.5rem auto;\n}\n\n.container>div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pill {\n    font-family: \"Muli\", sans-serif;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: center;\n    color: #302D24;\n    border: 1px solid #DBD3BB;\n    border-radius: 32px;\n    padding: 4px 12px 4px 4px;\n    height: 32px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    user-select: none;\n    cursor: pointer;\n}\n\n.pill img {\n    width: 24px;\n    height: 24px;\n}\n\n.selected {\n    background-color: #DBD3BB;\n}\n\n@media screen and (max-width: 768px) {\n    .container>div {\n        flex-wrap: wrap;\n    }\n\n    .pill {\n        margin-bottom: 0.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__a79H6`,
	"pill": `styles_pill__aCHYE`,
	"selected": `styles_selected__vvCFW`
};
export default ___CSS_LOADER_EXPORT___;
