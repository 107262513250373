import { Routes, Route } from "react-router-dom";
import {useEffect} from "react";
import Home from "./screens/home";
import Gastronomy from "./screens/gastronomy";
import Destinations from "./screens/destinations";


function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/');
    }, []);
    return (<div></div>);
}


export default function RouteContainer() {
    return (
        <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/gastronomy'} element={<Gastronomy/>}/>
            <Route path={'/destinations'} element={<Destinations/>}/>
            <Route path="*" element={<LoginRedirect/>}/>
        </Routes>
    )
}