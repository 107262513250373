// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_mainContainer__E2q\\+4 {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -70%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.menu_mainContainer__E2q\\+4>img {
    margin-top: 10px;
    max-width: 360px;
}

.menu_container__ceo8h {
    display: flex;
    color: #F1BC6B;
}

.menu_container__ceo8h>div:first-of-type {
    border-radius: 32px 0 0 32px;
}

.menu_container__ceo8h div {
    padding: 4px 12px;
    border-right: #49250B 2px solid;
    cursor: pointer;
    background-color: #931C0C;
}

.menu_container__ceo8h div:last-of-type {
    border-right: none;
    border-radius: 0 32px 32px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/menu/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;AAChC","sourcesContent":[".mainContainer {\n    position: absolute;\n    left: 50%;\n    top: 70%;\n    transform: translate(-50%, -70%);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.mainContainer>img {\n    margin-top: 10px;\n    max-width: 360px;\n}\n\n.container {\n    display: flex;\n    color: #F1BC6B;\n}\n\n.container>div:first-of-type {\n    border-radius: 32px 0 0 32px;\n}\n\n.container div {\n    padding: 4px 12px;\n    border-right: #49250B 2px solid;\n    cursor: pointer;\n    background-color: #931C0C;\n}\n\n.container div:last-of-type {\n    border-right: none;\n    border-radius: 0 32px 32px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `menu_mainContainer__E2q+4`,
	"container": `menu_container__ceo8h`
};
export default ___CSS_LOADER_EXPORT___;
