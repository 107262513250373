const about_us = {
    title: "About Us",
    name: "Group Tay's",
    description: "Tay’s World Travel, now Tay’s Mundo Travel, was founded in Peru in 1998 by our founder, Ms. Dai Lixin, who immigrated to Peru in 1983. Based on her good education in China and Peru, as well as 41 years of life experience in Peru, with 26 years of rich travel experience, whether you visit the vibrant capital Lima or settle in Cusco and visit the deep Inca ruins of Machu Picchu, or board a custom-made river boat to explore the mysteries of the Amazon rainforest along the mighty jungle. Every trip will let you feel the magic, enrich your experience and have fun!\n" +
        "The spirit of serving you has remained unchanged for 26 years: integrity, professionalism and seriousness." +
        "The surname \"Dai\" is the inheritance and continuation of the spirit of his grandfather Dai Zonghan. In 1968, he was the first Peruvian to receive the \"Agricultural Medal\" awarded by the government. He was praised by the then president as the \"Peruvian Agricultural Hero\". In 1988, he was awarded the title of \"Honorary Citizen of Guangzhou\"."
};

const awards = [
    {title: "World's Best Cultural Destination", description: "Peru has been awarded \"World's Best Cultural Destination\" at the World Travel Awards. With its impressive archaeological sites such as Machu Picchu, the Nazca Lines and the sacred city of Caral, Peru is a paradise for history and culture lovers. Immerse yourself in the legacy of millenary civilizations and experience the magic of the past!"},
    {title: "Best Tourist Attraction in the World", description: "Machu Picchu has been named the \"World's Best Tourist Attraction\" on multiple occasions. This amazing archaeological site, located high in the Andes, is a breathtaking wonder for all visitors. Walk through its ancient terraces and discover the secrets of the Inca civilization in this magical destination."},
    {title: "Best Adventure Destination in the World", description: "Peru has been awarded as the \"World's Best Adventure Destination\" thanks to its incredible geography and diversity of activities. From hiking the Inca Trail and surfing the beaches of Mancora, to sandboarding the dunes of Huacachina and rafting the Urubamba River, Peru is the perfect place for adrenaline lovers."},
    {title: "Best Historical Destination", description: "Peru has earned the title of \"Best Historical Destination\" for its numerous well-preserved archaeological sites. From the ruins of Chan Chan, the world's largest mud city, to the mysterious Kuelap Fortress, every corner of the country is full of stories to discover. Relive history in the heart of South America!"},
    {title: "Best Birding Destination", description: "Peru is a birdwatcher's paradise and has been recognized as the \"Best Birding Destination\". With more than 1,800 species of birds, including hummingbirds, condors and endemic birds, Peru offers incredible opportunities for birdwatchers - get ready for a spectacle of color and song!"},
    {title: "Best Gastronomic Destination in South America", description: "In addition to the global awards, Peru has been named the \"Best Gastronomic Destination in South America\". Its cuisine fuses indigenous traditions with European, Asian and African influences, creating a unique culinary experience. Taste the richness of flavors that have put Peru on the world's gastronomic map!"}
]

const destinations = [
    {title: "Cuzco", description: "Marvel at Cuzco, the heart of the ancient Inca Empire, a city that is itself a living work of art. Splendid in its mix of Inca and colonial architecture, every street and square tells stories of a glorious past. Discover the spectacular Temple of the Sun, Qoricancha, and explore the ruins of Sacsayhuaman, where history is palpable."},
    {title: "Machu Picchu", description: "Don't miss the crown jewel, Machu Picchu, a wonder of the world that rises majestically amidst misty mountains, offering breathtaking views and a magical atmosphere that can rarely be experienced in a lifetime. This sacred sanctuary, accessible from Cuzco via a spectacular train ride or by following the unforgettable Inca Trail, promises an adventure that is, without a doubt, unforgettable. Cuzco and Machu Picchu together offer not only a window into the Inca past, but also a profound experience that touches the soul of every visitor."},
    {title: "Puno", description: "Discover splendid Puno, the gateway to mystical Lake Titicaca, the highest navigable lake in the world. Immerse yourself in a rich culture and witness vibrant festivities that are simply unforgettable. Puno is not just a destination, it is a living cultural wonder where past and present meet in every dance and tradition."},
    {title: "Lima", description: "Peru's vibrant capital, Lima is a splendid mosaic of history, culture and modernity. From the majesty of its historic center to the modern waterfront of Miraflores and Barranco, Lima offers an unparalleled urban experience. Delight in its renowned gastronomy, which will take you on a spectacular culinary journey."},
    {title: "Paracas - Ica", description: "A marine oasis on the coast of the Peruvian desert, Paracas is simply wonderful. With its impressive National Reserve harboring an amazing biological diversity and the mysterious Nazca Lines nearby, Paracas is perfect for those looking to combine adventure and relaxation in a unique natural environment. \n" +
            "\n" +
            "A short distance away, Ica invites you to discover its vast vineyards and taste pisco, the emblematic Peruvian liquor. The city is also the gateway to the mysterious Huacachina Lagoon, a spectacular oasis surrounded by huge sand dunes where you can practice sandboarding and buggy rides."},
    {title: "Iquitos", description: "Beyond its boundaries, embark on exciting ecological excursions: navigate mysterious rivers, observe pink dolphins, and connect with indigenous communities that will enrich your understanding of the Amazon ecosystem. Iquitos offers an essential and spectacular experience for adventurers and nature lovers."},
    {title: "Huaraz", description: "An adventurer's paradise, Huaraz offers a spectacular natural experience with its towering snow-capped peaks and turquoise lagoons in the Huascaran National Park. It is an ideal destination for trekking, mountaineering and nature lovers, promising unforgettable adventures on every trail."}
]

const tours = [
    {title: "Cusco Enchantment Program + Sacred Valley Tour", d: "3D", n: "2N", days: [
            {title: "Day 1: Machu Picchu Excursion", description: "Reception in Cusco and transfer from the airport to the Train Station, where passengers can  take the train to Machu Picchu. \n" +
                    "During the journey, you will appreciate the beautiful vegetation characteristic of the High  Jungle and majestic snow-capped mountains, pass through tunnels, and finally arrive at Aguas  Calientes station. From there, we will go to the Bus Station where we will board the tourist  bus that will take us to the Machu Picchu citadel (approximately 30 minutes journey).  Subsequently, a guided tour of Machu Picchu will be provided for about 2 hours. Within the  Archaeological Site, we will visit the most important places such as plazas, temples,  warehouses, stairways, water sources, terraces, watchtowers, cemeteries, as well as the  Temple of the Sun, the Intiwatana (Sun Dial), the Temple of the Three Windows, among other  attractions. After the guided tour, you will have time for lunch at a tourist restaurant. At the  scheduled time, board the return train and transfer to your hotel in Cusco." },
            {title: "Day 2: Sacred Valley of the Incas Excursion", description: "Breakfast at the Hotel.\n This tour starts with the pick-up of passengers from their hotel at around 7:30 am. Then, we  head to the Sacred Valley (1 ½ hours journey) to visit the most important places in the  Valley. We will begin by visiting the archaeological center of Pisac, from where you can  appreciate the impressive terraces from the Inca period and the town itself. Continuing the  journey through the Sacred Valley of the Incas, we make a stop for a buffet lunch at a tourist  restaurant. After lunch (about 30 minutes journey), we will enjoy the fortress of  Ollantaytambo and observe the agricultural areas, highlighting the cultivation of corn  without irrigation, as well as Andean tubers and cereals. Then we will move to the viewpoint  of Chinchero, the last point of the tour. This town is a beautiful example of mestizo and  colonial style. At the indicated time, return to Cusco, arriving at approximately 6:00 pm."},
            {title: "Day 3: AM City Tour", description: "Breakfast at the Hotel.\n Between 08:00 hrs and 08:20 hrs, our transfer will pick you up at your hotel to take you to the  starting point of your tour. The tour begins at the Cusco Cathedral, followed by a short walk  through the streets of the historic center towards Qoricancha or Temple of the Sun,  considered an indigenous religious sanctuary. Then, we will board the transport that will take  us to Sacsayhuaman, located 3km from the city of Cusco, offering us a panoramic view of the  city. Next, we will visit Qenqo, which means labyrinth and is located one kilometer from  Sacsayhuaman. Afterwards, we will explore Puca Pucara, which means \"red fortress\" in  Quechua. Finally, we will visit Tambomachay. The tour concludes in the city of Cusco at  approximately 1:45 pm."},
        ]},
    {
        title: "Cusco Charm", d: "4D", n: "3N", days: [
            {
                title: "Day 1: City Tours Excursion",
                description: "Reception in Cusco and transfer from the airport to the hotel of your choice. \n Between 12:45 hrs and 1:00 hrs our transfer will visit you at your Hotel to transfer you to the  starting point of your Tour. The tour begins at the Cathedral of Cusco. Next, we will take a  short walk through the streets of the historic center towards the Qoricancha or Temple of the  Sun, considered an indigenous religious sanctuary. Then we will board the transportation that  will take us to Sacsayhuaman located 3km away. from the city of Cusco, Sacsayhuaman offers  us a panoramic view of the city of Cusco Then we will go to Qenqo, which means labyrinth, it  is located one kilometer from Sacsayhuaman, then we will visit Puca Pucara: coming from two  Quechua words that mean Puca ( red) Pucara (fortress) “red fortress. Finally we will visit  Tambomachay. Ending in the city of Cusco at approximately 6:30 p.m."
            },
            {
                title: "Day 2: Mountain of Colors",
                description: "• 04:30 hrs: Pick up from your hotel, start of the tour to the Full Day Montaña de Colores. • 05:00 hrs: Transfer by tourist bus to the Cusipata district.\n" +
                    "• 06:30 hrs: Arrival in Chirihuani (Cusipata), breakfast and break.\n" +
                    "• 08:30 hrs: Arrival in Phulawasipata.\n" +
                    "• 08:45 hrs: Start of the walk to the Vinicunca Colored Mountain.\n" +
                    "• 10:30 hrs: Exploration as photographs in Vinicunca, review and explanation by the guide. • 11:30 hrs: Walk to Phulawasipata and return to Cusipata.\n" +
                    "• 2:00 p.m.: Free time for lunch (included).\n" +
                    "• 3:00 p.m.: Departure time of the bus from Cusipata to Cusco.\n" +
                    "• 4:30 p.m.: Arrival in the city of Cusco."
            },
            {
                title: "Day 3: Machu Picchu Excursion",
                description: "Breakfast at the hotel. \n At the indicated time, transfer from your Hotel to the train station where your trip to Machu  Picchu begins by train of your choice. During the tour you will appreciate the beautiful  vegetation characteristic of the High Jungle and majestic snow-capped mountains, you will go  through tunnels and finally arrive at the Aguas Calientes station. We will go to the bus station  where we will board the tourist transport bus that will take us to the citadel of Machupicchu.  ¸ (½ hour trip approx.) Later you will be guided inside Machu Picchu for approx. 2 hours, within  the Archaeological site we will visit the most important places such as the Plazas, temples,  tanks, stairs, water sources, platforms and surveillance posts, cemeteries, as well as the  temple of the sun, the Intiwatana (Sun Clock) the temple of the three windows among other  charms. After the guided tour you will have time for lunch at a tourist restaurant. At the  indicated time, board the return train and transfer to your hotel in Cusco."
            },
            {
                title: "Day 4: Airport transfer", description: "Breakfast at the hotel\n" +
                    "At the indicated time, departure transfer.\n" +
                    "End of services."

            }
        ]
    },
    {
        title: "Taquile Dream (Puno)", d: "3D", n: "2N", days: [
            {title: "Day 1: PM Exc. CHULLPAS DE SILLUSTANI", description: "Arrival in the city of Puno and transfer to the chosen hotel.\n They are monumental pre-Inca funerary buildings, some of which  are up to 12 meters high. Carved in volcanic stone, La Chullpa del Lagarto, La de los 12 angles. The  entire archaeological complex is located on the shores of Lake UMAYO, which underground  communicates with Lake Titicaca. At the indicated time, return to Puno and transfer to your Hotel."},
            {title: "Day 2: Exc. UROS Y TAQUILE", description: "07:00 Hrs. Transfer from the hotel to the port, to board our motor boat and visit the floating islands  of Los Uros, which are approximately 30 minutes away. After a brief visit (approx. 40 minutes), we will  continue the trip to the Natural Island of Taquile. Whose residents are mainly dedicated to agriculture,  crafts and fishing. The locals continue to live according to their ancient Inca traditions and laws with  customs, religious and folklore rituals such as: Marriage (Silvinacuy), payment to the land (Pacha  mama).\n" +
                "12.00 hrs. (half day), Typical lunch on the island.\n" +
                "After lunch: Passengers will have two free hours to visit the town's craft center and take photographs  with panoramic views and then return to the city of Puno (to their respective hotels).\n"},
            {title: "Day 3: Airport transfer", description: "Breakfast at the hotel.\n At the indicated time, transfer to the airport or bus station."}
        ]
    },
    {
        title: "Adventure in the Amazon Jungle", d: "3D", n: "2N", days: [
            {title: "Day 1: Iquitos - Ceiba Tops", description: "Arrival at the airport or hotel and transfer to the Explorama pier. A 40km boat ride along the Amazon  River takes you to Ceiba Tops, where air-conditioned rooms with private hot-water bathrooms provide  jungle-home-like comfort. In the afternoon, enjoy a hike through the jungle surrounded by pristine  forest, home to the giant ceiba trees, after which the lodge is named. After dinner, take a night walk  to observe insects, frogs, tarantulas and listen to the sounds of the jungle.\n" +
                    "• Meals: Lunch, Dinner\n" +
                    "• Accommodation: Luxury Lodge in the Jungle\n"},
            {title: "Day 2: Ceiba Tops", description: "Early morning bird watching by boat and sunrise over the Amazon River. Return to Ceiba Tops for  breakfast. Visit to the meeting point of two rivers, the gray and pink rivers. Next, visit a small Yagua  indigenous community where the guide will explain the Yagua culture. Enjoy demonstrations of local  crafts and blowgun hunting techniques. In the afternoon, take a fishing trip and, weather permitting,  enjoy a sunset over the Amazon River.\n" +
                    "• Meals: Breakfast, Lunch, Dinner\n" +
                    "• Accommodation: Luxury Lodge in the Jungle"},
            {title: "Day 3: Ceiba Tops - Iquitos", description: "Travel by boat to a riverside town where the guide will explain the culture of the people of the Peruvian  Amazon River and visit the town school. Return to Iquitos, then to Lima or to the hotel of your choice.\n" +
                    "•  Meals: Breakfast, Lunch (if your flight is in the afternoon)\n" +
                    "Note: The itinerary may change due to weather conditions."}
        ]
    }
]

const footer = {
    contact_us: "Contact Us",
    about_us: "About Us",
    destinations: "Destinations",
    email_us: "Email Us",
}

const gastronomy = {
    title: "Gastronomy",
    awards: [
        { title: "“THE WORLD’S BEST FEMALE CHEF AWARD 2021”", subtitle: "Pía León", description: "The co-owner of the exclusive rest. Central and owner of Kjolle in Lima, and Mil in Cuzco received the best chef award in 2021, in the female category."}
    ],
    dishes: {
        dishes_costa: [
            {
                title: "Ceviche",
                description: "Dive into the freshness of ceviche, the star dish of the Peruvian coast. This delicacy is made with fresh fish, such as corvina or flounder, marinated in lime juice, which \"cooks\" the fish, giving it a firm texture and unmatched tangy flavor. Accompanied by red onion, spicy ají limo, and fresh cilantro, each bite is an explosion of flavor. Served with cooked sweet potato and corn, ceviche is a refreshing and vibrant culinary experience that you cannot miss during your visit to Peru."
            },
            {
                title: "Lomo Saltado",
                description: "Discover lomo saltado, an iconic dish of Peruvian cuisine that combines the best of Creole and Chinese traditions. This delicious dish features juicy pieces of beef tenderloin sautéed with red onion, tomato, and ají amarillo, all infused with soy sauce and vinegar for an irresistible flavor. Served with crispy French fries and white rice, each bite offers an explosion of textures and flavors that will delight your palate."
            },
            {
                title: "Causa Limeña",
                description: "Enjoy causa limeña, a gem of Peruvian gastronomy that combines tradition and flavor in every bite. This colorful dish is made with layers of yellow potato puree seasoned with ají amarillo and lime, filled with chicken or tuna, avocado, and mayonnaise. Causa limeña is a feast of textures and flavors, where the smoothness of the potato blends with the creamy filling and the fresh touch of avocado. Garnished with hard-boiled egg and olives, it is as delicious as it is visually appealing."
            },
        ],
        dishes_sierra: [
            {
                title: "Rocoto Relleno",
                description: "Dare to try rocoto relleno, an explosion of flavor and spiciness typical of Arequipa. This dish features rocotos (Peruvian peppers) stuffed with a savory mix of ground meat, onion, peanuts, olives, and hard-boiled egg. Baked and topped with melted cheese, these small volcanoes of flavor are served with potato cake, offering a perfect contrast between spicy and smooth. Rocoto relleno is an intense culinary experience that will make you fall in love with Arequipean cuisine."
            },
            {
                title: "Pachamanca",
                description: "Immerse yourself in ancestral tradition with pachamanca, an authentic feast from the Peruvian highlands. This dish is cooked in an underground oven with hot stones, where marinated meats (beef, lamb, pork, and chicken), potatoes, sweet potatoes, broad beans, and corn are placed, all wrapped in banana leaves. The result is a meal rich in smoky and earthy flavors, with juicy meats and tender vegetables that absorb the natural aromas of the earth. Pachamanca is not just a dish; it is a cultural experience celebrating the connection to the land and ancient Andean traditions."
            },
            {
                title: "Cuy Chactado",
                description: "Cuy chactado is one of the most traditional and symbolic preparations of the Peruvian highlands. The guinea pig is flattened and fried in abundant oil until crispy on the outside and juicy on the inside. This dish is seasoned with garlic and cumin, typically served with boiled potatoes and salad. In many Andean communities, cuy is more than food; it is part of celebrations and rituals. Its unique flavor and crunchy texture make it a cherished dish for those seeking to experience authentic highland Peruvian cuisine."
            },
        ],
        dishes_selva: [
            {
                title: "Tacacho con Cecina",
                description: "Delight in tacacho con cecina, a robust and flavorful dish typical of the Peruvian jungle. Tacacho is made from grilled and mashed green plantains, mixed with pork lard, and served alongside cecina, salted and sun-dried pork. The combination of the smooth tacacho and the intense cecina creates an unforgettable culinary experience. This dish is an authentic representation of the intense flavors and culinary heritage of the Amazon."
            },
            {
                title: "Inchicapi",
                description: "Immerse yourself in the unique flavors of inchicapi, a traditional Amazonian soup made with chicken, ground corn, peanuts, yucca, and cilantro. This thick and creamy dish combines the earthy flavor of peanuts with the freshness of cilantro. Inchicapi is a comforting meal that reflects the rich biodiversity and unique ingredients of the Peruvian jungle. Ideal for those seeking an authentic and nutritious culinary experience."
            },
            {
                title: "Juane",
                description: "Discover juane, an emblematic dish of the Peruvian jungle that will delight your senses. This delicacy is made with rice seasoned with turmeric, mixed with juicy pieces of chicken, olives, and hard-boiled egg, all wrapped in bijao leaves and steamed. Juane is an explosion of flavors and aromas that will transport you to the heart of the Amazon. The bijao leaves infuse the rice and chicken with a distinctive and aromatic touch, creating an unforgettable culinary experience. This dish is traditionally enjoyed during the San Juan festival but is so delicious that it is enjoyed all year round."
            },
        ]
    },
    restaurants: {
        lima: {
            name: "Lima",
            data: [
                {
                    name: "La Rosa Náutica",
                    photo: "restaurants/image20.png",
                    description: "An icon of Lima, located on the pier in Miraflores, offering Peruvian and international cuisine with spectacular views of the Pacific Ocean."
                },
                {
                    name: "Huaca Pucllana",
                    photo: "restaurants/image26.png",
                    description: "A restaurant next to the Huaca Pucllana, offering contemporary Peruvian cuisine with views of a pre-Columbian archaeological site."
                },
                {
                    name: "Panchita",
                    photo: "restaurants/image2.png",
                    description: "Part of the Gastón Acurio group, offering traditional Peruvian Creole food with a homemade touch, featuring dishes like anticuchos, tamales, and chicharrones."
                },
                {
                    name: "Maido",
                    photo: "restaurants/image18.png",
                    description: "A Nikkei restaurant led by Chef Mitsuharu Tsumura, internationally recognized for its fusion of Japanese and Peruvian cuisine."
                },
                {
                    name: "Osaka",
                    photo: "restaurants/image10.png",
                    description: "Specializing in Nikkei cuisine, combining Japanese techniques with Peruvian ingredients to create innovative and sophisticated dishes."
                },
                {
                    name: "Astrid & Gastón",
                    photo: "restaurants/image27.png",
                    description: "Founded by Gastón Acurio, this restaurant is an emblem of high-end Peruvian cuisine, focusing on local ingredients and modern techniques."
                },
                {
                    name: "Rafael Restaurante",
                    photo: "restaurants/image6.png",
                    description: "Directed by Rafael Osterling, it offers a fusion of Peruvian and international cuisine with elegant presentations."
                },
                {
                    name: "La Mar",
                    photo: "restaurants/image15.png",
                    description: "A cevichería by Gastón Acurio, famous for its fresh ceviches and other seafood dishes, setting a standard in Peruvian cuisine."
                },
                {
                    name: "Isolina Taberna Peruana",
                    photo: "restaurants/image12.png",
                    description: "A tavern in Barranco offering traditional Peruvian Creole food in generous portions, with a family-friendly and cozy atmosphere."
                },
                {
                    name: "Mayta",
                    photo: "restaurants/image1.png",
                    description: "A restaurant by Jaime Pesaque that fuses modern Peruvian cuisine with contemporary techniques and artistic presentations."
                },
                {
                    name: "El Charrúa Restaurante Parrilla",
                    photo: "restaurants/image14.png",
                    description: "Specializing in Uruguayan and Argentine-style grilled meats, offering premium cuts in a classic setting."
                },
                {
                    name: "Cosme",
                    photo: "restaurants/image23.png",
                    description: "A contemporary restaurant offering a fusion of Peruvian and international flavors in a modern and relaxed atmosphere."
                },
                {
                    name: "Amoramar",
                    photo: "restaurants/image13.png",
                    description: "Located in Barranco, it offers contemporary Peruvian cuisine with a focus on fresh and creative seafood in a casual setting."
                },
                {
                    name: "Kjolle",
                    photo: "restaurants/image21.png",
                    description: "Led by Pía León, it focuses on Peruvian biodiversity, using local ingredients to create unique and modern dishes."
                },
                {
                    name: "Fiesta Restaurant Gourmet Lima",
                    photo: "restaurants/image29.png",
                    description: "A restaurant that brings traditional northern cuisine from Chiclayo to Lima, famous for its rice with duck and other northern dishes."
                },
                {
                    name: "Troppo",
                    photo: "restaurants/image11.png",
                    description: "A casual restaurant offering a fusion of Peruvian and Italian cuisine, known for its pastas, pizzas, and Peruvian dishes."
                },
                {
                    name: "Titi",
                    photo: "restaurants/image22.png",
                    description: "One of Lima's most emblematic chifas, combining Cantonese flavors with Peruvian ingredients, a benchmark of fusion cuisine."
                },
                {
                    name: "Four Seas International",
                    photo: "restaurants/image4.png",
                    description: "A Chinese restaurant focusing on traditional Cantonese dishes."
                }
            ]
        },
        cusco: {
            name: "Cusco",
            data: [
                {
                    name: "Cicciolina",
                    photo: "restaurants/image5.png",
                    description: "Known for its fusion of Peruvian and international cuisine, located in the heart of Cusco.",
                },
                {
                    name: "Chicha por Gastón Acurio",
                    photo: "restaurants/image7.png",
                    description: "A restaurant by the famous Peruvian chef offering traditional Andean cuisine with a modern twist."
                },
                {
                    name: "MAP Café",
                    photo: "restaurants/image28.png",
                    description: "Located in the Museum of Pre-Columbian Art, this restaurant offers elegant dishes inspired by Peruvian cuisine."
                },
                {
                    name: "Limo Cocina Peruana & Pisco Bar",
                    photo: "restaurants/image19.png",
                    description: "Famous for its Peruvian dishes with Japanese influence and an excellent pisco bar."
                },
                {
                    name: "Incanto",
                    photo: "restaurants/image8.png",
                    description: "A restaurant that combines Italian and Peruvian cuisine, using fresh local ingredients."
                },
                {
                    name: "Uchu Peruvian Steakhouse",
                    photo: "restaurants/image3.png",
                    description: "Specializes in meats and grills with a focus on Peruvian cooking techniques."
                },
                {
                    name: "Kion Peruvian Chinese",
                    photo: "restaurants/image25.png",
                    description: "A fusion of Peruvian and Chinese (chifa) cuisine, located in Cusco's historic center."
                }
            ]
        },
        arequipa: {
            name: "Arequipa",
            data: [
                {
                    name: "La Nueva Palomino",
                    photo: "restaurants/image17.png",
                    description: "Famous for its traditional Arequipa cuisine, such as rocoto relleno and ocopa."
                },
                {
                    name: "KAO",
                    photo: "restaurants/image30.png",
                    description: "Known for its Peruvian-Thai fusion food, offering a burst of flavor."
                },
                {
                    name: "Zig Zag",
                    photo: "restaurants/image16.png",
                    description: "Specializes in meats and grills, with a menu that includes alpaca, beef, and lamb dishes."
                },
                {
                    name: "Sol de Mayo",
                    photo: "restaurants/image24.png",
                    description: "One of the most traditional restaurants in Arequipa, known for its family-friendly atmosphere and typical Arequipa food."
                },
                {
                    name: "La Trattoria del Monasterio",
                    photo: "restaurants/image9.png",
                    description: "Located in the Santa Catalina Monastery, this restaurant offers a blend of Italian and Peruvian cuisine."
                },
                {
                    name: "El Tío Darío",
                    photo: "restaurants/image31.png",
                    description: "Known for its seafood dishes and pleasant atmosphere with views of Arequipa's volcanoes."
                }
            ]
        }
    }
}

const menu = [
    {name: "Start", url: "/?l=1"}, {name: "Destinations", url: "/destinations"}, {name: "Gastronomy", url: "/gastronomy"}
];

const world_destinations = {
    title: "Destinations",
    header: "Discover our destinations in ",
    destinations: [
        {name: 'Asia', value: 0},
        {name: 'South America', value: 1},
        {name: 'Europe', value: 2},
    ],
    asia: {
        name: "Asia",
        data: [
            {
                name: "China",
                flag: "china.png",
                data: [
                    {
                        name: "Classic China",
                        duration: "12 days",
                        options: [
                            {
                                name: "Option 1",
                                days: [
                                    {
                                        name: "01 THU. Shanghai",
                                        description: "Arrival. Welcome to China! Transfer to the hotel and free time. You will receive information about the start of the circuit during the afternoon."
                                    },
                                    {
                                        name: "02 FRI. Shanghai",
                                        description: "Visit of Shanghai. We visit the Pudong area and will cross the Huangpu River by ferry. Visit to Yuyuan Garden, tea ceremony, and walk through the Xintiandi neighborhood. Lunch included. We'll visit the lively Tianzifang neighborhood and the Bund. Free time."
                                    },
                                    {
                                        name: "03 SAT. Shanghai - Luoyang",
                                        description: "Transfer to the station. Train to Luoyang among beautiful landscapes. Lunch included. Visit to the Longmen Buddhist Caves and boat trip. Afterwards, walk through the ancient city of Luoyang."
                                    },
                                    {
                                        name: "04 SUN. Luoyang - White Horse Monastery - Sanmexia - Xian",
                                        description: "Visit to the White Horse Monastery. We continue to Sanmexia, next to the Yellow River. Visit to Boulon Temple and Guo Museum. Lunch included. Arrival in Xian in the late afternoon."
                                    },
                                    {
                                        name: "05 MON. Xian",
                                        description: "Departure to visit the Museum where the Terracotta Army is located, with its thousands of clay soldiers. Afterwards, visit Xian, including the Wild Goose Pagoda, Chinese calligraphy course, Muslim Quarter, Mosque, and Drum Tower."
                                    },
                                    {
                                        name: "06 TUE. Xian",
                                        description: "After breakfast, end of our services."
                                    }
                                ]
                            },
                            {
                                name: "(Optional 2) Classical China",
                                description: "Days 1 - 5 as in Option 1",
                                days: [
                                    {
                                        name: "06 TUE. Xian, Lingfen, Clan Wang Mansion, Pingyao",
                                        description: "Train to Lingfen and visit the Wang Clan Mansion. Lunch included. Arrival and visit to Pingyao, the best-preserved historical city in China."
                                    },
                                    {
                                        name: "07 WED. Pingyao - Wutai",
                                        description: "We travel to one of the most spiritual places in China: Wutai Mountain, and visit Nanshan Temple. Green mountains and forests. Free time. Dinner included."
                                    },
                                    {
                                        name: "08 THU. Wutai - Yanmenguan - Yinkxian - Hanging Temple - Datong",
                                        description: "A road of great scenic beauty. Arrival in Yanmenguan and visit to a part of the Great Wall. Departure to Yingxian, with its large wooden pagoda. Lunch included. Visit to the Hanging Temple, an incredible construction on a cliff face. Arrival in Datong at the end of the day."
                                    },
                                    {
                                        name: "09 FRI. Datong - Yungang - Jiming Post - Beijing",
                                        description: "We visit the Yungang Caves, the most impressive cave enclosure in China. Departure to Jiming Post, a traditional town protected by powerful walls. We continue to Beijing. Arrival in the middle of the afternoon. End of our services. Check your flight schedule in case you need an extra night. Departure transfer to the airport not included."
                                    },
                                    {
                                        name: "10 SAT. Beijing",
                                        description: "Full day visit of Beijing, where we will visit the Imperial Palace, Jingshan Park, Coal Hill, Tian An Men Square, and the Summer Palace. Tricycle tour through the traditional neighborhoods, known as Hutong. Lunch included."
                                    },
                                    {
                                        name: "11 SUN. Beijing",
                                        description: "Excursion to the impressive Great Wall of China (Mutianyu sector). Afterwards, we continue with the visits in Beijing of District 798 and the Temple of Heaven. Foot massage experience and Tai Chi class. Lacquered duck dinner included."
                                    },
                                    {
                                        name: "12 MON. Beijing",
                                        description: "After breakfast, end of our services."
                                    }
                                ]
                            }
                        ],
                        services_included: "Travel by bus with English speaking guide, basic travel insurance, and breakfast buffet.\n" +
                            "Includes arrival transfer\n" +
                            "Boat: Boat trip in Longmen.\n" +
                            "City tour in: Shanghai, Xian.\n" +
                            "Evening Transfer: Old City in Luoyang.\n" +
                            "Ticket admission: Yuyuan Garden and demonstration of the Tea ceremony in Shanghai, White Horse Temple in Luoyang, Buddhist caves of Longmen in Longmen, Baoulon Temple, Guo Museum in Sanmexia, Terracotta Army Museum, Small Wild Goose Pagoda, Chinese calligraphy course, mosque, show of traditional instrument playing in Drum Tower (not always guaranteed) in Xian.\n" +
                            "Ferry: Huangpu River in Shanghai.\n" +
                            "High-Speed Train: Shanghai - Luoyang.\n" +
                            "3 Lunches or Dinners Included in: Shanghai, Luoyang, Sanmexia.\n" +
                            "(Additional services Opt. 2)\n" +
                            "Excursion: Tricycle ride through the traditional neighborhood in Beijing, Great Wall of China Mutianyu section in Beijing.\n" +
                            "City tour in: Beijing.\n" +
                            "Evening Transfer: Wangfujing street in Beijing.\n" +
                            "Ticket admission: Mansion of the Wang Clan, Temples, mansions, and city walls in Pingyao, Nanshan Temple in Wutai, Great Wall in Yanmenguan, Hanging Temple, The Yungang Grottoes in Datong, Small traditional Chinese settlement in Jiming Post, The Forbidden City, Jingshan Park, Summer Palace, Great Wall of China Mutianyu, Temple of Heaven, foot massage, Tai Chi class in Beijing.\n" +
                            "Funicular: Great Wall of China in Beijing.\n" +
                            "High-Speed Train: Xian - Lingfen.\n" +
                            "5 Lunches or Dinners Included in: Mansion Clan Wang, Wutai, Yinkxian, Beijing, Beijing."
                    },
                    {
                        name: "China’s Beauty",
                        duration: "14 days",
                        days: [
                            {
                                name: "01 - FRI. Beijing.",
                                description: "Welcome to China. Transfer to the hotel. In the late afternoon, we will have our first contact with this impressive city. We include a transfer to the famous Wangfujing Street. This street is a lively pedestrian and commercial artery with many shops and restaurants. Dinner included. Afterwards, return to the hotel."
                            },
                            {
                                name: "02 - SAT. Beijing.",
                                description: "During this day, we will visit: The Imperial Palace, better known as the Forbidden City, one of the world heritage sites. We will also have the opportunity to visit Jingshan Park, where the Coal Hill is located, one of the best-preserved imperial gardens in Beijing. We will climb to the top of the hill to get the best views (weather permitting) over the Forbidden City. The effort will be worth it. We continue to Tiananmen Square, one of the largest in the world, and from the outside, we will appreciate the Grand National Theater, Beijing's opera house. Lunch included. In the afternoon, we will visit the Summer Palace, which was a summer garden for the imperial family of the Qing Dynasty. In the afternoon, we include a tricycle excursion (a typical three-wheeled bicycle) through the traditional neighborhoods of Beijing, known as Hutongs. In this area, we will see the characteristic lifestyle of the residents in these popular neighborhoods, visit typical shops, and even visit a traditional Hutong house, a Siheyuan, with all its rooms arranged around a courtyard. Return to the hotel and accommodation."
                            },
                            {
                                name: "03 - SUN. Beijing - Xian.",
                                description: "Excursion to the Great Wall (Mutianyu section), a spectacular and grand architectural work with over 2,000 years of history. We include a cable car ride up and down the Great Wall. Return to the city. Around 15:45 hrs, we take one of China's ultra-modern high-speed trains. At 300 km/h, in just over 5 hours, we cover the nearly 1100 km separating the two cities. XIAN – Arrival in the late afternoon – transfer to the hotel."
                            },
                            {
                                name: "04 - MON. Xian.",
                                description: "We are in the fascinating city where the Silk Road originated. Early in the morning, we will visit the TERRACOTTA ARMY with its thousands of life-sized clay soldiers found underground. We return to XIAN. Later, we will visit the Small Wild Goose Pagoda, where we will have the opportunity to take a brief Chinese calligraphy course and walk through the walled city center, crossing its Muslim Quarter, and entering the mosque, allowing us to observe the multiple cultures and religions that have left their mark on this country. We will also include admission to the Drum Tower, where we will enjoy a short traditional instrument performance (not always guaranteed). Free time."
                            },
                            {
                                name: "05 - TUE. Xian - Chengdu.",
                                description: "We depart from Xian on a modern high-speed train, covering the 700 km that separate us from CHENGDU in just over three hours. Upon arrival, we will go to the Panda Breeding and Research Center, where 120 giant pandas and 76 red pandas live. Lunch included. In the afternoon, we will take a walk through People's Park, with its small lake, local residents practicing Tai Chi, and its tea houses."
                            },
                            {
                                name: "06 - WED. Chengdu.",
                                description: "Today we include a full-day excursion in Sichuan Province, known for its pleasant landscapes and traditional villages. In DUJIANGYAN, we will visit the traditional irrigation system that has been functioning for more than 2000 years and is a UNESCO World Heritage site. QUINGCHENG SHAN, Green City Mountain, is a sacred Taoist mountain with trails through forests that allow us to discover caves, pavilions, and centuries-old wooden temples. Lunch included. Finally, we will visit the ancient city of JIEZI with its alleys over a thousand years old. Return to CHENGDU."
                            },
                            {
                                name: "07 - THU. Chengdu - Miao Village - Zhaoxing.",
                                description: "We will depart by train, leaving early (scheduled train departure at 7:38 hrs), covering nearly a thousand kilometers in just over five and a half hours through magnificent mountain landscapes (picnic lunch on the train). Arrival in Congjiang, we are in a magnificent and unknown area of China inhabited by ethnic minorities. We will visit the BASHA MIAO Village, in the forest, with its wooden houses, the only ethnic group authorized to carry firearms, having been traditional bear hunters. We continue to ZHAOXING, the most important town of the Dong minority, a beautiful place with over 800 traditional homes, many describing it as the most beautiful village in China. Accommodation."
                            },
                            {
                                name: "08 - FRI. Zhaoxing - Guanxiao - Chengyang - Longsheng - Guilin.",
                                description: "Today, you will experience one of the most beautiful and exciting stages of your trip. GUANXIAO, among surprising Dong ethnic villages with their beautiful wooden architecture, this charming village, frozen in time, is rarely visited by international tourists. We will see its suspension bridge, drum tower, and opera theater. We also stop in CHENGYANG, with its magnificent wind and rain bridge. Lunch included. In LONGSHENG, we will visit the LONJI rice terraces, a UNESCO World Heritage site. We will take a small bus up to the viewpoints and can walk down part of the way along paths through the rice fields and wonderful landscapes. After that, we continue to GUILIN – Arrival in the late afternoon."
                            },
                            {
                                name: "09 - SAT. Guilin.",
                                description: "A full day in GUILIN, one of China's most popular cities with a very pleasant atmosphere by its lake, next to the Li River, and with its very commercial streets in the city center. We include a visit to the Reed Flute Cave, a magnificent cave with numerous stalactites and stalagmites of fantastic shapes illuminated with beautiful colors, creating a spectacular world underground. Free afternoon, we recommend visiting the Jingjiang Palace, the miniature Forbidden City. Accommodation."
                            },
                            {
                                name: "10 - SUN. Guilin - Li River Cruise  - Yangshuo - Huangyao - Guangzhou.",
                                description: "We will depart from Guilin by boat, taking a river cruise on the Li River, with spectacular landscapes of green mountains, villages on the banks, surprising rocks, and their astonishing peaks. Lunch included. We arrive in YANGSHUO, a very touristy and lively city. We take a walk before continuing inland China towards HUANGYAO, in a rural setting. The ancient city is magnificently preserved with its small streets, ancient buildings, and temples; the life and activity of its inhabitants will also transport us to another era. Time to walk around. We continue our route to GUANGZHOU – Arrival at the end of the day."
                            },
                            {
                                name: "11 - MON. Guangzhou. - Zhuhai - Macau.",
                                description: "Guangzhou, we include a two-hour visit to this booming metropolis in southern China. We will walk along Shangxiajiu Street with its traditional market, visit the Chen Clan Ancestral Hall from the late 19th century, and stroll along the riverfront area. Free time and lunch included. In the afternoon, we travel to ZHUHAI, by the China Sea, where we stop by the impressive Opera House, built on reclaimed land, inaugurated in 2016, and its impressive shape symbolizes the sun and the moon. BORDER CROSSING and entry into MACAU, where we explore the casino area, many of them in the same style as those in Las Vegas, their activity is impressive. VERY IMPORTANT: If your trip includes re-entry into mainland China after your stay in Macau and Hong Kong, you will need a double-entry visa."
                            },
                            {
                                name: "12 - TUE. Macau - Hong Kong.",
                                description: "MACAU. We include a city tour, the historic center has been declared a World Heritage site. It was administered by the Portuguese from the 16th century until 1999, its ancient churches, tiles on civil buildings, fortress, and China's oldest lighthouse attest to this. Free time and lunch included. In the afternoon, we take the impressive bridge that connects Hong Kong to Macau, inaugurated at the end of 2018, an engineering marvel with over 30 kilometers of bridges over the China Sea, 6 kilometers of tunnels under the sea, and artificial islands. Note: If the number of participants in the group is less than 06 people, the passage from Macau to Hong Kong may be made by ferry. HONG KONG – Arrival. After accommodation, we will take the Star Ferry to cross the bay. We will take a short walk to see the illuminated buildings from the opposite shore. Return to our hotel area. Dinner included. Accommodation."
                            },
                            {
                                name: "13 - WED. Hong Kong.",
                                description: "After breakfast, we will take a city tour. During the visit, we will see the financial center with its tall skyscrapers, visit the History Museum, which illustrates the city's history (colonial period, world war...), and the Avenue of Stars by the pier, which showcases celebrities from the film industry. We will take the Star Ferry, crossing Hong Kong Bay by boat, and ascend to Victoria Peak (552 m) from where the entire city can be seen. Descent by Peak Tram. After our visit, free afternoon and accommodation."
                            },
                            {
                                name: "14 - THU. Hong Kong.",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "tour with a Spanish-speaking guide, basic travel insurance, hotel accommodation, and buffet breakfast.\n" +
                            "Includes arrival transfer\n" +
                            "Panoramic tours in: Beijing, Xian, Guangzhou, Macau, Hong Kong\n" +
                            "Excursions: Tricycle tour through traditional neighborhoods, Great Wall of China (Mutianyu section), Dujiangyan, Mount Qingcheng and the ancient city of Jiezi, Reed Flute Cave\n" +
                            "Boat: Boat trip on the lake in Mount Qingcheng, Li River cruise\n" +
                            "Evening transfers: Wangfujing Street, Hong Kong\n" +
                            "Admission tickets: Forbidden City, Jingshan Park, Summer Palace, Great Wall of China (Mutianyu), Terracotta Army Museum, Small Wild Goose Pagoda, Chinese calligraphy demonstration, mosque and traditional drum performance at Drum Tower (not always guaranteed), Dujiangyan Irrigation System, Sacred Mount Qingcheng, Basha Miao Village, Visit to Zhaoxing Dong Ethnic Village, Visit to Guangxiao Dong Ethnic Village, Wind and Rain Bridge, Rice Terraces, Reed Flute Cave, Ancient Town, Chen Clan Ancestral Hall, History Museum\n" +
                            "Ferry: Star Ferry in Hong Kong Bay\n" +
                            "Cable car: Great Wall of China, Mount Qingcheng, Peak Tram (descent)\n" +
                            "High-speed trains: Beijing - Xian, Chengdu - Congjiang\n" +
                            "8 Lunches included in: Beijing, Chengdu, Chengdu, Miao Village, Chengyang, Li River Cruise, Guangzhou, Macau\n" +
                            "2 Dinners included in: Beijing, Hong Kong\n"
                    },
                    {
                        name: "China Explorer",
                        duration: "15 days",
                        days: [
                            {
                                name: "01 THU. Shanghái.-",
                                description: "Arrival. Welcome to China! Transfer to the hotel and free time. You will receive information about the start of the circuit during the afternoon."
                            },
                            {
                                name: "02 FRI. Shanghái.-",
                                description: "Visit of Shanghai. We will visit the Pudong area and cross the Huangpu River by ferry. Visit to Yuyuan Garden, tea ceremony, and a walk through the Xintiandi neighborhood. Lunch included. We will visit the lively Tianzifang neighborhood and the Bund. Free time."
                            },
                            {
                                name: "03 SAT. Shanghái - Luoyang.-",
                                description: "Transfer to the station. Train to Luoyang through beautiful landscapes. Lunch included. Visit to the Longmen Buddhist Caves and a boat trip. Afterwards, walk through the ancient city of Luoyang."
                            },
                            {
                                name: "04 SUN. Luoyang - White Horse Monastery  - Sanmexia - Xian.-",
                                description: "Visit to the White Horse Monastery. We continue to Sanmexia, next to the Yellow River. Visit to Boulon Temple and Guo Museum. Lunch included. Arrival in Xian in the late afternoon."
                            },
                            {
                                name: "05 MON. Xian.-",
                                description: "Departure to visit the Museum where the Terracotta Army is located, with its thousands of clay soldiers. Afterwards, visit Xian, including the Wild Goose Pagoda, a Chinese calligraphy course, the Muslim Quarter, the Mosque, and the Drum Tower."
                            },
                            {
                                name: "06 TUE. Xian - Chengdu.-",
                                description: "High-speed train to Chengdu. Visit to the Panda Bear Research and Breeding Center. Lunch included. Walk through the People’s Park."
                            },
                            {
                                name: "07 WED. Chengdu - Dujiangyan - Qingcheng Shan - Jiezi - Chengdu.-",
                                description: "Full-day excursion in Sichuan province, with pleasant landscapes and traditional villages. Visit to Dujiangyan and the sacred Qingcheng Shan Mountain. Lunch included. Visit to the ancient city of Jiezi."
                            },
                            {
                                name: "08 THU. Chengdu - Miao Village - Zhaoxing.-",
                                description: "Train to Congjiang (Picnic lunch on the train), a magnificent city inhabited by ethnic minorities. Visit to the villages of Basha Miao, with its wooden houses, and Zhaoxing, the most important town of the Dong minority."
                            },
                            {
                                name: "09 FRI. Zhaoxing - Guangxiao - Chengyang - Longsheng - Guilin.-",
                                description: "One of the most beautiful and exciting stages. We will discover the villages of Guanxiao and Chengyang, with their beautiful wooden architecture. Lunch included. In Longsheng, we will go to the rice paddies of Lonji. Late afternoon arrival in Guilin."
                            },
                            {
                                name: "10 SAT. Guilin.-",
                                description: "Full day in Guilin. Visit to the Reed Flute Grotto and free afternoon. Accommodation."
                            },
                            {
                                name: "11 SUN. Guilin - Cruise on the Li River  - Yangshuo - Huangyao - Guangzhou.-",
                                description: "Li River Cruise. Spectacular landscapes with green mountains. Lunch included. Arrival and a walk in Yangshuo. Departure to Huangyao, in a rural environment, and time for a walk. Arrival in Guangzhou."
                            },
                            {
                                name: "12 MON. Guangzhou.-",
                                description: "We include a visit of about two hours in this thriving metropolis of southern China, we will visit the House of the Chen clan, and we will tour the riverside area. Free time and lunch included. End of our services. Please, check the time of your flight in case you might need an additional night."
                            },
                            {
                                name: "13 TUE. Guangzhou - Zhuhai - Macau.-",
                                description: "Visit of Guangzhou: market, Chen Clan House, and riverside walk. Free time and lunch included. Visit to Zhuhai, by the South China Sea. Border and cross to Macau. Stroll through the casino area."
                            },
                            {
                                name: "14 WED. Macau - Hong Kong.-",
                                description: "We include a visit to Macau. Lunch included. We cross the impressive bridge that connects Hong Kong with Macau over the South China Sea. Arrival in Hong Kong. After arriving, we’ll take the Star ferry across the bay. Dinner included."
                            },
                            {
                                name: "15 THU. Hong Kong.-",
                                description: "Visit to the Financial Centre, Museum of History, Avenue of Stars, and Bay Crossing by Ferry. Ascent to Victoria Peak and descent on the Peak Tram. Free time in the afternoon."
                            },
                            {
                                name: "16 FRI. Hong Kong.-",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Travel by bus with an English-speaking guide, basic travel insurance, and buffet breakfast.\n" +
                            "Includes arrival transfer\n" +
                            "Excursion: Dujiangyan, Qingcheng Shan Mountain, and the ancient city of Jiezi in Chengdu.\n" +
                            "Boat: Boat trip in Longmen, Boat trip in Qingcheng Shan Mountain in Chengdu.\n" +
                            "City tour in: Shanghai, Xian.\n" +
                            "Evening Transfer: Old City in Luoyang.\n" +
                            "Ticket admission: Yuyuan Garden and demonstration of the Tea ceremony in Shanghai, White Horse Temple in Luoyang, Buddhist caves of Longmen in Longmen, Boulon Temple, Guo Museum in Sanmexia, Terracotta Army Museum, Small Wild Goose Pagoda, Chinese calligraphy course, mosque, show of traditional instrument playing in Drum Tower (not always guaranteed) in Xian, Traditional irrigation system in Dujiangyan, Sacred Mountain of Qingcheng Shan in Chengdu.\n" +
                            "Ferry: Huangpu River in Shanghai.\n" +
                            "Funicular: Qingcheng Shan Mountain in Chengdu.\n" +
                            "High-Speed Train: Shanghai - Luoyang.\n" +
                            "5 Lunches or Dinners Included in: Shanghai, Luoyang, Sanmexia, Chengdu, Chengdu.\n" +
                            "(Additional services)\n" +
                            "Excursion: Reed Flute Cave.\n" +
                            "Boat: Cruise on the Li River.\n" +
                            "City tour in: Guangzhou.\n" +
                            "Ticket admission: Basha Miao village, Zhaoxing Dong Ethnic Village, Guangxiao Dong Ethnic Village, Wind and Rain Bridge in Chengyang, Rice paddies in Longsheng, Reed Flute Cave, Ancient City of Huangyao in Huangyao, House of the Chen Clan in Guangzhou.\n" +
                            "High-Speed Train: Chengdu - Congjiang.\n" +
                            "4 Lunches or Dinners Included in: Miao Village, Chengyang, Li River Cruise, Guangzhou.\n" +
                            "City tour in: Guangzhou, Macau, Hong Kong.\n" +
                            "Evening Transfer: Hong Kong Bay in Hong Kong.\n" +
                            "Ticket admission: House of the Chen Clan in Guangzhou, History Museum in Hong Kong.\n" +
                            "Ferry: Star ferry in Hong Kong Bay.\n" +
                            "Funicular: Peak Tram (one way) in Hong Kong.\n" +
                            "3 Lunches or Dinners Included in: Guangzhou, Macau, Hong Kong."
                    },
                    {
                        name: "China and Japan",
                        duration: "16 days",
                        options: [
                            {
                                name: "Option 1",
                                description: "",
                                days: [
                                    {
                                        name: "01 SUN. Beijing.",
                                        description: "Welcome to China. Transfer to our hotel in Beijing. In the middle of the afternoon, transfer to the famous Wangfujing Street. Dinner included."
                                    },
                                    {
                                        name: "02 MON. Beijing.",
                                        description: "Excursion to the impressive Great Wall of China (Mutianyu sector). Afterwards, we continue with visits in Beijing to District 798 and the Temple of Heaven. Foot massage experience and Tai Chi class. Peking duck dinner included."
                                    },
                                    {
                                        name: "03 TUE. Beijing.",
                                        description: "Full-day visit of Beijing, where we will visit the Imperial Palace, Jingshan Park, Coal Hill, Tiananmen Square, and the Summer Palace. Tricycle tour through the traditional neighborhoods, known as Hutong. Lunch included."
                                    },
                                    {
                                        name: "04 WED. Beijing - Nanjing.",
                                        description: "Transfer to the station and train to Nanjing. Visit the Mausoleum of Sun Yat-sen and the Lingu Temple. Free time and accommodation."
                                    },
                                    {
                                        name: "05 THU. Nanjing - Suzhou - Tongli - Shanghai.",
                                        description: "Arrival in the beautiful and elegant Suzhou and visit to the Garden of the Humble Administrator. Lunch included. Afterwards, we continue to the charming town of Tongli and take a boat ride. Arrival in Shanghai in the late afternoon."
                                    },
                                    {
                                        name: "06 FRI. Shanghai.",
                                        description: "Visit of Shanghai. We visit the Pudong area and cross the Huangpu River by ferry. Visit Yuyuan Garden, tea ceremony, and walk through the Xintiandi neighborhood. Lunch included. We will visit the lively neighborhood of Tianzifang and the Bund. Free time."
                                    },
                                    {
                                        name: "07 SAT. Shanghai - Tokyo.",
                                        description: "After breakfast, a transfer takes you to the airport to take your flight to Tokyo. Late in the afternoon, we will take a trip on the metro to the Shinjuku district. Dinner included."
                                    },
                                    {
                                        name: "08 SUN. Tokyo.",
                                        description: "Five-hour city tour. Lunch included. Free time in the afternoon."
                                    },
                                    {
                                        name: "09 MON. Tokyo.",
                                        description: "After breakfast, end of our services."
                                    }
                                ]
                            },
                            {
                                name: "(Opt. 2) Beijing, Shanghai, and Essential Japan",
                                description: "Days 1 - 8 as in Opt. 1.",
                                days: [
                                    {
                                        name: "09 MON. Tokyo - Mount Fuji - Kawaguchiko.",
                                        description: "Visit Arakurayama Sengen Park, with the best views of Mount Fuji. Arrival in Kawaguchiko, an idyllic lakeside town, and a boat ride. Ascent to Mount Fuji by bus. Traditional Japanese dinner. Overnight in a Ryokan."
                                    },
                                    {
                                        name: "10 TUE. Kawaguchiko - Iyasi no Sato Nenba - Toyota - Kyoto.",
                                        description: "Visit the beautiful traditional village of Iyasi no Sato Nenba. Afterwards, we will admire the Shiraito Falls. In the Nagoya region, we include a visit to the Toyota Museum. Arrival in Kyoto in the late afternoon."
                                    },
                                    {
                                        name: "11 WED. Kyoto.",
                                        description: "Kyoto City Tour. We will visit the Arashiyama Bamboo Forest. Night transfer to the Gion Quarter, popular for its Geishas."
                                    },
                                    {
                                        name: "12 THU. Kyoto - Nara - Osaka.",
                                        description: "In Nara, we visit Todaiji, with its giant Buddha. Lunch included. Afterwards, we will visit the Buddhist temple of Horyuji. Continuing to Osaka, we will stroll through Dotonbori, a neighborhood full of neon lights."
                                    },
                                    {
                                        name: "13 FRI. Osaka.",
                                        description: "After breakfast, end of our services."
                                    }
                                ]
                            },
                            {
                                name: "(Opt. 3) Legends of China and Japan",
                                description: "Days 1 - 12 as in Opt. 2.",
                                days: [
                                    {
                                        name: "13 FRI. Osaka - Himeji - Korakuen Garden - Okayama.",
                                        description: "We will visit the immense Himeji Castle. Lunch included. In Okayama, we will visit the Korakuen Garden, one of the most beautiful gardens in Japan, as well as the Shinto shrine of Kibitsu-Jinja."
                                    },
                                    {
                                        name: "14 SAT. Okayama - Kurashiki - Hiroshima - Itsukushima - Hiroshima.",
                                        description: "Visit Kurashiki, with its water canals. Upon arrival in Hiroshima, we will visit the Peace Memorial. By ferry, we go to Miyajima Island, with its temple partially built on the sea. Return to our hotel. Dinner included."
                                    },
                                    {
                                        name: "15 SUN. Hiroshima.",
                                        description: "After breakfast, end of our services."
                                    }
                                ]
                            }
                        ],
                        services_included: "Travel by bus with an English-speaking guide, basic travel insurance, and buffet breakfast.\n" +
                            "Includes arrival transfer\n" +
                            "Excursion: Great Wall of China, Mutianyu section in Beijing; Tricycle ride through the traditional neighborhood in Beijing.\n" +
                            "Boat: Boat ride in Tongli.\n" +
                            "City tour in: Beijing, Shanghai, Tokyo.\n" +
                            "Evening transfer: Wangfujing Street in Beijing, Shinjuku in Tokyo.\n" +
                            "Admissions: Great Wall of China, Mutianyu section; Temple of Heaven; foot massage; Tai Chi class in Beijing; the Forbidden City; Jingshan Park; Summer Palace in Beijing; Sun Yat-sen Mausoleum and Lingu Temple in Nanjing; Humble Administrator's Garden in Suzhou; Yuyuan Garden and tea ceremony demonstration in Shanghai; Metro in Tokyo; Zojoji Temple, Meiji Temple, and Sensoji Temple in Tokyo.\n" +
                            "Ferry: Huangpu River in Shanghai.\n" +
                            "Funicular: Great Wall of China in Beijing.\n" +
                            "High-speed train: Beijing - Nanjing.\n" +
                            "Flights included: Shanghai - Tokyo.\n" +
                            "7 lunches or dinners included in: Beijing, Beijing, Beijing, Suzhou, Shanghai, Tokyo, Tokyo.",
                        services_additional: "Boat: Kawaguchiko Lake.\n" +
                            "City tour in: Kyoto.\n" +
                            "Evening transfer: Traditional quarter of Gion in Kyoto, Dotonbori in Osaka.\n" +
                            "Admissions: Arakurayama Sengen Park at Mount Fuji; Village Museum in Iyashi No Sato Nenba; Toyota Museum; Tenryu-ji Temple; Arashiyama Bamboo Forest in Kyoto; Fushimi Inari; Imperial Palace; Kinkakuji Temple in Kyoto; Todai-ji Temple and Horyu-ji Temple in Nara.\n" +
                            "2 lunches or dinners included in: Kawaguchiko, Nara.\n" +
                            "Admissions: Himeji Castle; Korakuen Gardens in Okayama; Itsukushima Temple; Peace Museum in Hiroshima.\n" +
                            "Ferry: Hiroshima - Miyajima.\n" +
                            "2 lunches or dinners included in: Himeji, Hiroshima.\n"
                    },
                    {
                        name: "Clasic China + Japan",
                        duration: "14 days",
                        days: [
                            {
                                name: "01 - THU.Shanghai.",
                                description: "Welcome to China. Transfer to the hotel. Welcome to SHANGHAI, a cosmopolitan city with more than 15 million inhabitants, the largest commercial port, and the most international metropolis in China."
                            },
                            {
                                name: "02 - FRI.Shanghai",
                                description: "On our included visit, we will explore the Pudong area, located on the other side of the Huangpu River. Today, it is the financial district, and its most famous silhouette is the Pearl Tower. We will include a ferry crossing over the Shanghai River, where we will obtain magnificent views of both parts of the city. We will travel back in time by walking through the streets of the Old City and Yuyuan Garden, a private garden built during the Ming Dynasty. We will enjoy a tea ceremony demonstration, a beverage that plays a very important cultural role in China through its tradition, aroma, and taste. We will pass by one of the trendiest neighborhoods in the capital, Xintiandi. Lunch included. We will explore the Tianzifang neighborhood, an old residential area located in the French Concession area, known for its narrow, labyrinthine traditional alleys with small craft shops and art galleries. We will end the visit at the waterfront, better known as the Bund, with its 19th-century European-style buildings. In your free time, we recommend a night cruise to see Shanghai illuminated; it will be an unforgettable experience."
                            },
                            {
                                name: "03 - SAT.Shanghai - Luoyang.",
                                description: "Transfer to the station. We will take a high-speed train (scheduled train departure at 7:17 AM), covering the 1,000 km distance to LUOYANG in just over five hours. Lunch included. We will visit the impressive LONGMEN Buddhist caves, where more than 100,000 images of Buddha were carved into the rocky wall of the river over more than a kilometer. We will include a boat ride. Afterward, transfer to the old city, entering through its impressive wall, where we can enjoy the traditional activity and its illuminated streets."
                            },
                            {
                                name: "04 - SUN.Luoyang - White Horse Monastery  - Sanmenxia - Xian",
                                description: "Early in the morning, on the outskirts of Luoyang, we will visit the WHITE HORSE BUDDHIST MONASTERY, an extensive monastery and study center where Buddhists from all over the world come. We continue our route to SANMENXIA, where we will see the Yellow River, visit the Baoulon Temple with its towering pagoda, and visit one of China's best museums: the Guo Museum, with the funerary carriages of the ancient state of Guo. Lunch included. We continue east, passing at the foot of Mount Hua, a sacred mountain. XIAN, arrival in the late afternoon."
                            },
                            {
                                name: "05 - MON.Xian",
                                description: "We are in the fascinating city where the Silk Road originated. Early in the morning, we will visit the TERRACOTTA ARMY, with its thousands of life-size clay soldiers found underground. We return to XIAN. Later, we will visit the Small Wild Goose Pagoda, where we will have the opportunity to take a brief course in Chinese calligraphy. Then, we will stroll through the walled center, cross the Muslim quarter, and enter the mosque, observing the multiple cultures and religions that have left their mark on this country. We will also include entrance to the Drum Tower, where we will enjoy a short performance with traditional instruments (not always guaranteed). Free time."
                            },
                            {
                                name: "06 - TUE.Xian - Lingfen - Wang Clan Mansion - Pingyao",
                                description: "We leave Xian on a modern high-speed train, arriving in Lingfen in less than three hours, from where we head by coach to the WANG CLAN MANSION, built between the 17th and 19th centuries by a wealthy family. Lunch included. We continue to PINGYAO, the best-preserved historical city in China, with 4,000 ancient buildings, a UNESCO World Heritage site. We will enjoy this charming traditional city. We include an entrance ticket that allows access to all the temples, mansions, and the city wall."
                            },
                            {
                                name: "07 - WED.Pingyao - Wutai",
                                description: "We travel to one of the most exciting spiritual places in China: WUTAI MOUNTAIN. Among green mountains and forests lies one of the centers of Lamaist Buddhism, with more than 40 ancient monasteries (and a similar number of monasteries built in recent years) housing a large community of monks. We will visit the Nanshan Temple with our guide. Later, we will have free time for you to seek out less-visited temples where you can, with respect, observe the monks. Dinner included and accommodation."
                            },
                            {
                                name: "08 - THU.Wutai - Yanmenguan - Yingxian - Hanging Temple e - Datong",
                                description: "Following a scenic road between mountains, we continue to YANMENGUAN. Not far from the Mongolian border, in a mountain pass, we visit a section of the GREAT WALL, where we can walk peacefully as it is an area with very few tourists. We continue to YINGXIAN, a quiet, picturesque town known for its large wooden pagoda. Lunch included. Then, we proceed to the HANGING TEMPLE, Xuancong Temple, an incredible construction on the side of a cliff. DATONG, arrival at the end of the day. You can stroll through this city with impressive walls, which has reconstructed its entire historic center."
                            },
                            {
                                name: "09 - FRI.Datong - Yungang - Jiming Post - Beijing",
                                description: "On the outskirts of Datong, we will visit the YUNGANG CAVES, the most impressive cave art site in China, with more than 51,000 statues in its 252 caves carved in the 5th century. We continue to JIMING POST, a small traditional Chinese town that maintains its peaceful and provincial life. It was created as a resting point for the emperor's couriers, protected by powerful walls. Continue to BEIJING, arriving in the mid-afternoon."
                            },
                            {
                                name: "10 - SAT. Beijing",
                                description: "During this day, we will visit: The Imperial Palace, better known as The Forbidden City, a World Heritage site. We will also have the opportunity to visit Jingshan Park, where Coal Hill is located, one of the best-preserved imperial gardens in Beijing. We will climb to the top of the hill for the best views (if the day is clear) over the Forbidden City. The effort will be worth it. We continue to Tiananmen Square, one of the largest in the world, and from the outside, we will admire the National Grand Theater, Beijing's opera house. Lunch included. In the afternoon, we will visit the Summer Palace, which was a summer garden for the imperial family of the Qing Dynasty. We include a rickshaw (typical three-wheeled bicycle) tour through the traditional neighborhoods of Beijing, known as Hutong. In this area, we can see the characteristic lifestyle of the residents in these popular neighborhoods, visit typical shops, and even visit a traditional Hutong house, a Siheyuan, with all its rooms around a courtyard. Return to the hotel and accommodation."
                            },
                            {
                                name: "11 - SUN. Beijing",
                                description: "Excursion to The Great Wall (Mutianyu section), a spectacular and grand architectural work with a history spanning more than 2,000 years. We include the cable car up and down the Great Wall. Returning to Beijing, we will stop in the 798 Art District, with its contemporary art located in a former military factory. Next, we will visit the Temple of Heaven, the largest temple complex in China and one of the most original and impressive constructions in Beijing. We will learn about traditional Chinese medicine, which has supported the theory for centuries that our health is linked to the health of our feet. We will relax and enjoy a foot massage. We will also receive a Tai Chi class. Later, return to the hotel and accommodation. Peking duck dinner included."
                            },
                            {
                                name: "12 - MON. Beijing - Tokyo",
                                description: "After breakfast, transfer to the airport to take a flight to Tokyo (flight included). Tokyo - Welcome to Japan! After border and customs procedures, an assistant (usually Spanish-speaking, though sometimes English-speaking) will be waiting for you. Transfer by shared transport (SHUTTLE BUS) to your hotel. At 6:30 PM, we will meet our guide in the hotel reception. We will get to know the members of our group, and then take an evening stroll. In the late afternoon, we will get introduced to this fascinating country by taking the subway (tickets included) to the Shinjuku area. You will see the masses of people using the modern and efficient public transportation. Then, we will take a walk to discover this area, which has a lively nightlife. Welcome dinner included at a local restaurant. Return to the hotel by subway, accompanied by our guide. NOTE: Occasionally, depending on travelers' arrival flights, the subway transfer and dinner may be scheduled for the following afternoon or evening."
                            },
                            {
                                name: "13 - TUE. Tokyo",
                                description: "In the morning, we include an extensive five-hour guided tour of this impressive city. Its frenetic activity and ultramodern neighborhoods coexist with areas full of peace. We will make a brief stop at Zojoji Temple to photograph the most beautiful image of Tokyo Tower. Notable are the hundreds of Jizos, stone guardian deities of children, adorned with woolen hats, bibs, and pinwheels. Next, we will head to the famous Shibuya Crossing, said to be the busiest in the world. We continue to Meiji Shrine, dedicated to the spirits of Emperor Meiji and his wife. We continue our bus tour along the elegant Omotesando Street. We will skirt the most beautiful part of the Imperial Palace and its gardens. We will stop to visit the famous Nijubashi Bridge. We will pass through Akihabara, the “Electric City,” the center of Japanese Manga and Anime. We will pass by the Kokugikan, the Sumo stadium, Japan's national sport. We will end our visit in Asakusa, visiting Sensoji Temple and the famous Nakamise Street. Lunch included at a local restaurant. Return to the hotel. Free afternoon."
                            },
                            {
                                name: "14 - WED. Tokyo",
                                description: "After breakfast, end of our services."
                            }
                        ]
                    }
                ],
            },
            {
                name: "Japan",
                flag: "japan.png",
                data: [
                    {
                        name: "Classic Japan ",
                        duration: "08 days",
                        options: [
                            {
                                name: "Option 1: Tokyo, Kyoto, and Alps (Monday all year)",
                                description: "",
                                days: [
                                    {
                                        name: "1 (Monday)",
                                        description: "Arrival in Tokyo, transfer to hotel, evening at Shinjuku (dinner included)."
                                    },
                                    {
                                        name: "2 (Tuesday)",
                                        description: "Tokyo city tour (lunch included), free afternoon."
                                    },
                                    {
                                        name: "3 (Wednesday)",
                                        description: "Bullet train to Kyoto, Kyoto city tour, evening in Gion Quarter."
                                    },
                                    {
                                        name: "4 (Thursday)",
                                        description: "Visit Tsumago, Matsumoto Castle, and arrive in Nagano (lunch included)."
                                    },
                                    {
                                        name: "5 (Friday)",
                                        description: "Visit Nagano Temple, Jigokudani Monkey Park, Kusatsu, and stay in Ikaho Onsen (traditional dinner included)."
                                    },
                                    {
                                        name: "6 (Saturday)",
                                        description: "Visit Nikko temples, return to Tokyo. End of services."
                                    }
                                ]
                            },
                            {
                                name: "Option 2: Tokyo, Kyoto, Alps, and Fuji",
                                description: "Days 1-5: Same as Option 1.",
                                days: [
                                    {
                                        name: "6 (Saturday)",
                                        description: "Visit Nikko temples, return to Tokyo."
                                    },
                                    {
                                        name: "7 (Sunday)",
                                        description: "Visit Kamakura, Enoshima, and Odawara."
                                    },
                                    {
                                        name: "8 (Monday)",
                                        description: "Visit Owakudani, Oshino Hakkai, Kawaguchiko, Mount Fuji, and return to Tokyo. End of services."
                                    }
                                ]
                            }
                        ],
                        services_included: "Arrival transfer\n" +
                            "City tours\n" +
                            "High-speed train, and various temple and park admissions.\n" +
                            "5 meals included in Option 1\n" +
                            "Additional boat\n" +
                            "Admission services in Option 2.\n"
                    }
                ],
            },
            {
                name: "Korea",
                flag: "korea.png",
                data: [
                    {
                        name: "Korea Express",
                        duration: "06 days",
                        days: [
                            {
                                name: "01 - MON. Seoul.",
                                description: "Welcome to Korea! Transfer to your hotel. Meet your guide. In the late afternoon, we will begin exploring this fascinating country. With our guide, we will take the modern and efficient Metro (who will teach us how to use this public transportation) to City Hall, a building shaped like a tsunami. We will also see the Deoksugung Palace, illuminated at night, which allows us to appreciate both tradition and modernity in this country. Then, we will walk to the Myeongdong district, the largest shopping and nightlife area in Korea, whose name means bright tunnel. Dinner included at a local restaurant. Overnight stay."
                            },
                            {
                                name: "02 - TUE. Seoul.",
                                description: "Today, we will include a five-hour tour of Seoul. We will visit the Changdeokgung Palace, a UNESCO World Heritage site, which is a complex of palaces within a large park. After that, we will walk through Bukchon Hanok Village, a historic neighborhood of traditional small houses. Later, we will visit the War Memorial, an impressive war museum that illustrates the conflicts Korea has experienced throughout history, especially the Korean War, in which soldiers from numerous countries participated under the United Nations. Lunch included at a local restaurant. We continue to the Gangnam District, one of the busiest areas of the city, home to numerous shopping and entertainment centers. Finally, we will stop at the Lotte World Tower, an impressive building that is 550 meters tall, where you will have free time to either go up the tower (admission not included) or stroll through its park or luxury shopping centers. Return to the hotel and free time."
                            },
                            {
                                name: "03 - WED. Seoul - Suwon - Jeonju.",
                                description: "Today, we will visit Gyeongbokgung Palace, an impressive palace with 600 years of history, where we will witness, if available, the changing of the guard ceremony. We will then leave Seoul and head south. We arrive in Suwon, the ancient capital of Korea, known for its impressive walls, a UNESCO World Heritage site. We will explore the impressive wall with its fortifications and visit Hwaseong Haenggung, the summer palace. Lunch included. We continue our route to Jeonju, where we will visit its fascinating historic center (Hanok Village), with its traditional houses, shops, and art galleries."
                            },
                            {
                                name: "04 - THU. Jeonju - Haeinsa - Daegu - Busan.",
                                description: "Amidst stunning mountain landscapes covered in forests, we arrive at Haeinsa, a Buddhist temple built in 802, where the Tripitaka, the most complete collection of Buddhist texts carved into 80,000 wooden blocks, is stored. We will visit the temple and the museum dedicated to the Tripitaka. We then continue to Daegu, where we will visit the traditional medicine market, famous for its various herbs, especially ginseng, and the oriental medicine museum. Lunch included. We continue to Busan, Korea's second-largest city, full of life. Upon arrival, we will stop at the fantastic Doosan Haeundae complex, with its skyscrapers over 300 meters tall and its marina. Overnight stay."
                            },
                            {
                                name: "05 - FRI. Busan - Haedong Yonggung - Seoul.",
                                description: "In the morning, we will depart from Busan to visit Haedong Yonggung, a 14th-century Buddhist temple located by the sea. We will return to Busan and visit the United Nations Memorial Cemetery, where people from numerous countries are buried. We will then visit the largest fish market in the country, located next to Nampo Port. Lunch included. Afterwards, we will walk to Yongdusan Park with its immense 120-meter pagoda. Around 5:30 PM, we will take a modern high-speed train, and in about three hours, we will return to Seoul. Arrival, transfer to the hotel, and overnight stay."
                            },
                            {
                                name: "06 - SAT. Seoul.",
                                description: "Free day in Seoul to explore the city at your own pace or participate in optional activities. Overnight stay in Seoul."
                            }
                        ],
                        services_included: "Coach tour with a Spanish-speaking guide, basic travel insurance, hotel accommodation, and buffet-style breakfast.\n" +
                            "Includes arrival transfer.\n" +
                            "Panoramic tour in: SEOUL.\n" +
                            "Night transfer: Myeongdong District in Seoul.\n" +
                            "Entrance fees included for: Metro, Changdeokgung Palace, War Memorial in Seoul, Gyeongbokgung Palace, Hanok Village, Buddhist Temple, Haedong Yonggung Temple, United Nations Memorial, and Yongdusan Park.\n" +
                            "High-speed train: Busan-Seoul.\n" +
                            "4 Lunches included in: SEOUL, SUWON, DAEGU, BUSAN.\n" +
                            "1 Dinner included in: SEOUL.\n"
                    },
                    {
                        name: "Korea + Classical Japan",
                        duration: "13 days",
                        days: [
                            {
                                name: "01 - MON. Seoul.",
                                description: "Welcome to Korea! Transfer to your hotel. Meet your guide. Welcome to Korea! Transfer to your hotel. Meet your guide."
                            },
                            {
                                name: "02 - TUE. Seoul.",
                                description: "Today, we will include a five-hour tour of Seoul. We will visit Changdeokgung Palace, a UNESCO World Heritage site, which is a complex of palaces within a large park. After that, we will walk through Bukchon Hanok Village, a historic neighborhood of traditional small houses. Later, we will visit the War Memorial, an impressive war museum that illustrates the conflicts Korea has experienced throughout history, especially the Korean War, in which soldiers from numerous countries participated under the United Nations. Lunch included at a local restaurant. We continue to the Gangnam District, one of the busiest areas of the city, home to numerous shopping and entertainment centers. Finally, we will stop at the Lotte World Tower, an impressive building that is 550 meters tall, where you will have free time to either go up the tower (admission not included) or stroll through its park or luxury shopping centers. Return to the hotel and free time."
                            },
                            {
                                name: "03 - WED. Seoul - Suwon - Jeonju.",
                                description: "Today, we will visit Gyeongbokgung Palace, an impressive palace with 600 years of history, where we will witness, if available, the changing of the guard ceremony. We will then leave Seoul and head south. We arrive in Suwon, the ancient capital of Korea, known for its impressive walls, a UNESCO World Heritage site. We will explore the impressive wall with its fortifications and visit Hwaseong Haenggung, the summer palace. Lunch included. We continue our route to Jeonju, where we will visit its fascinating historic center (Hanok Village), with its traditional houses, shops, and art galleries."
                            },
                            {
                                name: "04 - THU. Jeonju - Haeinsa - Daegu - Busan.",
                                description: "Amidst stunning mountain landscapes covered in forests, we arrive at Haeinsa, a Buddhist temple built in 802, where the Tripitaka, the most complete collection of Buddhist texts carved into 80,000 wooden blocks, is stored. We will visit the temple and the museum dedicated to the Tripitaka. We then continue to Daegu, where we will visit the traditional medicine market, famous for its various herbs, especially ginseng, and the oriental medicine museum. Lunch included. We continue to Busan, Korea's second-largest city, full of life. Upon arrival, we will stop at the fantastic Doosan Haeundae complex, with its skyscrapers over 300 meters tall and its marina. Overnight stay."
                            },
                            {
                                name: "05 - FRI. Busan - Haedong Yonggung - Busan - Ferry Busan Shimonoseki.",
                                description: "In the morning, we will depart from Busan to visit Haedong Yonggung, a 14th-century Buddhist temple located by the sea. We will return to Busan and visit the United Nations Memorial, with its cemetery where people from numerous countries are buried. We will then visit the largest fish market in the country, located next to Nampo Port. Lunch included. Afterwards, we will walk to Yongdusan Park, with its immense 120-meter pagoda. Transfer to the port to complete the boarding procedures (Note: The ferry check-in deadline is 5:30 PM). Departure from Busan is scheduled for 9:00 PM, crossing the Sea of Japan. Overnight on the ferry in double cabins with private bathrooms. Note: Due to the limited capacity of double cabins on the ferry, we may sometimes take a fast ferry departing from Busan at 3:00 PM, arriving in Fukuoka (Japan) around 6:30 PM (border crossing, free time in Fukuoka, and hotel accommodation)."
                            },
                            {
                                name: "06 - SAT. Ferry Busan Shimonoseki - Shimonoseki - Iwakuni - Itsukushima - Hiroshima.",
                                description: "Arrival at 8:00 AM at the port of Shimonoseki (breakfast not included), border crossing. We travel to Iwakuni, a small town where we will visit the beautiful and historic Kintai-Kyo Bridge. We continue to Miyajima and take a ferry to the island of Miyajima, the island where gods and people live together, home to the Itsukushima Shrine dedicated to the guardian of the seas, built partially over the water. We will have time to visit the temple, stroll through the beautiful center, and have lunch. We then continue to Hiroshima, a city tragically known for the atomic bombing it suffered in 1945, which completely destroyed the city. We will walk through the Peace Memorial, see the Atomic Bomb Dome, and visit the impressive Peace Museum. Dinner included."
                            },
                            {
                                name: "07 - SUN. Hiroshima - Matsuyama.",
                                description: "Today, we will take a ferry to the island of Shikoku, a very pleasant two-and-a-half-hour journey. We will better understand Japan, a country made up of multiple islands. In Matsuyama, we will take a cable car to visit its impressive castle. After that, we will visit Ishiteji, a beautiful Buddhist temple and pilgrimage site. Lunch included at a local restaurant. In the late afternoon, we will include entry to Dogo Onsen, a traditional Japanese bathhouse built in the 19th century."
                            },
                            {
                                name: "08 - MON. Matsuyama - Kotohira - Takamatsu - Naruto - Kobe.",
                                description: "We will depart for Kotohira, a Shinto shrine dedicated to the god of sailors, a pilgrimage site built on Mount Zosu, which requires climbing 785 steps to reach. Afterwards, in Takamatsu, we will visit Ritsurin, one of the most beautiful gardens in Japan. Lunch included at a local restaurant. Then, in Naruto, we will stop to admire the construction of an impressive bridge that crosses the sea, where we can walk on its glass floor and observe the stunning whirlpools in the sea from 45 meters above. Our scenic route continues as we cross large bridges between islands over the sea. Arrival in Kobe, overnight stay."
                            },
                            {
                                name: "09 - TUE. Kobe - Osaka - Mount Koya.",
                                description: "In Kobe, we will visit the Earthquake Memorial Museum, which commemorates the tragic earthquake that devastated the city in 1995 and its impeccable reconstruction. Note: On certain dates, the Earthquake Museum visit may be replaced with a visit to the Sake Museum or a trip to the Kobe Tower. We then continue to Osaka. We will leave the city and head to Mount Koya, the most important center of Shingon Buddhism in Japan. On this sacred mountain, a place of pilgrimage and magic, there are 120 temples and monasteries. We will visit the Okuno Mausoleum, a spiritual site and immense cemetery in nature with more than 200,000 graves. We will also visit Kongobuji Temple, built in 1593. Tonight, we will stay in a Buddhist monastery. The monks will give us a Zen meditation practice (Ajikan). Dinner included (vegetarian meal). If you wish, you can attend the temple's early morning religious ceremony. Note: We consider staying in the monastery a valuable and enriching experience. The rooms are simple, without bathrooms, and in traditional Japanese style with tatami mats. The baths are communal."
                            },
                            {
                                name: "10 - WED. Mount Koya - Kyoto.",
                                description: "Japanese breakfast at the temple. We will depart early for Kyoto, where we will spend the entire day in the city, which was Japan's capital from 794 to 1868, hosting the Imperial Court. This city was the only major Japanese city not bombed during World War II, preserving its rich artistic heritage. The Kyoto Protocol, aimed at limiting greenhouse gas emissions, was signed here in 1997. We will include a visit to the city's main attractions. We will visit the magnificent Fushimi Inari Shinto Shrine, the Imperial Palace, and the Kinkakuji Temple, also known as the Golden Pavilion, with its magnificent gardens. After that, we will have some free time before taking a walk through Gion, the traditional district full of life, known for its Geishas. Free time."
                            },
                            {
                                name: "11 - THU. Kyoto - Tokyo.",
                                description: "After breakfast, we will travel to Tokyo by bullet train. Our train will depart early, and in just over two hours, we will cover the 500 kilometers between the two cities. Arrival in Tokyo. In the late afternoon, we will take the subway (tickets included) to the Shinjuku area. You will see the masses of people using the modern and efficient public transportation. In Shinjuku, we will find one of the most vibrant nightlife areas. Dinner included at a local restaurant. Return to the hotel."
                            },
                            {
                                name: "12 - FRI. Tokyo.",
                                description: "In the morning, we include a five-hour tour of this impressive city with our guide. Its frenetic activity and ultramodern neighborhoods coexist with peaceful areas. We will make a brief stop at Zojoji Temple to photograph the most beautiful view of Tokyo Tower. Notable are the hundreds of Jizos, stone deities that protect children, adorned with woolen hats, bibs, and pinwheels. Next, we will head to the famous Shibuya Crossing, said to be the busiest in the world. We will continue to Meiji Shrine, dedicated to the spirits of Emperor Meiji and his wife. Our bus will then take us along the elegant Omotesando Street, skirting the most beautiful part of the Imperial Palace and its gardens. We will stop to visit the famous Nijubashi Bridge. We will pass through Akihabara, the Electric City, the center of Japanese Manga and Anime, and pass by the Kokugikan, the Sumo stadium, Japan's national sport. We will end our visit in Asakusa, visiting Sensoji Temple and the famous Nakamise Street. Lunch included at a local restaurant. Return to the hotel. Free afternoon."
                            },
                            {
                                name: "13 - SAT. Tokyo.",
                                description: "After breakfast, end of our services"
                            }
                        ]
                    }
                ],
            }
        ]
    },
    america: {
        name: "South America",
        data: [
            {
                name: "Argentina",
                flag: "argentina.png",
                data: [
                    {
                        name: "Magical Iguazu",
                        duration: "06 days",
                        days: [
                            {
                                name: "1 - Arrival in Iguazú",
                                description: "Upon arrival at Iguazú Airport (IGU or IGR), you will be transferred to the hotel. You will have the rest of the day free to relax or explore the city at your own pace. Overnight stay in Iguazú."
                            },
                            {
                                name: "2 - Iguazú Falls (Brazilian Side)",
                                description: "After breakfast, enjoy an excursion to the impressive Iguazú Falls on the Brazilian side. This tour will allow you to admire the panoramic views of the falls. The afternoon is free for optional activities or rest. Overnight stay in Iguazú."
                            },
                            {
                                name: "3 - Iguazú Falls (Argentine Side) and Transfer to Buenos Aires",
                                description: "Breakfast at the hotel and visit to Iguazú Falls on the Argentine side, where you can get even closer to the powerful waterfalls. Optionally, you can choose between a stop at the Bird Park or a visit to Dreamland, with a ticket included for one attraction of your choice. Additionally, you will enjoy a free stop at the Chocolate Caseiro craft center. In the afternoon, you will be transferred to the airport to catch your flight to Buenos Aires. Upon arrival, transfer to the hotel. Overnight stay in Buenos Aires."
                            },
                            {
                                name: "4 - Buenos Aires City Tour and Shopping",
                                description: "After breakfast, you will take part in a half-day city tour of Buenos Aires, visiting the most iconic spots in the city. In the afternoon, enjoy a shopping tour, visiting leather factories, clothing stores, and other local products. Overnight stay in Buenos Aires."
                            },
                            {
                                name: "5 - Free Day in Buenos Aires",
                                description: "A free day to explore Buenos Aires on your own. You can take advantage of a 10% discount at Puerto Cristal restaurant in Puerto Madero to enjoy a delicious dinner. Additionally, if you wish, you can visit the Puerto Madero Casino, with free entry and transfer provided by the casino (for guests over 18 years old). Overnight stay in Buenos Aires."
                            },
                            {
                                name: "6 - Departure from  Buenos Aires",
                                description: "After breakfast, you will have free time before being transferred to the airport for your return flight. End of services."
                            }
                        ],
                        services_included: "In Iguazú:\n" +
                            "Airport/hotel/airport transfers.\n" +
                            "2 nights of accommodation with breakfast.\n" +
                            "Excursions to Iguazú Falls (Brazilian and Argentine sides).\n" +
                            "Choice between a stop at the Bird Park or a visit to Dreamland with an included ticket.\n" +
                            "Stop at the Chocolate Caseiro craft center.\n" +
                            "In Buenos Aires:\n" +
                            "Airport/hotel/airport transfers in semi-private service.\n" +
                            "3 nights of accommodation with breakfast.\n" +
                            "Half-day city tour in regular service.\n" +
                            "Shopping tour at leather and clothing factories.\n" +
                            "10% discount at Puerto Cristal restaurant.\n" +
                            "Free entry and transfer to Puerto Madero Casino.\n" +
                            "Hotel taxes.\n",
                        services_additional: "Dinner show at Rafain Churrascaria in Iguazú (with an additional cost).\n"
                    }
                ],
            },
            {
                name: "Brazil",
                flag: "brasil.png",
                data: [
                    {
                        name: "Magical Iguazu",
                        duration: "06 days",
                        days: [
                            {
                                name: "1 - Arrival in Iguazú",
                                description: "Upon arrival at Iguazú Airport (IGU or IGR), you will be transferred to the hotel. You will have the rest of the day free to relax or explore the city at your own pace. Overnight stay in Iguazú."
                            },
                            {
                                name: "2 - Iguazú Falls (Brazilian Side)",
                                description: "After breakfast, enjoy an excursion to the impressive Iguazú Falls on the Brazilian side. This tour will allow you to admire the panoramic views of the falls. The afternoon is free for optional activities or rest. Overnight stay in Iguazú."
                            },
                            {
                                name: "3 - Iguazú Falls (Argentine Side) and Transfer to Buenos Aires",
                                description: "Breakfast at the hotel and visit to Iguazú Falls on the Argentine side, where you can get even closer to the powerful waterfalls. Optionally, you can choose between a stop at the Bird Park or a visit to Dreamland, with a ticket included for one attraction of your choice. Additionally, you will enjoy a free stop at the Chocolate Caseiro craft center. In the afternoon, you will be transferred to the airport to catch your flight to Buenos Aires. Upon arrival, transfer to the hotel. Overnight stay in Buenos Aires."
                            },
                            {
                                name: "4 - Buenos Aires City Tour and Shopping",
                                description: "After breakfast, you will take part in a half-day city tour of Buenos Aires, visiting the most iconic spots in the city. In the afternoon, enjoy a shopping tour, visiting leather factories, clothing stores, and other local products. Overnight stay in Buenos Aires."
                            },
                            {
                                name: "5 - Free Day in Buenos Aires",
                                description: "A free day to explore Buenos Aires on your own. You can take advantage of a 10% discount at Puerto Cristal restaurant in Puerto Madero to enjoy a delicious dinner. Additionally, if you wish, you can visit the Puerto Madero Casino, with free entry and transfer provided by the casino (for guests over 18 years old). Overnight stay in Buenos Aires."
                            },
                            {
                                name: "6 - Departure from  Buenos Aires",
                                description: "After breakfast, you will have free time before being transferred to the airport for your return flight. End of services."
                            }
                        ],
                        services_included: "In Iguazú:\n" +
                            "Airport/hotel/airport transfers.\n" +
                            "2 nights of accommodation with breakfast.\n" +
                            "Excursions to Iguazú Falls (Brazilian and Argentine sides).\n" +
                            "Choice between a stop at the Bird Park or a visit to Dreamland with an included ticket.\n" +
                            "Stop at the Chocolate Caseiro craft center.\n" +
                            "In Buenos Aires:\n" +
                            "Airport/hotel/airport transfers in semi-private service.\n" +
                            "3 nights of accommodation with breakfast.\n" +
                            "Half-day city tour in regular service.\n" +
                            "Shopping tour at leather and clothing factories.\n" +
                            "10% discount at Puerto Cristal restaurant.\n" +
                            "Free entry and transfer to Puerto Madero Casino.\n" +
                            "Hotel taxes.\n",
                        services_additional: "Dinner show at Rafain Churrascaria in Iguazú (with an additional cost)."
                    }
                ],
            },
            {
                name: "Ecuador",
                flag: "ecuador.png",
                data: [
                    {
                        name: "Magical Galapagos",
                        duration: "4 days",
                        days: [
                            {
                                name: "1 - Airport Reception + Scientific Station ",
                                description: "Upon arrival at Baltra airport, our guide will accompany you on the transfer to Santa Cruz. In the afternoon, you will visit the Charles Darwin Research Station and the Interpretation Center of the Galápagos National Park, where you can learn more about the giant tortoises and iguanas. You will spend the night in Puerto Ayora."
                            },
                            {
                                name: "2 - Tortuga Bay + Highlands ",
                                description: "In the morning, you will explore the beautiful Tortuga Bay, starting with a 2.5 km walk along a paved trail where the guide will explain the local flora. First, you will visit Playa Brava, ideal for surfers due to its strong currents and waves up to 2 meters high. Then, you will head to Playa Mansa, perfect for relaxing and enjoying the sun in its crystal-clear waters and white sand. Afterward, you will take a shared transport from Puerto Ayora to Los Gemelos craters and then continue to a private ranch for lunch. In the afternoon, you will take a walk to observe the giant Galápagos tortoises in their natural habitat and explore a lava tunnel before returning to the port."
                            },
                            {
                                name: "3 - Las Grietas + Playa de los Alemanes",
                                description: "At the time set by the guide, you will meet in the hotel lobby and walk to the dock to take a water taxi to the Punta Estrada area. There, you will take a short walk to reach Las Grietas, an impressive volcanic formation, ideal for swimming in calm, crystal-clear waters, and for those who enjoy diving. Then, you will visit Playa de los Alemanes, a popular spot for its white sand and transparent waters, perfect for relaxing near Puerto Ayora."
                            },
                            {
                                name: "4 - Departure and Transfer to the Airport",
                                description: "After a delicious breakfast, you will check out of the hotel and be transferred to the airport to catch your return flight. End of services."
                            }
                        ]
                    },
                    {
                        name: "Explorar Galapagos",
                        duration: "5 days",
                        days: [
                            {
                                name: "1 - Arrival in Galápagos + Charles Darwin Research Station and Highlands",
                                description: "On February 17th, after arriving at Baltra airport, our guide will take you to visit the Charles Darwin Research Station and the Interpretation Center of the Galápagos National Park, where you can observe giant tortoises and iguanas. Then, you will travel from Puerto Ayora to Los Gemelos craters and a private ranch, where you will have the opportunity to take a walk to see giant tortoises in their natural habitat and explore a lava tunnel. You will end the day by returning to the port and staying at a luxury hotel."
                            },
                            {
                                name: "2 - Isabela Excursion + Tintoreras",
                                description: "On February 18th, you will depart at dawn by boat to Isabela Island, where you will visit Tintoreras, a place known for its abundant marine life, including white-tip reef sharks, sea lions, marine iguanas, blue-footed boobies, Sally Lightfoot crabs, sea turtles, pelicans, and the only penguins in the Galápagos. After the visit, you will enjoy a snorkeling session in a nearby bay before returning to Isabela dock. Accommodation in a luxury hotel."
                            },
                            {
                                name: "3 - Sierra Negra Volcano + Santa Cruz Island",
                                description: "On February 19th, you will head to the highlands of Isabela to start a hike to Sierra Negra Volcano, the second-largest crater in the world. If you wish, you can continue the hike to Volcán Chico, where you will see fumaroles and spectacular lava formations. In the afternoon, you will return to Santa Cruz for your accommodation."
                            },
                            {
                                name: "4 - Seymour Norte Excursion",
                                description: "On February 20th, after breakfast, you will depart for Seymour Norte, where you can hike, swim, and snorkel. On the island, you will see Palo Santo trees, blue-footed boobies, frigatebirds, and sea lions. You will also visit Las Bachas beach, known for its crystal-clear waters and white sand. You will return to the hotel for the night."
                            },
                            {
                                name: "5 - Departure from Galápagos",
                                description: "On February 21st, you will enjoy breakfast at the hotel before having free time to explore or relax. After lunch, you will be transferred to the airport to catch your return flight to Lima."
                            }
                        ],
                        services_included: "4 nights in luxury hotels with breakfast included.\n" +
                            "Professional guide in Galápagos.\n" +
                            "Transportation during excursions and inter-island transfers.\n",
                        services_not_included: "Round-trip flights from Lima to Galápagos.\n" +
                            "Meals other than breakfast.\n" +
                            "Entrance fees to Galápagos National Park.\n" +
                            "Transit Control Card (TCT).\n" +
                            "Additional fees such as boat transport, water taxis, and other unspecified services.\n",
                        recommendations: "Bring sunscreen, insect repellent, swimwear, and travel insurance."
                    }
                ],
            },
            {
                name: "Peru",
                flag: "peru.png",
                data: [
                    {
                        name: "Lima Express",
                        duration: "3 días",
                        description: "Discover the Best of Lima and Paracas in 3 Days with Our Exclusive Travel Package! Are you looking for a unique adventure that combines history, nature, and culture? We have the perfect itinerary for you! Immerse yourself in the vibrant city of Lima, explore the enigmatic Ballestas Islands, and relax in the beauty of Paracas with our 3-Day: Lima and Paracas Package. Designed for travelers who want the best of both worlds, this tour combines cultural experiences, pre-Columbian history, impressive natural landscapes, and culinary delights.",
                        days: [
                            {
                                name: "Day 1 - Cultural Exploration in Lima",
                                description: "Welcome to Lima! Upon your arrival at Jorge Chávez International Airport, our team will be waiting to take you to your comfortable hotel in Miraflores or San Isidro. After a short rest, get ready for a day filled with history and culture. Start your adventure with a visit to the Larco Museum, a hidden gem that houses the largest collection of pre-Columbian art in Lima. Admire the stunning ceramics, textiles, and gold and silver objects from ancient Peruvian civilizations. Enjoy a delicious Peruvian lunch at the museum's charming restaurant, surrounded by gardens. Discover Peru's historical wealth with a visit to the Gold Museum, where you will find jewelry and artifacts of great cultural value. End the day with dinner at one of Miraflores' renowned restaurants and a relaxing walk around Larcomar, an open-air shopping center with stunning views of the Pacific Ocean."
                            },
                            {
                                name: "Day 2 - Nature and Adventure in Paracas",
                                description: "Get ready for an exciting day! We leave early for the beautiful bay of Paracas, a destination known for its rich biodiversity and breathtaking landscapes. Once in Paracas, you will board a boat to explore the impressive Ballestas Islands. Observe sea lions, Humboldt penguins, and a variety of seabirds in their natural habitat up close. After the tour, you will have the opportunity to explore the Paracas National Reserve, a desert paradise with unique beaches like Red Beach and panoramic views that will leave you breathless. Enjoy a delicious dinner with sea views and spend the night in a cozy hotel in Paracas, relaxing after a day full of adventures."
                            },
                            {
                                name: "Day 3 - Return to Lima and Final Touches",
                                description: "After a relaxing night in Paracas, we will return to Lima to finish your trip in style. We will travel back to Lima in the morning, just in time for a delicious lunch in Miraflores or San Isidro, where you can taste local flavors. Enjoy the afternoon exploring Miraflores at your own pace. You can stroll along the Malecón, visit Kennedy Park, or relax at a café with ocean views. End your trip with a farewell dinner in Lima before our team takes you to Jorge Chávez International Airport for your return flight."
                            }
                        ],
                        services_included: "Round-trip transfers to and from the airport.\n" +
                            "Private transportation from Lima to Paracas and back.\n" +
                            "Admission tickets and guided tours to the Larco Museum, Gold Museum, Ballestas Islands, and Paracas National Reserve.\n" +
                            "1 night of accommodation in Paracas with breakfast.\n" +
                            "Personalized assistance throughout the trip.\n",
                        services_not_included: "Flights to Lima.\n" +
                            "Lunches and dinners not mentioned in the itinerary.\n"
                    },
                    {
                        name: "Lima and Paracas",
                        duration: "4 days",
                        description: "Discover the Best of Lima and Paracas in 4 Days with Our Exclusive Travel Package. Looking for an experience that combines history, nature, culture, and gastronomy? We have the perfect itinerary for you. Immerse yourself in the vibrant city of Lima, explore the enigmatic Ballestas Islands, relax in the beauty of Paracas, and learn to prepare the famous Peruvian ceviche and pisco sour with our 4-Day: Lima and Paracas Package. Designed for travelers who want to explore the best of both destinations, this tour includes cultural experiences, pre-Columbian history, impressive natural landscapes, culinary delights, and a unique cooking class.",
                        days: [
                            {
                                name: "Day 1 - Cultural Exploration in Lima",
                                description: "Welcome to Lima! Upon your arrival at Jorge Chávez International Airport, our team will be waiting to take you to your comfortable hotel in Miraflores or San Isidro. After a short rest, get ready for a day filled with history and culture. Start your adventure with a visit to the Larco Museum, a hidden gem that houses the largest collection of pre-Columbian art in Lima. Admire the stunning ceramics, textiles, and gold and silver objects from ancient Peruvian civilizations. Enjoy a delicious Peruvian lunch at the museum's charming restaurant, surrounded by gardens. Discover Peru's historical wealth with a visit to the Gold Museum, where you will find jewelry and artifacts of great cultural value. End the day with dinner at one of Miraflores' renowned restaurants and a relaxing walk around Larcomar, an open-air shopping center with stunning views of the Pacific Ocean."
                            },
                            {
                                name: "Day 2 - Peruvian Cooking Class and Magic Water Circuit",
                                description: "Today, you will learn how to prepare some of Peru's most iconic dishes and enjoy a unique nighttime show. In the morning, participate in a Peruvian cooking class where you will learn to prepare the famous ceviche and pisco sour, the country's signature drink. Under the guidance of a local chef, you'll discover the secrets behind these iconic dishes and enjoy your own creations for lunch. After the class, enjoy free time exploring Miraflores. You can stroll along the Malecón, visit Kennedy Park, or relax at a café with ocean views. At night, enjoy an unforgettable show at the Magic Water Circuit, the largest fountain complex in the world, where you can admire a display of lights, water, and music."
                            },
                            {
                                name: "Day 3 - Nature and Adventure in Paracas",
                                description: "Get ready for an exciting day! We leave early for the beautiful bay of Paracas, a destination known for its rich biodiversity and breathtaking landscapes. Once in Paracas, you will board a boat to explore the impressive Ballestas Islands. Observe sea lions, Humboldt penguins, and a variety of seabirds in their natural habitat up close. After the tour, you will have the opportunity to explore the Paracas National Reserve, a desert paradise with unique beaches like Red Beach and panoramic views that will leave you breathless. Enjoy a delicious dinner with sea views and spend the night in a cozy hotel in Paracas, relaxing after a day full of adventures."
                            },
                            {
                                name: "Day 4 - Return to Lima and Final Touches",
                                description: "After a relaxing night in Paracas, we will return to Lima to finish your trip in style. We will travel back to Lima in the morning, just in time for a delicious lunch in Miraflores or San Isidro, where you can taste local flavors. Enjoy the afternoon exploring Miraflores at your own pace. You can stroll along the Malecón, visit Kennedy Park, or relax at a café with ocean views. End your trip with a farewell dinner in Lima before our team takes you to Jorge Chávez International Airport for your return flight."
                            }
                        ],
                        services_included: "Traslados ida y vuelta al aeropuerto.\n" +
                            "Transporte privado de Lima a Paracas y viceversa.\n" +
                            "Entradas y tours guiados al Museo Larco, Museo de Oro, Islas Ballestas, Reserva Nacional de Paracas y Circuito Mágico del Agua.\n" +
                            "Clase de cocina peruana con almuerzo.\n" +
                            "1 noche de alojamiento en Paracas con desayuno.\n" +
                            "Asistencia personalizada durante todo el viaje.\n",
                        services_not_included: "Boletos de avión a Lima.\n" +
                            "Almuerzos y cenas no mencionados en el itinerario.\n"
                    }
                ],
            },
            {
                name: "United States",
                flag: "usa.png",
                data: [
                    {
                        name: "New York City",
                        duration: "9 days",
                        days: [
                            {
                                name: "1 - SAT - October 5",
                                description: "Arrival in New York City. Check-in at the hotel. Free day to rest and explore the city."
                            },
                            {
                                name: "2 - SUN - October 6",
                                description: "Admission to the Statue of Liberty Cruise. Suggested time: 10:00 AM. Enjoy the ferry to Liberty Island, visit the museum and access the pedestal of the Statue of Liberty, the rest of the day is free to explore Battery Park and the surrounding area."
                            },
                            {
                                name: "3 - MON - October 7",
                                description: "Day Trip to Boston from NY. Departure time: 06:00 AM. Visit Boston's most iconic landmarks, such as Faneuil Hall, Quincy Market, Beacon Hill, and Harvard University. Estimated return to New York: 8:00 PM."
                            },
                            {
                                name: "4 - TUE - October 8",
                                description: "Upper and Lower Manhattan Tour. Start time: 09:00 AM. Tour through Upper Manhattan, passing by Central Park, Lincoln Center, Harlem, and 5th Avenue, continue to Lower Manhattan with stops in Greenwich Village, Soho, Chinatown, Little Italy, Wall Street, 9/11 Memorial, and Battery Park, duration: 4 hours. Afternoon free to continue exploring the city."
                            },
                            {
                                name: "5 - WED - October 9",
                                description: "Day Trip to Washington from NY. Departure time: 06:00 AM. Visit Arlington Cemetery, the Pentagon, the White House, the Lincoln Memorial, among others, visit the Air and Space Museum: Reservation between 2:00 PM and 3:30 PM. Estimated return to New York: 9:00 PM."
                            },
                            {
                                name: "6 - THU - October 10",
                                description: "Contrasts Tour. Start time: 08:00 AM. Explore the neighborhoods of New Jersey, Bronx, Queens, and Brooklyn, option to walk across the Brooklyn Bridge at the end of the tour, duration: 5 hours. Afternoon free to explore the city."
                            },
                            {
                                name: "7 - FRI - October 11",
                                description: "Free day in New York, recommendations: Visit Central Park, Times Square, or a museum such as the MET or MOMA."
                            },
                            {
                                name: "8 - SAT - October 12",
                                description: "Contrasts Tour (Option 2). If you decide to repeat the tour or if you did not do the tour on Thursday, this is another opportunity."
                            },
                            {
                                name: "9 - SUN - October 13",
                                description: "Departure from New York. Check-out from the hotel, transfer to the airport."
                            }
                        ],
                        hotels: ["Hotel Edison", "Hotel Sheraton New York Times Square", "Hotel Hampton Inn Manhattan Times Square Central (with breakfast)"],
                        notes: "Toma en cuenta que hay tours que NO acaban en el mismo hotel, sino que los pasajeros deben regresar al hotel por su cuenta. Los puntos de encuentro para los tours son los siguientes, NO recogen de todos los hoteles en Manhattan.\n" +
                            "Puntos de recogida: Sheraton New York Times Square,RIU plaza New York Times Square y The new Yorker hotel, los horarios de recojo varían dependiendo del tour."
                    },
                    {
                        name: "Disney World Itinerary",
                        duration: "8 days",
                        days: [
                            {
                                name: "DAY 1",
                                description: "Arrival in Orlando. Shared daytime transfer from the airport to the selected hotel and check-in. Overnight stay in Orlando."
                            },
                            {
                                name: "DAYS 2 TO 5",
                                description: "Visit Disney Parks (1 park per day): Animal Kingdom, Magic Kingdom, Hollywood Studios, Epcot. Overnight stay in Orlando."
                            },
                            {
                                name: "DAYS 5 AND 6",
                                description: "Visit to Universal Studios and Islands of Adventure (with Park-to-Park admission). Overnight stay in Orlando."
                            },
                            {
                                name: "DAY 7",
                                description: "Free day for personal activities. Overnight stay in Orlando."
                            },
                            {
                                name: "DAY 8",
                                description: "Check-out from the hotel. Shared daytime transfer to the airport, departure from Orlando."
                            }
                        ],
                        hotels: ["Disney's All-Star Sports Hotel", "Residence Inn Orlando Lake Buena Vista (with breakfast)", "Hampton Inn Orlando Lake Buena Vista (with breakfast)"]
                    }
                ],
            },
            {
                name: "Cruises",
                flag: "usa_2.png",
                data: [
                    {
                        name: "Cruise - Option 1 Nassau, Bahamas",
                        duration: "5 days",
                        departure: "Fort Lauderdale",
                        route: "Nassau, Bahamas / Disney Castaway Cay (Private Island) / Disney Lookout Cay At Lighthouse Point (Private Island)",
                        services_included: "Cruise, full meals, tea, coffee, milk, dispenser sodas, shows, daily entertainment, access to various public areas such as gym, taxes, port charges.",
                        services_not_included: "Air tickets, sodas, freshly squeezed juices, assistance and cancellation insurance, excursions, wifi, transfers, shopping, tips (usd 14.50 per person/per night), casino, spa services, and any item not listed as included, alcoholic beverages."
                    },
                    {
                        name: "Cruise - Option 2 Nassau, Bahamas",
                        duration: "5 days",
                        departure: "Port Canaveral",
                        route: "Nassau, Bahamas / Disney Castaway Cay (Private Island) / Disney Lookout Cay At Lighthouse Point (Private Island)",
                        services_included: "Cruise, full meals, tea, coffee, milk, dispenser sodas, shows, daily entertainment, access to various public areas such as gym, taxes, port charges.",
                        services_not_included: "Air tickets, sodas, freshly squeezed juices, assistance and cancellation insurance, excursions, wifi, transfers, shopping, tips (usd 14.50 per person/per night), casino, spa services, and any item not listed as included, alcoholic beverages."
                    },
                    {
                        name: "Grandeur of the Seas Cruise",
                        duration: "9 nights",
                        departure: "Fort Lauderdale, Florida",
                        route: "San Martín (St. Martin): This destination is known for its crystal-clear waters, white sands, and breathtaking landscapes. Passengers can enjoy the most renowned beaches, participate in water sports, or explore the cultural side of the island.\n" +
                            "Southern Caribbean Islands: The lush islands of the southern Caribbean offer a perfect blend of nature and culture. Travelers will have the opportunity to visit several destinations, each with its own natural beauty, marine life, and opportunities for relaxation or adventure.\n" +
                            "Mexico (Flooded Sinkhole): Among the most fascinating attractions of this itinerary is the visit to karst-origin flooded sinkholes in Mexico. These geological formations are impressive natural water bodies, perfect for swimming and exploring nature.\n",
                        services_included: "Full meals, Access to all public areas of the ship such as the gym, Daily shows and entertainment, Port taxes and charges",
                        services_not_included: "Air tickets, Assistance and cancellation insurance, Alcoholic beverages, soft drinks, freshly squeezed juices, Excursions, Wifi, Transfers, Purchases and services in the casino or spa"
                    },
                    {
                        name: "Serenade of the Seas Cruise – October 11, 2025",
                        duration: "7 nights",
                        departure: "Colón, Panamá",
                        route: "The Serenade of the Seas is back, and this time it takes you to enjoy the best vacation in Latin America! This season, you will sail through the stunning waters of the Southern Caribbean, departing from Colón, Panama, and exploring the paradisiacal islands of Aruba, Bonaire, and Curaçao. This cruise offers a unique experience, full of fun, adventures, and unforgettable moments, ideal for those seeking a combination of relaxation and exploration.",
                        services_included: "Full meals (tea, coffee, milk, dispenser soft drinks, and juices), Deluxe-category alcoholic beverages, Daily shows and entertainment, Access to all public areas of the ship, including the gym, Port taxes and charges",
                        services_not_included: "Air tickets, Assistance insurance, Excursions, Wifi, Transfers, Tips (USD 18.00 per person/per night), Casino, spa services, and any other service not detailed",
                    },
                    {
                        name: "Delfin III – 4 days / 3 nights",
                        duration: "Low water season",
                        departure: "Iquitos",
                        route: "Day 1: Arrival in Iquitos, transfer to Nauta, embarkation, and orientation. Boat exploration on the Marañón River at sunset. Day 2: Bird and sloth watching in Iquitos Caño. Visit to the Amazon Natural Park and ceremony with a shaman. Navigation on the Yanayacu and Pucate Rivers with options for kayaking and pink dolphin sightings. Night safari in Nauta Caño. Day 3: Piranha fishing in Nauta Caño and visit to a local community. Jungle hike in Fundo Casual and Amazon wildlife spotting. Day 4: Disembarkation, visit to the Manatee Rescue Center, and transfer to Iquitos airport.",
                        services_included: "Meals, beverages, guided excursions, and onboard activities",
                        services_not_included: "Air tickets, tips, and WiFi"
                    },
                    {
                        name: "Delfin III – 4 days / 3 nights",
                        duration: "High water season",
                        departure: "Iquitos",
                        route: "Day 1: Arrival in Iquitos, transfer to Nauta, VIP reception, and embarkation. Navigation on the Marañón River and night safari in the Amazon. Day 2: Boat exploration in Iquitos Caño, bird and sloth watching. Walk in the Amazon Natural Park, canopy over 25 meters high. Navigation on Yanayacu Pucate, with piranha fishing and kayaking. Night jungle walk. Day 3: Explore Nauta Caño by boat, spotting monkeys, caimans, and dolphins. Walk in Fundo Casual jungle, spotting anacondas and tarantulas. Visit a local community and tour the confluence of the Ucayali and Marañón rivers. Day 4: Disembarkation and visit to the Manatee Rescue Center. Transfer to Iquitos airport.",
                        services_included: "Meals, beverages, guided excursions, and onboard activities",
                        services_not_included: "Air tickets, tips, and WiFi"
                    }
                ],
            },
            {
                name: "Trains",
                flag: "trains.png",
                data: [
                    {
                        name: "Andean Explorer - Peruvian Altiplano",
                        duration: "3 days / 2 nights",
                        route: "Cusco - Puno - Arequipa",
                        description: "Embark on the ultimate getaway through Peru and travel from Cusco to Arequipa in style, visiting Puno and the majestic Lake Titicaca. Each day includes excursions to uncover the hidden treasures of this fascinating land. The journey across the Peruvian Altiplano departs every Thursday morning.",
                        services_included: "Luxury accommodation onboard the train, All meals and beverages onboard, Onboard entertainment, Excursions as per itinerary",
                        itinerary: "Day 1 – Cusco: 10:00: Check-in at Monasterio, A Belmond Hotel. 11:00: Train departure from Wanchaq station, Cusco. 12:30: Lunch onboard. 14:00: Visit to the archaeological site of Raqchi. 17:20: Arrival at La Raya. 19:00: Cocktails and canapés at the piano bar. 19:30: Dinner onboard. 22:00: Arrival at Lake Titicaca station - Puno Port. Day 2 – Puno: Sunrise at Lake Titicaca. 06:00: Breakfast onboard. 08:30: Tour of Lake Titicaca, visit to the Uros floating islands, lunch at Taquile, and visit to Collata beach. 16:00: Afternoon tea. 16:50: Departure to Saracocha. 19:00: Cocktails and canapés at the piano bar. 19:30: Dinner onboard. 21:15: Arrival at Saracocha. Day 3 – Arequipa: Sunrise at Saracocha. 06:00: Breakfast onboard. 09:50: Tour of the Sumbay caves. 12:30: Lunch onboard. 15:30: Arrival in Arequipa with an option for a guided transfer to the airport."
                    },
                    {
                        name: "Hiram Bingham - Round Trip Train to Machu Picchu",
                        route: "Cusco - Machu Picchu - Cusco",
                        duration: "1 full day",
                        itinerary: "Departure from Cusco: 08:00: Reception at Poroy station, Cusco, with a welcome cocktail. 08:30: Departure of the Hiram Bingham train to Machu Picchu. Enjoy a gourmet brunch while admiring the stunning Andean landscapes. 12:30: Arrival in Aguas Calientes. Transfer by bus to the Machu Picchu citadel. Visit to Machu Picchu: 13:00: Guided tour of Machu Picchu, exploring the mysterious Inca city with an expert guide. 15:30: Free time to enjoy the view or explore the citadel further on your own. Return to Cusco: 17:30: Return to Aguas Calientes and boarding the Hiram Bingham train. Gourmet dinner onboard while the train returns to Cusco. 21:30: Arrival at Poroy station, Cusco, and transfer to the hotel."
                    },
                    {
                        name: "Expedition - Round Trip Train to Machu Picchu",
                        route: "Cusco (Poroy or Ollantaytambo) - Machu Picchu - Cusco",
                        duration: "1 full day",
                        itinerary: "Departure from Cusco/Ollantaytambo: From Cusco (Poroy): 06:40: Departure from Poroy station in Cusco. 09:54: Arrival at Machu Picchu station (Aguas Calientes). From Ollantaytambo: 07:45: Departure from Ollantaytambo station. 09:54: Arrival at Machu Picchu station (Aguas Calientes). Enjoy the landscape of the Sacred Valley of the Incas aboard a comfortable train with panoramic windows that allow you to admire the mountains and local flora. Visit to Machu Picchu: 10:00: Bus transfer from Aguas Calientes to Machu Picchu. 10:30: Guided tour of the Machu Picchu citadel. 14:00: Free time to explore the archaeological site on your own. Return to Cusco: 16:22: Departure of the Expedition train from Aguas Calientes station to Ollantaytambo or Poroy. Arrival at Ollantaytambo: 18:10 Arrival at Cusco (Poroy): 19:50 Transfer to the hotel in Cusco."
                    },
                    {
                        name: "Vistadome - Round Trip Train to Machu Picchu",
                        route: "Cusco (Poroy or Ollantaytambo) - Machu Picchu - Cusco",
                        duration: "1 full day",
                        itinerary: "Departure from Cusco/Ollantaytambo: From Cusco (Poroy): 06:40: Departure from Poroy station in Cusco. 09:54: Arrival at Machu Picchu station (Aguas Calientes). From Ollantaytambo: 07:45: Departure from Ollantaytambo station. 09:54: Arrival at Machu Picchu station (Aguas Calientes). Aboard the Vistadome, enjoy panoramic windows offering spectacular views of the Andean mountains, the Sacred Valley, and the Urubamba River. Visit to Machu Picchu: 10:00: Bus transfer from Aguas Calientes to Machu Picchu. 10:30: Guided tour of the Machu Picchu citadel, with an expert showing you the highlights of this wonder of the world. 14:00: Free time to explore the citadel or enjoy the natural surroundings. Return to Cusco: 16:43: Departure of the Vistadome train from Aguas Calientes station to Ollantaytambo or Poroy. Arrival at Ollantaytambo: 18:31 Arrival at Cusco (Poroy): 19:54 Transfer to the hotel in Cusco."
                    }
                ]
            }
        ]
    },
    europe: {
        name: "Europe",
        data: [
            {
                name: "Europe Express",
                flag: "europe.png",
                data: [
                    {
                        name: "Sicilia Express",
                        duration: "4 days",
                        days: [
                            {
                                name: "01 - MON. Palermo - Cefalù - Castelbuono - Tindaris - Milazzo",
                                description: "Welcome to your Europamundo tour! We will depart from Palermo, following the coast of the Tyrrhenian Sea, enjoying beautiful landscapes along the way. Our first stop is CEFALÙ, a charming coastal city. Its Norman Cathedral, with its original architecture and mosaics, has been declared a World Heritage Site. The historic center is incredibly beautiful, featuring narrow streets, sea views, and bustling commercial and tourist life. After lunch, we continue inland to CASTELBUONO, a small and picturesque town nestled among the mountains, where you can experience the tranquility of rural life with its walls, castle, and quaint streets. We then head back to the coast towards TINDARIS, situated high on a mountain overlooking the sea. The Sanctuary of Tindari, a famous pilgrimage site known for its Black Madonna and stunning views, awaits us here. Nearby, you will find the remains of an ancient Greek city. We continue to MILAZZO, a lively port city facing the volcanic Aeolian Islands."
                            },
                            {
                                name: "02 - TUE. Milazzo - Lipari - Vulcano - Taormina",
                                description: "Today is filled with excitement as we explore two of the islands in the AEOLIAN ARCHIPELAGO, declared a World Heritage Site for their ongoing volcanic activity. We will take a ferry to LIPARI, the largest and most pleasant of the Aeolian Islands, known for its historic center, castle, and vibrant tourist scene. You'll have time to have lunch and explore. Then, we will board another ferry that will take us in less than 30 minutes to VULCANO, where the scent of sulfur permeates the entire island and active fumaroles are a common sight. The island, dominated by its crater, is famous for its hot beaches, and we highly recommend experiencing the thermal sulfur baths, known for their beneficial effects on the skin. Don’t miss out on this unique experience! Afterward, we will take the ferry back to Milazzo (a little over an hour crossing) to continue to TAORMINA, arriving at the end of the day. Note: In summer or on specific dates, accommodation may be provided in Messina instead of Taormina."
                            },
                            {
                                name: "03 - WED. Taormina - Caltagirone - Villa Romana del Casale - Palermo",
                                description: "At the foot of Mount Etna, the stunning city of TAORMINA was described by Churchill as the most beautiful place on Earth. You'll have time to stroll through the lively old town, where its Greek theater offers unforgettable views. Late in the morning, we continue our journey toward the center of the island to CALTAGIRONE, a city declared a World Heritage Site for its rich Baroque heritage. We include a tour on a tourist train, where you will receive explanations about the city and see its main attractions. Caltagirone is Sicily's ceramic capital, as evidenced by its many shops and workshops. One of its most iconic spots is the 142-step staircase, each step adorned with different tiles. After lunch, we head to VILLA ROMANA DEL CASALE (entrance included), a World Heritage Site. During the Roman Empire, villas were symbols of agricultural exploitation. At El Casale, not only has the 4th-century architectural configuration been preserved, but the abundance and quality of the mosaics decorating almost all the rooms are considered the most beautiful in the Roman world. Crossing the mountainous center of the island, we continue to PALERMO, arriving at the end of the day."
                            },
                            {
                                name: "04 - THU. Palermo",
                                description: "Today, we include a guided tour of the city of PALERMO. Highlights include the Cathedral (entrance included) with its blend of styles and royal tombs inside; the Palace of the Normans (the former royal palace); its fountains, markets, and gardens. During the visit, we will also go to MONREALE (entrance included), where its Cathedral has been declared a World Heritage Site for its impressive golden mosaics that cover the interior. The afternoon is free for you to explore at your leisure. Note: Occasionally, if the number of travelers is very limited, the visit may not be private and will instead be conducted as a regular excursion with local companies. End of our services. Please check your flight time in case you need an additional night."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel accommodation, and buffet-style breakfast included.\n" +
                            "Panoramic tour in: Palermo.\n" +
                            "Excursion to: Monreale from Palermo.\n" +
                            "Included tickets for: Villa Romana del Casale, Palermo Cathedral, and Monreale Cathedral.\n" +
                            "Train ride through: Caltagirone.\n" +
                            "Ferry through: the Aeolian Islands."
                    },
                    {
                        name: "Barcelona and Valencia",
                        duration: "4 days",
                        days: [
                            {
                                name: "Day 1 - Monday / Thursday. Madrid - Zaragoza - Poblet - Barcelona",
                                description: "We depart from Madrid towards Aragón, crossing the lands of Castilla. We will make a brief stop in Zaragoza to visit the Basilica del Pilar. After lunch, we continue to the Monastery of Poblet, surrounded by walls in a beautiful setting; it is one of the best-preserved medieval monasteries in the world (entrance included). We continue to Barcelona, arriving in the late afternoon."
                            },
                            {
                                name: "Day 2 - Tuesday / Friday. Barcelona",
                                description: "In the morning, we include a panoramic tour of the city, considered one of the most interesting European capitals, open to the Mediterranean Sea, famous for Gaudí and its modernist architecture, not to mention its Ramblas and memories of the Universal Expositions and Olympics held there. Free afternoon. In the evening, we include a transfer to Maremagnum, a modern leisure and restaurant center by the sea, next to Plaza Colón, where you can stroll around this lively area and have dinner."
                            },
                            {
                                name: "Day 3 - Wednesday / Saturday. Barcelona - Peñíscola - Valencia - Madrid",
                                description: "We travel south along the Mediterranean coast. We will make a stop in Peñíscola, a small medieval walled city surrounded by beautiful beaches. Free time for a walk. We continue to Valencia, a port city on the Levantine coast, arriving around lunchtime. Will you try their famous paella? Around 4:30 p.m., we depart, and after a brief stop at the avant-garde City of Arts and Sciences in Valencia, we continue towards Madrid. Arrival is expected around 8:30 / 9:00 p.m. Note: If the number of travelers from Valencia to Madrid is very limited, the journey may be made by high-speed train."
                            }
                        ],
                        services_included: "Travel by coach with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Panoramic tours in: Poblet, Barcelona.\n" +
                            "Night transfer: Maremagnum in Barcelona.\n" +
                            "Entrance: Monastery of Poblet.\n"
                    },
                    {
                        name: "Basque Country",
                        duration: "3 days",
                        days: [
                            {
                                name: "01 - SAT. Madrid - Segovia - Pedraza - Bilbao",
                                description: "We depart towards the lands of Castilla, crossing mountains and enjoying beautiful landscapes. Our first stop is SEGOVIA. As we circle the city, we arrive at the Mirador de la Pradera de San Marcos, from where you can get the best views of the Alcázar of Segovia and its walls, which inspired Walt Disney for the castle of the witch in Sleeping Beauty. Later, we park next to the Roman aqueduct of Segovia, built in the 1st century and in use until 1973, now declared a UNESCO World Heritage Site. You will have free time to walk around the old town, visit the Plaza Mayor, and see the cathedral and the Alcázar. We continue our route along small roads that allow us to appreciate the villages and landscapes on the way to PEDRAZA, considered one of the most beautiful villages in Spain. This walled medieval village features cobbled streets, historic buildings, a Plaza Mayor, and a fortress dating back to the 13th century. You will have time to have lunch here. In the afternoon, we continue our journey to the Basque Country, arriving in BILBAO. We recommend a stroll through the old town, known for its numerous taverns and bars where you can taste the famous pintxos."
                            },
                            {
                                name: "02 - SUN. Bilbao - Portugalete - Guernica - San Sebastián",
                                description: "Today we explore Bilbao and its estuary, highlighting the Guggenheim Museum with its impressive exterior architecture, the old town with its lively streets, and the cathedral. We will also visit the Vizcaya Bridge (entrance included) in Portugalete, declared a UNESCO World Heritage Site and known as the world's first transporter bridge for pedestrians and vehicles. You will have free time to walk around and have lunch. We recommend the La Ribera Market, which offers stalls with typical Basque cuisine in a structure resembling a boat by the river. After lunch, we head to GUERNICA, known as the scene of one of the worst massacres of the Spanish Civil War, immortalized in Pablo Picasso's painting. Here, you will find the Tree of Guernica, under whose branches autonomy was granted to the Basque Country in the Middle Ages. We continue to HONDARRIBIA (Fuenterrabía), a place with beaches, walls, a seafaring atmosphere, and a historic center where the grid-like layout of its narrow, cobbled streets is striking. Time to explore the historic center. Finally, we head to SAN SEBASTIÁN, arriving in the late afternoon. We suggest exploring the Parte Vieja, famous for its lively pintxos bars and vibrant atmosphere, a popular destination for food lovers. Each establishment has its own specialty and style, making trying different pintxos an exciting and delicious experience."
                            },
                            {
                                name: "03 - MON. San Sebastián - Burgos - Madrid",
                                description: "In the morning in San Sebastián, we include a funicular ride up Mount Igueldo, where you will enjoy a magnificent view over the bay. Afterward, you will have free time to enjoy the large beach or stroll along its boulevards. After lunch, we will travel along a highway with beautiful landscapes toward Castilla, arriving in BURGOS. You will have time to visit the historic center, where the Burgos Cathedral, a gem of Gothic style, stands out. We continue towards MADRID, arriving at the end of the day. End of our services. Please check your flight time in case you need an additional night."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel accommodation, and buffet-style breakfast.\n" +
                            "Panoramic tour in: Bilbao.\n" +
                            "Transfer: Mirador de la Pradera de San Marcos in Segovia.\n" +
                            "Entrance tickets: Vizcaya Bridge in Portugalete.\n" +
                            "Funicular: Mount Igueldo in San Sebastián.\n"
                    },
                    {
                        name: "Catalonia and France ",
                        duration: "4 days",
                        days: [
                            {
                                name: "Day 1 - Tuesday. Barcelona - Gerona - Besalú - Colliure - Carcassonne",
                                description: "We travel north through Catalonia. Arrival in Gerona, where we will have time to explore this city with a magnificent historic center dominated by medieval buildings and Roman, Arab, and Jewish influences. We recommend visiting the Cathedral of Santa María, an impressive Gothic building in the upper part of the city, and the old Jewish quarter with its narrow and picturesque streets. We continue to the small medieval town of Besalú, where there will be time for lunch and a stroll through its streets, its impressive fortified stone bridge, its castle, Jewish baths, and the old pilgrims' hospital. After lunch, we continue to French Catalonia. Arrival in Colliure, a charming small coastal town with a beach, port, narrow streets, and an impressive castle built on an old Roman castrum, which housed the court of the Kings of Mallorca. If you wish, you can visit the tomb of the poet Antonio Machado. In the late afternoon, arrival in Carcassonne, considered the best-walled city in Europe. We suggest visiting to see the illuminated walls at night and dining in one of its hundreds of small restaurants in la cité."
                            },
                            {
                                name: "Day 2 - Wednesday. Carcassonne - Narbonne - Marseille",
                                description: "Morning in Carcassonne. With our guide, we will visit its impressive Count's Castle (entrance included) and its medieval walls. Afterward, you will have free time to stroll around. After lunch, we will continue to Narbonne, where we will have time to explore this historic Occitan city with its picturesque historic center. In the late afternoon, we arrive in Marseille, the second-largest city in France. We recommend a walk around its lively Vieux Port, filled with small restaurants."
                            },
                            {
                                name: "Day 3 - Thursday. Marseille - Aix en Provence - Avignon - Pont du Gard - Barcelona",
                                description: "Today we will discover the Provence region. In Aix en Provence, the birthplace of painter Paul Cézanne, we will have time to stroll through its magnificent historic center, with its small streets, fountains, and picturesque squares. We continue to Avignon, where we will have time to walk and have lunch in this walled city on the banks of the Rhône River, which was the seat of the Catholic popes, becoming the second Rome until it was integrated into France in 1791. Its immense Palace of the Popes reveals its historical past. We continue to Pont du Gard (entrance included), an impressive Roman aqueduct and UNESCO World Heritage Site, one of the most famous and best-preserved Roman monuments in the world. We continue our journey back to Catalonia. Arrival in Barcelona at the end of the day."
                            },
                            {
                                name: "Day 4 - Friday. Barcelona",
                                description: "After breakfast, our services end."
                            }
                        ],
                        services_included: "Travel by coach with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Panoramic tour in: Barcelona.\n" +
                            "Entrances: Count's Castle in Carcassonne, Roman Aqueduct at Pont du Gard.\n"
                    },
                    {
                        name: "Oporto and Lisbon",
                        duration: "4 days",
                        days: [
                            {
                                name: "Day 1 - Saturday. Madrid - Ciudad Rodrigo - Oporto",
                                description: "Early departure from Madrid towards Castilla. We will make a brief stop at the Mirador de los 4 Postes, offering a spectacular view over the walls of Ávila. Near the border with Portugal, we stop in Ciudad Rodrigo for a stroll to see its cathedral, walls, and medieval streets. We continue towards Portugal. Arrival in Oporto and accommodation. We will take a panoramic tour of this city, the second largest in Portugal, known for its wines. At the end of the tour, we will visit a centuries-old winery. Free time. Note: If the number of passengers between Madrid and Oporto is very small, this section may be done by plane, in which case the stop in Ciudad Rodrigo will be canceled."
                            },
                            {
                                name: "Day 2 - Sunday. Oporto - Coimbra - Batalha - Nazaré - Óbidos - Lisbon",
                                description: "We travel south. Free time in Coimbra, the third largest city in Portugal, known for its ancient university and charming steep streets. Then, we will stop by the Monastery of Batalha, an impressive medieval building where some kings are buried. Time for lunch and a walk in Nazaré, a fishing and seaside town. We include a ride on the “elevador” that takes us to the old town with stunning views of the ocean. On the way to Lisbon, we will stop in Óbidos, one of the most beautiful villages in Portugal, with its white streets full of flowers. Arrival in Lisbon in the late afternoon."
                            },
                            {
                                name: "Day 3 - Monday. Lisbon",
                                description: "In the morning, we will take a panoramic tour of the city to discover the Portuguese capital of the 7 hills, its narrow streets, tiles, saudade, and Atlantic light. We will also visit the Belém district, from where the great Portuguese navigators who crossed the oceans in the 15th century set sail. Free afternoon to stroll and visit the Bairro Alto and its viewpoint. If you wish, you can take an optional excursion to the towns of Sintra, Cascais, and Estoril. In the evening, we return from the lively Restauradores Square, with its bars and cafés, back to the hotel."
                            },
                            {
                                name: "Day 4 - Tuesday. Lisbon",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Travel by coach with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Panoramic visits in Oporto and Lisbon.\n" +
                            "Night transfer: Return from Restauradores Square in Lisbon.\n" +
                            "Entrance to the wine cellars in Oporto.\n" +
                            "Funicular ride in the Old Town of Nazaré.\n"
                    },
                    {
                        name: "Nordic Triangle",
                        duration: "6 days",
                        days: [
                            {
                                name: "Day 1 - Sunday. Oslo",
                                description: "Welcome! Free time. In the late afternoon, you will receive information about the start of your tour through the informational signs located at the hotel reception."
                            },
                            {
                                name: "Day 2 - Monday. Oslo - Orebro - Stockholm",
                                description: "We include a panoramic tour with a local guide of Norway's capital, Oslo. This city combines vibrant modern architecture with classical buildings, a magnificent landscape setting, and large parks with sculptures. Oslo has been named the European Green Capital. After the tour, there will be free time. Departure to Sweden. During the journey, we will enjoy landscapes of forests and lakes. We will make a stop in Orebro, a historic city by a beautiful lake with a castle and a charming historic center. Arrival in Stockholm at the end of the day, the capital of Sweden, a beautiful city in the archipelago of the Baltic Sea, connected by numerous bridges and ferries, known for its rich history, impressive architecture, and blend of modernity and tradition."
                            },
                            {
                                name: "Day 3 - Tuesday. Stockholm",
                                description: "We will take a panoramic tour of this beautiful capital built on 14 interconnected islands. We will visit Gamla Stan, or City Between the Bridges, the old town full of life, where many monumental buildings such as the Nobel Museum are located, situated next to Stortorget Square, which pays homage to Nobel Prize winners, the Cathedral, and the Royal Palace. When possible, we will have a spectacular view from the Fjällgatan viewpoint. Free time. We recommend visiting its museums and parks. Optionally, we suggest a visit to the City Hall and the Vasa Ship Museum."
                            },
                            {
                                name: "Day 4 - Wednesday. Stockholm - Helsingborg - Helsingor - Copenhagen",
                                description: "We travel south. Early in the afternoon, after some time in the active coastal city of Helsingborg, we take a short ferry from Sweden to Helsingor in Denmark. Free time in Helsingor, the city of Hamlet, where the great Kronborg Castlestands out, before continuing to the next city, Copenhagen, arriving in the late afternoon."
                            },
                            {
                                name: "Day 5 - Thursday. Copenhagen",
                                description: "In the morning, we include a panoramic tour with a local guide of the Danish capital, known for its quality of life, respect for sustainability, and rich cultural life. We will visit The Little Mermaid, the Nyhavn harbor with its colorful historic buildings, the exterior of the Amalienborg Palace where the Danish royal family resides, the City Hall Square, and the Tivoli Park area. Free afternoon."
                            },
                            {
                                name: "Day 6 - Friday. Copenhagen",
                                description: "After breakfast, our services end."
                            }
                        ],
                        services_included: "Travel by coach with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Panoramic tours in: Oslo, Stockholm, Copenhagen.\n" +
                            "Ferry: Sweden to Denmark.\n"
                    }
                ],
            },
            {
                name: "Europe 7 to 11 days",
                flag: "europe_2.png",
                data: [
                    {
                        name: "Italian Beauties",
                        duration: "11 days",
                        days: [
                            {
                                name: "01 - Friday. Rome - Termoli - Monte Sant'Angelo - Trani - Bari",
                                description: "Welcome to your European tour! Today we travel to the Adriatic Sea, with magnificent landscapes crossing the Apennine Mountains. We stop to visit the San Giovanni in Venere Abbey, a beautiful 11th-century abbey built on the ruins of an ancient Roman temple. Arrival in Termoli, a charming seaside town whose beach is dominated by the walled old town, featuring its 13th-century castle and Romanesque cathedral. Time for lunch and a stroll. We continue to Trani, the pearl of the Adriatic, a magnificent city that embraces the sea and showcases its pinkish Romanesque cathedral. Arrival in Bari, with time for a walk around the important port, promenade, and alleys of the old town before heading to the hotel."
                            },
                            {
                                name: "02 - Saturday. Bari - Brindisi - Lecce - Taranto - Brindisi",
                                description: "After breakfast, we have time to stroll through the old town of Bari, a port city inhabited since before Roman times. Its narrow streets, churches, and lively atmosphere make it a very special city. We then continue to Brindisi, a walled port city where you can enjoy a pleasant walk by the sea, see the impressive Roman column, and stroll through the old neighborhood. Our next stop is Lecce, a charming city known for its impressive Baroque architecture, which has earned it the nickname Florence of the South. The historic center is full of beautifully decorated Baroque buildings and churches. Time for a stroll and lunch. In the afternoon, we visit Taranto, a vibrant city in southern Italy with its Aragonese castle, old quarter, and cathedral. Return to Brindisi at the end of the day. Accommodation."
                            },
                            {
                                name: "03 - Sunday. Brindisi - Alberobello - Matera - Naples",
                                description: "Today we visit two spectacular and unique towns. Alberobello, a charming village famous for its unique conical houses known as “trulli.” These unique structures, with their stacked stone roofs, are a UNESCO World Heritage site and considered an outstanding example of rural Italian architecture. We then continue to Matera, located in the Basilicata region, known for its ancient historic center with the “Sassi di Matera,” a complex of ancient houses and buildings carved into the rock that dates back thousands of years. This site is a UNESCO World Heritage site and is one of the oldest continuously inhabited human settlements in the world. Time for a stroll and lunch. In the afternoon, we travel through Basilicata and Campania among beautiful mountainous landscapes. Arrival in Naples in the late afternoon. Free time."
                            },
                            {
                                name: "04 - Monday. Naples - Capri - Sorrento - Salerno",
                                description: "Free time in Naples before embarking to the Island of Capri, where you can take a boat to the White Grotto or the funicular to Capri capital. After lunch, we embark again in the direction of Sorrento, a charming tourist town at the foot of a cliff. Time to stroll. We continue to the Gulf of Salerno. Accommodation in Salerno."
                            },
                            {
                                name: "05 - Tuesday. Salerno - Paola - Messina - Taormina",
                                description: "We travel south down the boot. Attractive landscapes of southern Italy, crossing the Cilento National Park. We arrive in Paola, on the shores of the Tyrrhenian Sea, visit the Sanctuary of San Francesco di Paola, and have free time to have lunch in its beautiful historic center. We continue our route to Villa San Giovanni, where we take a short ferry ride to the island of Sicily. Arrival in Messina, where we will have time for a walk by the cathedral. Continuation to Taormina."
                            },
                            {
                                name: "06 - Wednesday. Taormina",
                                description: "At the foot of Mount Etna, the beautiful city of Taormina was defined by Churchill as the most beautiful place on earth. Time to stroll through the old town full of life; its Greek theater dominates unforgettable landscapes. You can also enjoy the beach. Lunch and dinner included."
                            },
                            {
                                name: "07 - Thursday. Taormina - Noto - Syracuse - Agrigento",
                                description: "We travel to Noto, a small city frozen in time, a Baroque jewel of Sicily. We continue later to Syracuse, a city that was once a rival to Athens and Rome due to its power and splendor. Time to visit its magnificent historic center and have lunch. We continue to Agrigento, where from the coach we will see the magnificent Valley of the Temples. Dinner included."
                            },
                            {
                                name: "08 - Friday. Agrigento - Marsala - Erice - Palermo",
                                description: "With a local guide, we visit the Valley of the Temples, an impressive archaeological site considered the most complete Greek temple complex (entrance included). We travel to Marsala, a historic city with a great atmosphere and famous wine; time for lunch. We continue along the salt flats of Marsala to Erice, a very picturesque town of Phoenician origin that overlooks the sea. Time for a stroll. We continue to Palermo. Dinner included."
                            },
                            {
                                name: "09 - Saturday. Palermo - Monreale - Night crossing",
                                description: "After breakfast, visit Palermo including a transfer to the beautiful village (now a suburb of the city) of Monreale, with its beautiful Arab-Norman cathedral (entrance included), a UNESCO World Heritage site, for its impressive golden mosaics covering the interior. The Sicilian capital is a city full of contrasts with stories of Arabs, Normans, and Baroque splendor. Highlights include the Cathedral (entrance included) and the Palazzo dei Normanni with its Palatine Chapel. Free time at the end of the visit. Late afternoon boarding on a modern night ferry. Accommodation in double cabins with bathroom."
                            },
                            {
                                name: "10 - Sunday. Naples - Pompeii - Cassino - Rome",
                                description: "Arrival in Naples early in the morning (breakfast not included) and continuation to Pompeii, where you can admire the remains of this Roman city that was buried by the ashes of Vesuvius with a local guide (entrance and visit with a local guide included). After that, we visit Naples, one of the most fascinating cities in Italy and one of the oldest and continuously inhabited in Europe. The historic center of Naples is one of the largest in Europe and has been declared a UNESCO World Heritage site. Time to stroll and have lunch. Continuation to Cassino, where you can visit the immense abbey where one of the hardest battles of World War II took place. Arrival in Rome at the end of the day."
                            },
                            {
                                name: "11 - Monday. Rome",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Panoramic tour in: Agrigento, Palermo, Pompeii.\n" +
                            "Transfer: Monreale in Palermo.\n" +
                            "Entrances: Entrance and visit to the Valley of the Temples in Agrigento, Palermo Cathedral, Monreale Cathedral, Pompeii.\n" +
                            "Ferry: Naples/Capri/Sorrento, San Giovanni/Messina in Sicily, Palermo/Naples.\n" +
                            "1 Lunch included in: Taormina.\n" +
                            "3 Dinners included in: Taormina, Agrigento, Palermo.\n"
                    },
                    {
                        name: "Classic Spain and France",
                        duration: "8 days",
                        days: [
                            {
                                name: "01 - Tuesday / Wednesday / Sunday. Barcelona",
                                description: "Welcome. Transfer to the hotel and free time. In the afternoon, you will receive information for the start of your tour, either through the informative posters located at the hotel reception."
                            },
                            {
                                name: "02 - Wednesday / Thursday / Monday. Barcelona - Madrid",
                                description: " In the morning, we include a panoramic tour of Barcelona. You will admire this dynamic city. Time to stroll. At 13:00, departure from Barcelona to Madrid. Stop at a service area on the highway for lunch. Arrival in Madrid around 21:00."
                            },
                            {
                                name: "03 - Thursday / Friday / Tuesday. Madrid",
                                description: "In the morning, we include a panoramic tour of the city, where we will visit the most representative monumental points of Madrid. Rest of the day free. Optionally, we offer you the chance to visit the historic city of Toledo."
                            },
                            {
                                name: "04 - Friday / Saturday / Wednesday. Madrid - San Sebastián - Bordeaux",
                                description: "We leave early from Madrid, passing through the mountainous steps and lands of Castile with beautiful landscapes in the Basque Country. San Sebastián, arrival at lunchtime. Time to have lunch and stroll through the old town or along the beautiful La Concha beach. Continuation of the route to France. Bordeaux, arrival in the late afternoon. We suggest a walk through the center of this UNESCO World Heritage city."
                            },
                            {
                                name: "05 - Saturday / Sunday / Thursday. Bordeaux - Blois - Paris",
                                description: "We cross the French countryside, lands of wines: Bordeaux, Cognac. In the Loire Valley, we stop in Blois, a city by the river, with its castle, one of the most beautiful examples of architecture in the Loire Valley. Time to have lunch and stroll. We continue to Paris, where we arrive in the late afternoon."
                            },
                            {
                                name: "06 - Sunday / Monday / Friday. Paris",
                                description: "In the morning, we include a transfer to the Palace of Versailles, where you will have free time to stroll through its beautiful gardens or visit the interior of the palace if you wish (for groups with a visit on Monday, this transfer will be made on Tuesday due to the palace's closure). We will return to Paris at lunchtime and head to the bohemian district of Montmartre with its small restaurants and street artists. After lunch, we take a panoramic tour of Paris with a local guide. Return to the hotel. Optionally, you can attend a variety show."
                            },
                            {
                                name: "07 - Monday / Tuesday / Saturday. Paris",
                                description: "Free day in Paris. If you wish, you can take the optional activity of visiting the Latin Quarter and a boat ride on the Seine River."
                            },
                            {
                                name: "08 - Tuesday / Wednesday / Sunday. Paris",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel, and continental breakfast.\n" +
                            "Arrival transfer.\n" +
                            "Panoramic tour in: Barcelona, Madrid, Paris.\n" +
                            "Excursion: Versailles (entrance to the palace not included).\n" +
                            "Transfer: Montmartre district in Paris.\n"
                    },
                    {
                        name: "Sweet France",
                        duration: "8 days",
                        days: [
                            {
                                name: "01 - Wednesday. Paris - Giverny - Rouen - Arromanches - Caen",
                                description: "Early departure from Paris. We travel to Normandy. We stop in Giverny, where we include admission to Monet's house and its stunning gardens; we will talk about Impressionist art, which had its center in Normandy. Then, we arrive in Rouen, the capital of Normandy, with its medieval streets and cathedral. Time to stroll and have lunch. We continue to Arromanches, where on its beaches we remember the landing in France during World War II. We then continue to Caen. Time for a stroll through the center. Note: From November to March, Monet's house is closed to visitors, so there will be no stop in Giverny."
                            },
                            {
                                name: "02 - Thursday. Caen - Mont St Michel - St. Malo - Dinan - Rennes",
                                description: "We travel early to Mont Saint Michel, known as the marvel. Time to explore the city surrounded by the sea and the abbey. Later, we travel to Saint Malo in Brittany, where strong walls defend the city. Time for lunch. In the afternoon, a stroll in Dinan, a charming Breton town. We continue to Rennes, the capital of Brittany, with its interesting and monumental historic center."
                            },
                            {
                                name: "03 - Friday. Rennes - Angers - Tours - Villandry - Amboise",
                                description: "We depart for Angers; those who wish can visit the castle that houses the largest medieval tapestry on the Apocalypse. We then continue to the Loire Valley: Tours, the regional capital on the banks of the Loire River. Time to stroll and have lunch. In the afternoon, we visit the Palace of Villandry, famous for its gardens and flowers. Admission to the gardens is included. Continuation to Amboise, a city with an interesting medieval quarter on the banks of the Loire. Dinner included and accommodation."
                            },
                            {
                                name: "04 - Saturday. Amboise - Chenonceau - Chambord - Limoges",
                                description: "A day to get to know the Loire region in depth. A walk through Amboise early in the morning to the mansion of Clos Lucé, where Leonardo da Vinci spent his last three years of life until his death. We include admission to the Château de Chenonceau, shaped like a bridge over the river. We visit Chambord, the largest of the Loire castles (park entrance included). Later, we travel to central France. Limoges, a city known for its porcelain. We suggest strolling through its historic center, where the Cathedral of St. Stephen stands out."
                            },
                            {
                                name: "05 - Sunday. Limoges - Lascaux - Sarlat - Beynac - Rocamadour",
                                description: "We leave Limoges for the Dordogne, another of the great historic rivers in central France. Few regions contain so many charming villages and unforgettable landscapes. We arrive in Lascaux and include admission to the museum and replica of the caves, where we will learn about prehistory with the magnificent cave paintings. Then we visit Sarlat, a UNESCO World Heritage city that preserves an incredibly well-preserved Renaissance historic center. Time to stroll and have lunch. In Beynac, we visit a medieval castle overlooking the river (entrance included). Rocamadour, a beautiful medieval village with an important sanctuary hanging on a cliff; we suggest walking down the Way of the Cross and returning by elevator. Dinner included."
                            },
                            {
                                name: "06 - Monday. Rocamadour - Padirac - Collonges la Rouge - St. Emilion - Bordeaux",
                                description: "We head to the impressive Padirac caves, where a boat ride takes place 75 meters underground (admission included with a boat ride on the underground river). A stop in Collonges la Rouge, a picturesque village built with red stone on the Camino de Santiago. Time for lunch. After lunch, we travel to Saint Emilion, in the heart of the Bordeaux wine region. Time to stroll. Bordeaux, arrival in the late afternoon. Note: From November to the end of March or due to justifiable inclement weather, the Padirac caves may be closed."
                            },
                            {
                                name: "07 - Tuesday. Bordeaux - Paris",
                                description: "The entire center of Bordeaux has been declared a UNESCO World Heritage site. You will be amazed by the “water mirror” and will walk through the old town with its pedestrian streets and its 11th-century cathedral. We will include admission (self-guided visit with information headsets in Spanish, French, or English) to La Cité du Vin, an impressive interactive museum that allows you to learn about the history of wine and its production. Wine tasting is included at the viewpoint over Bordeaux. You will have free time later to stroll and have lunch by the banks of the Garonne River, an area full of small restaurants. After lunch, around 13:00, we will accompany you to the train station where we include a ticket on a modern high-speed train. In just over two hours, you will travel the 500 km that separate Bordeaux from Paris. Arrival in Paris - station and transfer to the hotel."
                            },
                            {
                                name: "08 - Miércoles. París",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Boat: Padirac Caves.\n" +
                            "Entrance tickets: Monet's House and Gardens in Giverny, Gardens of the Palace of Villandry, Château de Chenonceau, Chambord Castle Park, Museum and replica of the Lascaux caves, Beynac Castle, Padirac Caves, La Cité du Vin in Bordeaux.\n" +
                            "High-speed train: Bordeaux - Paris in tourist class.\n" +
                            "2 dinners included in: Amboise, Rocamadour.\n"
                    },
                    {
                        name: "Northern Spain and France Tour",
                        duration: "9 days",
                        days: [
                            {
                                name: "01 - Tuesday. Barcelona - Girona - Besalú - Colliure - Carcassonne",
                                description: "Welcome to your Europamundo tour! We travel north towards Catalonia. Girona, time in this city with a magnificent historic quarter dominated by medieval constructions and Roman, Arab, and Jewish reminiscences. We suggest visiting the Cathedral of Santa Maria, an impressive Gothic building in the upper part of the city, and the old Jewish quarter with its narrow, picturesque streets. We continue to the small medieval city of Besalú; time for lunch and a walk through its streets, its impressive fortified stone bridge, its castle, Jewish baths, and the old pilgrims' hospital. After lunch, we continue towards French Catalonia. Colliure is a small charming coastal town with its beach, port, streets, and impressive castle built over an ancient Roman castrum, which housed the court of the kings of Mallorca. If you wish, you can visit the tomb of the poet Antonio Machado. In the late afternoon, we arrive in Carcassonne, considered the best-walled city in Europe. We suggest approaching to see the illuminated walls at night and dining in one of its hundreds of small restaurants in la cité."
                            },
                            {
                                name: "02 - Wednesday. Carcassonne - Narbonne - Marseille",
                                description: "Morning in Carcassonne. With our guide, we will visit its impressive Comtal Castle (admission included) and its medieval walls. You will have free time afterward to stroll. After lunch, we will continue to Narbonne, where we will have time to explore this historic Occitan city with its picturesque historic center. In the late afternoon, we arrive in Marseille, France's second city; we recommend a stroll around its lively Vieux Port full of small restaurants."
                            },
                            {
                                name: "03 - Jueves. Marsella - Arles - Les Baux - Aviñón - Lyon",
                                description: "Villages, landscapes, history: today, we will visit some of the most beautiful places in Provence. Arles, a World Heritage city, shows its legacy from Roman and medieval times and reminds us of Van Gogh. In Les Baux, we have the opportunity to visit this small medieval town and include the entrance to Carrieres de Lumiere, an impressive underground art, image, and sound show in ancient quarries. Time for lunch and a walk in Avignon, with its immense Papal Palace. We continue to Lyon, where we can walk through the old quarter, declared a World Heritage Site, and include the funicular to go up to Notre Dame de Fourviere, a huge basilica with a great view over the city. Note: When it is not possible to obtain tickets for the Carrieres de Lumière show due to closure or crowds, an alternative transfer will be made to the Roman aqueduct of Pont du Gard."
                            },
                            {
                                name: "04 - Friday. Lyon - Tournus - Beaune - Savigny Castle - Dijon",
                                description: "We will leave Lyon for Burgundy. We stop in Tournus, a pleasant small town with its huge stone gate and great abbey. Then we travel to Beaune. Time to stroll and have lunch in the capital of Burgundy wines. You can visit the wine museum or see the historic hospice. Afterward, we visit the Savigny Castle in the heart of this wine region, built in the 15th century and housing some extraordinary museums (motorcycle, race car, airplane, and helicopter museums). Continue to Dijon, the capital of Burgundy, with its lively streets and magnificent urban ensemble with numerous medieval and Renaissance buildings, highlighting the Dukes' Palace."
                            },
                            {
                                name: "05 - Saturday. Dijon - Besancon - Colmar - Riquewihr - Ribeauville - Strasbourg",
                                description: "Still in the Burgundy region, we stop in Besancon, a city of Roman origin with a beautiful historic center and a 17th-century citadel overlooking the city. We continue our route to Alsace, a French region disputed over the centuries between France and Germany, which maintains its own German dialect, Alsatian, and architecture and traditions with strong German roots. Stop in Colmar, a city with unique architecture, colorful half-timbered houses, and canals that cross it. It is known for its white wine and gastronomy. We will have time to stroll and have lunch in peace. In the afternoon, we visit two of the most beautiful villages in Alsace: Riquewihr, a medieval village with cobblestone streets and colorful houses that look like they are out of a fairy tale, and Ribeauville, with its historic center and ruined castles overlooking the town. Arrival in Strasbourg at the end of the afternoon. We suggest a walk around the cathedral area and Petite France, full of charming places and small restaurants."
                            },
                            {
                                name: "06 - Sunday. Strasbourg - Freiburg - Triberg - Gengenbach - Baden Baden",
                                description: "Time in Strasbourg, the capital of Alsace. The city is home to the European Parliament. Its historic center is beautiful, with its picturesque medieval streets, impressive Gothic cathedral, canals, and flowers. We include a boat ride through the canals of Petite France. In the mid-morning, we move to Germany to explore the beautiful mountain landscapes and villages of the Black Forest. In Freiburg, we have time for lunch and a walk through this university town with its medieval center and cathedral. Amid very beautiful landscapes, we continue to Triberg, one of the most picturesque villages in the Black Forest known for its cuckoo clocks. We include entry to the Triberg Waterfalls, the highest in Germany, accessed by a beautiful nature trail. We continue to Gengenbach, a small town with a beautiful central square. Accommodation in Baden-Baden, a spa town that attracted nobility and high bourgeoisie from all over Europe; its casinos, architecture, and parks remind us of this."
                            },
                            {
                                name: "07 - Monday. Baden Baden - Speyer - Heidelberg - Frankfurt",
                                description: "A little time in Baden Baden to walk through its parks before heading to Rhineland-Palatinate. Speyer, on the banks of the Rhine, its great Romanesque cathedral is a World Heritage Site; you can visit it and stroll through the old quarter, where the Alte Munze tower stands out. We continue our route to Heidelberg, where by funicular (included) we will go up to its impressive castle (entrance to the castle's outer park, cellars, and pharmacy museum included), with great views over the city. After that, time to have lunch and stroll through the old town and its Markt Platz. In the mid-afternoon, we arrive in Frankfurt, a great financial city with its modern skyscrapers, old town, views over the Rhine River, and its well-known nightlife."
                            },
                            {
                                name: "08 - Tuesday. Frankfurt - Luxembourg - Reims - Paris",
                                description: "Today we travel to France via the Grand Duchy of Luxembourg, a small country with German (Luxembourgish dialect) and French-speaking populations. Arrival in this cosmopolitan city that combines a rich history with the modernity of being a financial center and home to European institutions. In addition to its old town, a World Heritage Site, its fortifications, the Grand Ducal Palace, and the Cathedral of Notre-Dame stand out. After lunch, we head to France. In the heart of the Champagne region, we stop in Reims; time to see its impressive Gothic cathedral, where many of the French kings were crowned. Arrival in Paris at the end of the day."
                            },
                            {
                                name: "09 - Wednesday. Paris",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Boat: Through Petite France in Strasbourg.\n" +
                            "Admissions: Carcassonne Castle, Carrieres de Lumiere in Les Baux, Savigny Medieval Castle, Triberg Waterfalls, Heidelberg Castle (including pharmacy and cellar).\n" +
                            "Funicular: Lyon, Heidelberg.\n"
                    },
                    {
                        name: "United Kingdom and France",
                        duration: "9 days",
                        days: [
                            {
                                name: "01 - TUE / SAT. Paris",
                                description: "Welcome! Transfer to the hotel and free time. In the afternoon, you will receive information for the start of your circuit, or you can check the information boards located at the hotel reception."
                            },
                            {
                                name: "02 - WED / SUN. Paris",
                                description: "We get in touch with the history of France in the immense Palace of Versailles, with its beautiful gardens (entrance included). After that, returning to Paris, we head to Montmartre and take the funicular (included) to this bohemian neighborhood known for its painters, artists, and cabarets. Lunch included before starting the panoramic tour of the city with a local guide, to see its impressive avenues and monuments. Return to the hotel. Optionally, you can attend a variety show."
                            },
                            {
                                name: "03 - THU / MON. Paris - Rouen",
                                description: "We head to the Eiffel Tower; we include the ticket to the second floor of this impressive metal monument. After that, we sail on the Seine River, a small cruise of about an hour and a quarter. Free time afterward. Around 4:00 p.m., we will leave Paris for Normandy. Rouen, free time to stroll through the historic center of the capital of Normandy, an active city whose origin dates back to Roman times, with charming medieval architecture and an impressive Gothic cathedral. Note: Sometimes the wait to go up the Eiffel Tower is long, so the ascent can be made at a different time (or on Sunday) if deemed appropriate for organizational reasons."
                            },
                            {
                                name: "04 - FRI / TUE. Rouen - Canterbury - London",
                                description: "Time for a walk through the center of Rouen. After that, we travel to the north of France, where in Calais we take one of the modern ferries that cross the English Channel. Lunch included. Once in England, we stop in Canterbury, the religious capital of England with a very attractive historic center. Upon our arrival in London, before going to the hotel, you will have free time in the Tower Bridge area, one of the city's emblematic symbols. Transfer to the hotel and accommodation."
                            },
                            {
                                name: "05 - SAT / WED. London",
                                description: "We are going to discover London. We include a panoramic tour with a local guide to learn about its history, the Parliament area, the Thames, its lively streets. We will stop next to Buckingham Palace, where we will be able to see the famous Changing of the Guard (on days when it takes place). At nightfall, we will meet at a meeting point in Piccadilly to take a walk through Leicester Square, China Town, and Soho, with its atmosphere, its theaters, and its nightlife. Dinner included before returning to the hotel."
                            },
                            {
                                name: "06 - SUN / THU. London - Cambridge - York - Durham",
                                description: "We leave London towards the north of England. In Cambridge, we have time to admire its beautiful colleges. After lunchtime, we continue to York, its cathedral, its atmosphere, its Roman-origin walls, its busy shopping streets will leave a very pleasant memory. Time to walk around. After that, we continue north. Durham. Arrival in this beautiful small medieval city full of life with its cathedral opposite the castle."
                            },
                            {
                                name: "07 - MON / FRI. Durham - Alnwick - Edinburgh",
                                description: "We travel to Scotland. We stop in Alnwick, a picturesque city with beautiful gardens and its large medieval castle where part of the Harry Potter movie was filmed. We then continue to Edinburgh, arriving at midday. We are in the capital of Scotland and one of the most active cities in northern Europe; the monumental center has been declared a World Heritage Site by UNESCO; the castle dominates the city, its steep streets, its parks. Panoramic tour with a local guide included."
                            },
                            {
                                name: "08 - TUE / SAT. Edinburgh - Pitlochry - Inverness - Loch Ness - Glasgow",
                                description: "Today we have an exciting scenic stage through the north of Scotland (the Scottish Highlands). Time for a walk and a coffee in Pitlochry, a charming village. Pass through the city of Inverness in the north of Scotland, located very close to the mysterious Loch Ness. After lunchtime, we include the entrance to the medieval castle of Urquhart, from where we will later embark to take a boat ride on the dark waters of the lake. We will pass by Fort Augustus with its lock system and Fort William, a tourist town at the foot of Ben Nevis, the highest peak in the United Kingdom. We return south through high plateaus where it is not difficult to see snow and then skirt Loch Lomond. Glasgow, arrival at the end of the day for a short walk through the center and accommodation. VERY IMPORTANT NOTE: Groups arriving in Edinburgh from November 15 to the end of February, due to the short length of the days and frequent snow problems on the roads, the stage will be the following: Departure from Edinburgh to Pitlochry, a picturesque town in the center of the Scottish region where most whisky is produced. We visit a traditional whisky distillery in the area (entrance included). We continue to Stirling, an impressive historic center dominated by the large castle, one of the most important in Scotland, having witnessed numerous historical events, including not only historical battles but also the coronation of several of its kings. Reservations and tickets included. Time for lunch. After that, we stop to photograph The Kelpies, huge sculptures of two water horse heads from Scottish mythology. Continue to Glasgow, time to walk through the city center and accommodation."
                            },
                            {
                                name: "09 - WED / SUN. Glasgow",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        notes: "VERY IMPORTANT NOTE: Groups arriving in Edinburgh from November 15 to the end of February, due to the short length of the days and frequent snow problems on the roads, the stage will be the following:\n" +
                            "Departure from Edinburgh to Pitlochry, a picturesque town in the center of the Scottish region where most whisky is produced. We visit a traditional whisky distillery in the area (entrance included). We continue to Stirling, an impressive historic center dominated by the large castle, one of the most important in Scotland, having witnessed numerous historical events, including not only historical battles but also the coronation of several of its kings. Reservations and tickets included. Time for lunch. After that, we stop to photograph The Kelpies, huge sculptures of two water horse heads from Scottish mythology. Continue to Glasgow, time to walk through the city center and accommodation.",
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Includes arrival transfer.\n" +
                            "Panoramic tour in: Paris, London, Edinburgh.\n" +
                            "Excursion: Versailles in Paris.\n" +
                            "Boat: On the Seine River in Paris, on Loch Ness.\n" +
                            "Night transfer: Tower Bridge in London, Soho district in London.\n" +
                            "Transfer: Montmartre district in Paris, British Museum/Camden in London.\n" +
                            "Tickets: Palace of Versailles and Eiffel Tower in Paris, Urquhart Castle.\n" +
                            "Ferry: Calais (FR) - Dover (UK).\n" +
                            "Funicular: Montmartre in Paris.\n" +
                            "2 Lunches included in: Paris, English Channel.\n" +
                            "1 Dinner included in: London.\n"
                    },
                ],
            },
            {
                name: "Europe 12 to 18 days",
                flag: "europe_3.png",
                data: [
                    {
                        name: "Classic Europe",
                        duration: "14 days",
                        days: [
                            {
                                name: "Day 1 - Saturday. Madrid",
                                description: "Welcome to Madrid! Transfer to the hotel. In the afternoon, we will take a panoramic tour to see the most representative monuments of the city. In the evening, we include a transfer to Plaza Mayor, where you can have dinner and enjoy some wine at one of the traditional taverns."
                            },
                            {
                                name: "Day 2 - Sunday. Madrid - Toledo - Madrid",
                                description: "We include an excursion to the historic city of Toledo, protected by the Tagus River. Its medieval streets tell stories of Christians, Jews, and Muslims. Free afternoon in Madrid. Tonight, if you wish, we offer an optional flamenco show."
                            },
                            {
                                name: "Day 3 - Monday. Madrid - Zaragoza - Barcelona",
                                description: "We depart towards Aragón, crossing the lands of Castilla. In Zaragoza, we make a brief stop to visit the Basilica del Pilar. Arrival in Barcelona and a brief panoramic tour by coach through its main streets to get acquainted with the Catalan capital, open to the Mediterranean Sea, famous for Gaudí, its modernist architecture, its Ramblas, and the memories of the Universal Expositions and Olympics held here. Note: Sometimes, the visit may be done the next morning."
                            },
                            {
                                name: "Day 4 - Tuesday. Barcelona - French Riviera",
                                description: "Free time in Barcelona, where you can walk through the Gothic Quarter, Las Ramblas, and visit the Cathedral. At 12:00 p.m., we depart for France and the French Riviera, crossing the French Provence. Stop en route for lunch. Arrival in Niceat the end of the day. Note: On some dates, accommodation may be in a nearby area on the French Riviera."
                            },
                            {
                                name: "Day 5 - Wednesday. French Riviera - Monaco - Venice",
                                description: "This morning, we explore the French Riviera; we spend some time in Nice to enjoy the Promenade des Anglais. We travel through impressive landscapes to the independent Principality of Monaco; en route, we learn about traditional perfume-making techniques. We continue through magnificent landscapes along the Italian Riviera, where the Alps meet the Mediterranean. Arrival in Venice at the end of the day. Accommodation in the Mestre area."
                            },
                            {
                                name: "Day 6 - Thursday. Venice",
                                description: "In the morning, we include a boat transfer to the San Marcos area in Venice, a city built on over 100 islands connected by more than 400 bridges. We include a guided walking tour of this area of the city of canals. Free time in the afternoon. You can enjoy a gondola ride if you wish."
                            },
                            {
                                name: "Day 7 - Friday. Venice - Verona - Lake Garda - Innsbruck",
                                description: "We leave Venice for Verona, where we have time to explore the city of Romeo and Juliet and its significant Roman amphitheater. Then, we travel along the shores of Lake Garda, one of the most beautiful Alpine lakes. We have time in the charming village of Malcesine, from where we take a boat to Limone, a picturesque village surrounded by stunning mountain landscapes that descend into the lake. We continue to Austria. Arrival in Innsbruck in the mid-afternoon with time to explore its beautiful historic center."
                            },
                            {
                                name: "Day 8 - Saturday. Innsbruck - Munich - Regensburg - Prague",
                                description: "After breakfast, we head to Germany, the Bavarian region. We stop in Munich, the Bavarian capital, known for its baroque architecture, traditional breweries, and beautiful parks. We suggest visiting Marienplatz (the city's main square) and the Cathedral. Time for lunch. We continue to Regensburg, a small town on the banks of the Danube River with a medieval center declared a UNESCO World Heritage Site. Time for a stroll. Then, we head to the Czech Republic. Arrival in Prague at the end of the day."
                            },
                            {
                                name: "Day 9 - Sunday. Prague",
                                description: "In the morning, we include a panoramic tour of this city, one of the most beautiful in Europe, where highlights include the Castle, the Town Hall Square, and the stunning Charles Bridge. We finish the day with a night transfer to the city center, where many traditional beer halls are located, and you can have dinner."
                            },
                            {
                                name: "Day 10 - Monday. Prague - Lednice - Gyor - Budapest",
                                description: "We leave Prague early. In the Moravian region, we visit Lednice, a city declared a UNESCO World Heritage Site. Time to explore its gardens and admire its castle. Passing through Slovakia, we enter Hungary. Gyor, time for lunch and a stroll through the city of four rivers. We continue to Budapest, arriving in the mid-afternoon. In the evening, we include a transfer to the pedestrian area filled with small traditional restaurants, many with music."
                            },
                            {
                                name: "Day 11 - Tuesday. Budapest",
                                description: "We include a panoramic tour of the city crossed by the Danube. We will visit Buda with its old town and Pest with its shopping areas. Free afternoon to enjoy the charm of the Danube city."
                            },
                            {
                                name: "Day 12 - Wednesday. Budapest - Bratislava - Vienna",
                                description: "We leave early from Budapest. We travel to Bratislava, the capital of Slovakia, a city along the Danube with an attractive historic center. Time to explore the city and have lunch. After lunch, we continue to Vienna. Upon arrival, we include a panoramic tour of Vienna with a local guide. In the evening, we include a transfer to the City Hall Square."
                            },
                            {
                                name: "Day 13 - Thursday. Vienna",
                                description: "Free day in the Austrian capital. You can enjoy, at your own pace, the charm of this beautiful city. Time to stroll and discover unforgettable places."
                            },
                            {
                                name: "Day 14 - Friday. Vienna",
                                description: "After breakfast, end of our services."
                            }
                        ],
                        services_included: "Travel by coach with a guide in Spanish and Portuguese, basic travel insurance, hotel, and buffet breakfast.\n" +
                            "Arrival transfer.\n" +
                            "Panoramic tours in: Madrid, Barcelona, Venice, Prague, Budapest, Vienna.\n" +
                            "Excursion: Toledo in Madrid.\n" +
                            "Boat: Tronchetto / St. Mark's Square / Tronchetto in Venice, Malcesine / Limone / Malcesine on Lake Garda.\n" +
                            "Night transfer: Plaza Mayor in Madrid, Historic Center in Prague, Historic Center in Budapest, City Hall Square in Vienna.\n" +
                            "Entrances: Murano glass factory in Venice.\n"
                    },
                    {
                        name: "Beauties of Europe",
                        duration: "14 days",
                        days: [
                            {
                                name: "01 - FRI. Vienna.",
                                description: "Welcome. Transfer to the hotel and free time. In the afternoon, you will receive information about the start of the tour, either through the information boards located at the hotel reception."
                            },
                            {
                                name: "02 - SAT. Vienna.",
                                description: "We include a panoramic tour of the city with a local guide. You will see its majestic avenues, beautiful palaces, large parks, and the historic center. Free afternoon. In the evening, we include a transfer to the Grinzing district, an old wine-producing village annexed to the city, famous for its traditional taverns. Dinner included in one of them."
                            },
                            {
                                name: "03 - SUN. Vienna - Budapest.",
                                description: "After approximately 3 hours of travel, we arrive in BUDAPEST, the capital of Hungary. We first head to the lively Vaci Utca area. Lunch included. Later, we have a boat excursion to discover the city from the Danube River, with spectacular views of historic Buda on one bank and cosmopolitan Pest on the other. Afterwards, we will begin a panoramic tour of the city, known as the Queen of the Danube. Free time. In the evening, there will be an optional opportunity to attend a Magyar show with dinner included."
                            },
                            {
                                name: "04 - MON. Budapest - Bratislava - Prague.",
                                description: "We leave early from Budapest towards BRATISLAVA, the capital of Slovakia, a city by the Danube with an attractive historic core. Free time to explore the city and have lunch. Then, we continue towards the Czech Republic, arriving in PRAGUE. In the late afternoon, we include a transfer to the Old Town Square area, full of life and tourist activity—a fantastic area to get to know Prague. Dinner included."
                            },
                            {
                                name: "05 - TUE. Prague.",
                                description: "Today we will explore this capital, a Baroque jewel in Europe. We will take a panoramic tour with a local guide, who will tell us about the city and show us its main monuments; during the tour, we will enter Prague Castle (entrance included). Then, we include a wonderful boat ride on the Vltava River. Lunch included. Free afternoon."
                            },
                            {
                                name: "06 - WED. Prague - Munich - Innsbruck.",
                                description: "We leave early and travel to Bavaria, Germany. Arrival in MUNICH at noon, with time for a walk through its historic center and for lunch. In the afternoon, we travel to Austria, arriving in INNSBRUCK. Time for a walk in the capital of Tyrol, in the heart of the Alps. Tonight, we include a typical dinner with a Tyrolean folk show, full of color, rhythm, and joy."
                            },
                            {
                                name: "07 - THU. Innsbruck - Venice.",
                                description: "Through beautiful Alpine landscapes, we travel to Italy. In VENICE, we include a boat transfer to the area of St. Mark's Square, where we take a guided walking tour of the city of canals. You will also be able to admire the Murano glassmaking process. Lunch included. Free time afterwards—perhaps a gondola ride? Accommodation in the Mestre area."
                            },
                            {
                                name: "08 - FRI. Venice - Lugano - Lucerne - Zurich.",
                                description: "We depart along the Po Valley towards Switzerland, in a stage of great scenic beauty, entering the region of the Alpine lakes. ARRIVAL in LUGANO, with time to stroll in the capital of the Italian-speaking Swiss canton, by the shores of the great lake. After lunch, we cross the Alps through the San Gotthard tunnel. Arrival in LUCERNE in the mid-afternoon. We will stop by the Lion Monument and have free time in this beautiful Swiss city by the shores of Lake Lucerne. Arrival in ZURICH in the late afternoon, with a walk along its lake. Dinner included."
                            },
                            {
                                name: "09 - SAT. Zurich - Strasbourg - Paris.",
                                description: "We depart from Zurich towards the French region of Alsace to visit its capital, STRASBOURG, a charming city that combines French and German influences. Free time to explore the city. Lunch included. Then, we continue our route to PARIS, arriving at the end of the day."
                            },
                            {
                                name: "10 - SUN. Paris.",
                                description: "Visit to the Palace of Versailles (entrance included), with its beautiful gardens. Then, we return to Paris and head to Montmartre, going up by funicular (included) to this bohemian neighborhood known for its painters, artists, and cabarets. Lunch included before starting a panoramic tour of the city with a local guide, to discover its impressive avenues and monuments. Return to the hotel. Optionally, you can attend a variety show."
                            },
                            {
                                name: "11 - MON. Paris - Rouen.",
                                description: "We transfer to the Eiffel Tower, including a ticket to go up to the second floor of this impressive metal monument. Afterwards, we sail on the Seine River on a small cruise of about an hour and a quarter. Free time afterwards. Around 4:00 p.m., we leave Paris for Normandy. In ROUEN, free time to stroll through the historic center of the capital of Normandy, an active city whose origins date back to Roman times, with charming medieval architecture and an impressive Gothic cathedral."
                            },
                            {
                                name: "12 - TUE. Rouen - Canterbury - London.",
                                description: "Free time for a walk in the center of Rouen. Then, we travel towards the north of France, where in Calais we take one of the modern ferries that cross the English Channel. Lunch included. Already in England, we stop in CANTERBURY, the religious capital of England, with a very attractive historic center. Upon arrival in LONDON, before going to the hotel, you will have free time in the TOWER BRIDGE area, one of the city's iconic symbols. Transfer to the hotel and accommodation."
                            },
                            {
                                name: "13 - WED. London.",
                                description: "We will get to know London. We include a panoramic tour with a local guide to learn about its history, the Parliament area, the Thames, and its lively streets. We will stop by Buckingham Palace, where we can see the famous Changing of the Guard (on days when it takes place). At dusk, we will meet at a meeting point in Piccadilly to take a walk through Leicester Square, Chinatown, and Soho, with their atmosphere, theaters, and entertainment. Dinner included before returning to the hotel."
                            },
                            {
                                name: "14 - THU. London.",
                                description: "In the morning, we visit the British Museum, one of the main and oldest museums in the world, where we can see traces of cultures from around the world. At lunchtime, we will provide a transfer to the Camden area, a district famous for its shops and markets, where you can see items from cultures from around the world. Free afternoon. End of our services"
                            }
                        ],
                        services_included: "Coach tour with guide in Spanish and Portuguese, basic travel insurance, hotel accommodation, and buffet breakfast.\n" +
                            "Includes arrival transfer.\n" +
                            "Panoramic tours in: Vienna, Budapest, Prague, Venice, Paris, London.\n" +
                            "Excursion: Versailles in Paris.\n" +
                            "Boat trip: Danube River in Budapest, cruise on the Vltava River in Prague, Tronchetto/St. Mark's Square/Tronchetto in Venice, on the Seine River in Paris.\n" +
                            "Night transfers: Grinzing district in Vienna, historic center of Prague, Tower Bridge in London, Soho district in London.\n" +
                            "Transfers: Montmartre district in Paris, British Museum/Camden in London.\n" +
                            "Entries: Prague Castle, Tyrolean show, Murano glass factory in Venice, Palace of Versailles, and Eiffel Tower in Paris.\n" +
                            "Ferry: Calais (FR)-Dover (UK).\n" +
                            "Funicular: Montmartre in Paris.\n" +
                            "6 lunches included in: Budapest, Prague, Venice, Strasbourg, Paris, English Channel.\n" +
                            "5 dinners included in: Vienna, Prague, Innsbruck, Zurich, London.\n"
                    },
                    {
                        name: "Fairy Tale Tour",
                        duration: "14 days",
                        days: [
                            {
                                name: "01 THU. Rome.",
                                description: "Welcome. Transfer to the hotel and free time."
                            },
                            {
                                name: "02 FRI. Rome.",
                                description: "Panoramic tour of the capital of Italy, ending at St. Peter's Square in Vatican City. Evening transfer to Trastevere."
                            },
                            {
                                name: "03 SAT. Rome.",
                                description: "Free day."
                            },
                            {
                                name: "04 MON. Rome - Florence.",
                                description: "We travel to Tuscany. In Florence, after a stop at the Michelangelo Viewpoint to enjoy a beautiful view of the city, we take a panoramic tour. Free afternoon."
                            },
                            {
                                name: "05 TUE. Florence - Venice.",
                                description: "We leave Florence heading north, crossing the Apennines. We head to the center of Venice by boat and take a walking tour of the city of canals, where you can also see the traditional glass-blowing techniques. Accommodation in Mestre."
                            },
                            {
                                name: "06 WED. Venice - Lugano - Zurich.",
                                description: "A stage of great scenic beauty in Switzerland, with towns between lakes and the Alps. Stops in Lugano and Lucerne. Arrival in Zurich late in the afternoon."
                            },
                            {
                                name: "07 THU. Zurich - Basel - Colmar - Riquewihr - Ribeauville - Strasbourg.",
                                description: "Lunch in Switzerland, on the banks of the Rhine River, which historically marks the border between Germany and France. We visit Basel, with its beautiful historic center and cathedral. Entering France, we will discover the heart of the Alsace region, with its unique personality and deep-rooted Germanic traditions. We will stop in the beautiful villages of Riquewihr, Ribeauville, and the capital, Strasbourg."
                            },
                            {
                                name: "08 FRI. Strasbourg - Freiburg - Triberg - Gengenbach - Baden-Baden.",
                                description: "We will explore Strasbourg with a boat ride through the canals of Petite France. We continue our journey into Germany's Black Forest and its well-kept villages: Freiburg, Triberg, where we will visit the highest waterfalls in Germany, Gengenbach, and finally, we will stay in the spa town of Baden-Baden."
                            },
                            {
                                name: "09 SAT. Baden-Baden - Speyer - Heidelberg - Frankfurt.",
                                description: "We will traverse beautiful vineyard landscapes and medieval towns along the banks of the Rhine River to Frankfurt. We will stop in Speyer, known for its ancient cathedral. We will take the Heidelberg funicular, from which we will enjoy spectacular views and visit the castle, its wine cellars, and museum. Arrival in Frankfurt in the mid-afternoon. Time to stroll around."
                            },
                            {
                                name: "10 SUN. Frankfurt - Cruise - Cochem - Cologne - Dortmund.",
                                description: "Today, we will explore the Rhine and Moselle Valleys. We include a cruise through the most beautiful part of the Rhine. We will have lunch in Cochem, on the banks of the Moselle River. Following the river, we arrive in Cologne, with time to visit its impressive iconic cathedral. Accommodation in Dortmund."
                            },
                            {
                                name: "11 MON. Dortmund - Fairy Tale Route - Hannover.",
                                description: "We take a magical stage, recalling the tales of the Brothers Grimm and following the Fairy Tale Route. We will pass through Marburg, with its Rapunzel Tower (entrance included), through the forests of Hansel and Gretel, visit Cinderella's Castle in Polle, and follow the steps of the Pied Piper of Hamelin in Hamelin. We will also stop in Hoster, a small town with an ancient monastery. Accommodation in Hannover."
                            },
                            {
                                name: "12 TUE. Hannover - Goslar - Quedlinburg - Berlin.",
                                description: "We explore one of the most beautiful regions of Germany, known for its medieval towns, and stop in Goslar and Quedlinburg, charming historic towns with half-timbered houses and beautiful squares. Arrival in Berlin in the mid-afternoon."
                            },
                            {
                                name: "13 WED. Berlin.",
                                description: "Panoramic tour of the capital of Germany, including the Holocaust Memorial and the Berlin Wall Memorial Museum."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, and buffet breakfast.\n" +
                            "Includes arrival transfer.\n" +
                            "Boat: Tronchetto/St. Mark's Square/Tronchetto in Venice, through the Rhine Valley.\n" +
                            "Panoramic tour in: Rome, Florence, Venice, Berlin.\n" +
                            "Night Transfer: Trastevere in Rome.\n" +
                            "Michelangelo Viewpoint in Florence.\n" +
                            "Entries: Murano glass factory in Venice, Triberg waterfalls, Heidelberg Castle including the pharmacy and the wine cellar, Rapunzel Tower in Trendelburg, Holocaust Memorial; Berlin Wall Museum.\n" +
                            "Funicular: Heidelberg."
                    },
                    {
                        name: "Atlantic Europe",
                        duration: "14 days",
                        days: [
                            {
                                name: "01 THU. Mont Saint Michel - St. Malo - Dinan - Rennes.",
                                description: "We disembark in Normandy, France. We travel to the rocky island of Mont Saint Michel, one of France's wonders. Later, we enter Brittany, stopping in the walled city of St. Malo and taking a stroll in Dinan. We stay overnight in Rennes, the capital of the region."
                            },
                            {
                                name: "02 FRI. Rennes - Angers - Tours - Villandry - Amboise.",
                                description: "Today, we will explore the Loire Valley with interesting towns and castles along the way. We stop in Angers, on the riverbank. We continue to Tours, the capital of the region. In the afternoon, we will visit the gardens of the Villandry Palace and then stay overnight in another city in the valley, Amboise. Dinner included."
                            },
                            {
                                name: "03 SAT. Amboise - Chenonceaux - Chambord - Paris.",
                                description: "A day to get to know the Loire Valley more thoroughly. After a walk around Amboise, we will enter the Château de Chenonceau. We continue to Chambord, the largest of the castles in the valley. Arrival in Paris in the mid-afternoon."
                            },
                            {
                                name: "04 SUN. Paris.",
                                description: "After breakfast, end of services."
                            },
                            {
                                name: "05 MON. Paris - Canterbury - London.",
                                description: "We head to the north of France, where we take a ferry to cross the English Channel and enter the United Kingdom. Stop in Canterbury, a place of great cultural, historical, and religious significance in the country. Continue to London. Arrival at the end of the day."
                            },
                            {
                                name: "06 TUE. London.",
                                description: "Panoramic tour of London in the morning and an evening stroll through Leicester Square, China Town, and Soho."
                            },
                            {
                                name: "07 WED. London.",
                                description: "Free day to explore the city."
                            },
                            {
                                name: "08 THU. London - Cambridge - York - Durham.",
                                description: "We head to the north of England and the beautiful university city of Cambridge. We continue to York, where we can walk around the city center and visit the impressive cathedral. We stay overnight in Durham, a small medieval city."
                            },
                            {
                                name: "09 FRI. Durham - Alnwick - Edinburgh.",
                                description: "After breakfast, we head to Alnwick, with its Harry Potter-style castle. We enter Scotland. In Edinburgh, its capital declared a UNESCO World Heritage site, we take a panoramic tour. Accommodation provided."
                            },
                            {
                                name: "10 SAT. Edinburgh - Pitlochry - Inverness - Loch Ness - Glasgow.",
                                description: "Today, we have an exciting scenic journey through the north of Scotland. After a stop in Pitlochry and by the lake in Inverness, we arrive at the mysterious Loch Ness. We visit the medieval Urquhart Castle and then take a boat trip on its dark waters. Following the roads of lakes and small mountain villages, we arrive in Glasgow. Due to weather difficulties, the winter route varies with two stops in Pitlochry, a whisky distillery, Stirling (castle entrance included), and the Kelpies before arriving in Glasgow."
                            },
                            {
                                name: "11 SUN. Glasgow - Cairnryan - Belfast - Dublin.",
                                description: "We head south to take a ferry to Northern Ireland. Time in Belfast, the capital of this country belonging to the United Kingdom, which reminds us of its difficult coexistence between Catholics and Protestants. Continue to the Republic of Ireland. Arrival in Dublin at the end of the day."
                            },
                            {
                                name: "12 MON. Dublin.",
                                description: "Panoramic tour of Dublin. Free afternoon."
                            },
                            {
                                name: "13 TUE. Dublin - Caernarfon - Liverpool.",
                                description: "In the morning, we board a ferry to cross from Ireland to the United Kingdom. After a little more than 3 hours of travel, we arrive in Wales. We will visit Caernarfon, with its impressive castle, before continuing to Liverpool, home of the Beatles."
                            },
                            {
                                name: "14 WED. Liverpool - Stratford - Oxford - Portsmouth.",
                                description: "We visit Stratford upon Avon, the city of Shakespeare. Then we travel to Oxford, whose life and recent history revolve around its prestigious university. Continue to Portsmouth to board a ferry and head to France. Overnight on board."
                            },
                            {
                                name: "15 THU. Mont St Michel - Fougeres - Chartres - Paris.",
                                description: "We disembark in Normandy, France. We travel to the rocky island of Mont Saint Michel, one of France's wonders. In Brittany, we will stop in the medieval city of Fougeres. After that, we will visit the magnificent Gothic cathedral of Chartres, a UNESCO World Heritage site. Continue to Paris. End of services."
                            }
                        ],
                        services_included: "Coach tour with a guide in Spanish and Portuguese, basic travel insurance, and buffet breakfast.\n" +
                            "Boat: On Loch Ness.\n" +
                            "Panoramic Tour in: London, Edinburgh, Dublin.\n" +
                            "Night Transfer: Soho in London.\n" +
                            "Entries: Urquhart Castle on Loch Ness.\n" +
                            "Ferry: Calais (FR)-Dover (UK), Scotland - Ireland, Ireland - Wales, Portsmouth (UK) - France.",
                        services_additional: "Entries: Gardens of Villandry Palace, Château de Chenonceau, Chambord Castle Park.\n" +
                            "1 Lunch or Dinner Included in: Amboise."
                    },
                    {
                        name: "Modern Europe",
                        duration: "15 days",
                        days: [
                            {
                                name: "01 - MON. Lisbon.",
                                description: "Welcome. Transfer to the hotel and free time. In the afternoon, you will receive information about the start of the tour through the information boards located at the hotel reception."
                            },
                            {
                                name: "02 - TUE. Lisbon.",
                                description: "In the morning, panoramic tour of Lisbon, the Portuguese capital of the 7 hills, with its narrow streets, tiles, and Atlantic light. We will visit the Belém neighborhood, the departure point for Portuguese navigators in the 15th century. Free afternoon to explore the Bairro Alto and its viewpoint. An optional excursion to Sintra, Cascais, and Estoril is also available. In the evening, return from the Plaza de los Restauradores to the hotel."
                            },
                            {
                                name: "03 - WED. Lisbon - Estremoz - Cáceres - Madrid.",
                                description: "Early departure from Lisbon to Madrid, passing through the Alentejo region and stopping in Estremoz. Then, crossing into Spain to visit Cáceres, where you can have lunch and explore its historic center. Arrival in Madrid at the end of the day."
                            },
                            {
                                name: "04 - THU. Madrid.",
                                description: "Panoramic tour of Madrid to discover the city center, Plaza Mayor, avenues, and Retiro Park. Includes entry to the Santiago Bernabéu Stadium. Free afternoon and transfer to Plaza Mayor. Dinner included."
                            },
                            {
                                name: "05 - FRI. Madrid - San Sebastián - Bordeaux.",
                                description: "Departure to San Sebastián to stroll along La Concha beach. Lunch included. Then, continue to France, arriving in Bordeaux in the late afternoon. Suggested walk through the city center."
                            },
                            {
                                name: "06 - SAT. Bordeaux - Chambord - Paris.",
                                description: "Journey through the Loire region. Visit to the Château de Chambord (entrance included). Lunch included. Arrival in Paris in the mid-afternoon."
                            },
                            {
                                name: "07 - SUN. Paris.",
                                description: "Visit to the Palace of Versailles (entrance included), followed by Montmartre with its funicular. Lunch included and panoramic tour of Paris with a local guide. Return to the hotel. Option to attend a variety show."
                            },
                            {
                                name: "08 - MON. Paris - Rouen.",
                                description: "Visit to the Eiffel Tower (ascent to the second floor included) and cruise on the Seine River. Departure to Rouen to explore its historic center. Possible rescheduling of the visit to the Eiffel Tower for organizational reasons."
                            },
                            {
                                name: "09 - TUE. Rouen - Canterbury - London.",
                                description: "Stroll through Rouen and then cross the English Channel by ferry to England. Visit to Canterbury and arrival in London, with free time in the Tower Bridge area. Transfer to the hotel."
                            },
                            {
                                name: "10 - WED. London.",
                                description: "Panoramic tour of London with a local guide, passing by the Parliament, the Thames, and Buckingham Palace. Walk through Piccadilly, Leicester Square, Chinatown, and Soho. Dinner included."
                            },
                            {
                                name: "11 - THU. London - Colchester - Crossing.",
                                description: "Visit to the British Museum and transfer to the Camden neighborhood. Departure to Colchester and embark on a ferry for an overnight crossing. Dinner included."
                            },
                            {
                                name: "12 - FRI. Crossing - Volendam - Amsterdam.",
                                description: "Arrival in Holland, visit to Volendam, and then to Amsterdam with a panoramic tour. Transfer to Rembrandtplein. Dinner included."
                            },
                            {
                                name: "13 - SAT. Amsterdam - Hamelin - Berlin.",
                                description: "Travel from Amsterdam to Berlin, stopping in Hamelin. Lunch included. Arrival in Berlin at the end of the day."
                            },
                            {
                                name: "14 - SUN. Berlin.",
                                description: "Guided tour of Berlin, including the historic center, Brandenburg Gate, and Holocaust Memorial. Dinner included in the Kantstrasse area."
                            },
                            {
                                name: "15 - MON. Berlin.",
                                description: "End of the tour after breakfast."
                            }
                        ],
                        services_included: "Coach tour with guide in Spanish and Portuguese, basic travel insurance, hotel accommodation, and buffet breakfast.\n" +
                            "Arrival transfer included.\n" +
                            "Panoramic tours in: Lisbon, Madrid, Paris, London, Amsterdam, Berlin.\n" +
                            "Excursion: Versailles in Paris.\n" +
                            "Boat trip: On the Seine River in Paris.\n" +
                            "Night transfers: From Plaza de los Restauradores in Lisbon, Plaza Mayor in Madrid, Tower Bridge and Soho neighborhood in London, Rembrandtplein in Amsterdam, and the Kantstrasse area in Berlin.\n" +
                            "Transfers included to: Retiro Park in Madrid, Montmartre in Paris, British Museum/Camden in London.\n" +
                            "Entrances included to: Santiago Bernabéu Stadium in Madrid, Chambord Palace Park, Palace of Versailles and Eiffel Tower in Paris, diamond factory in Amsterdam, Berlin Wall Museum, and Holocaust Memorial in Berlin.\n" +
                            "Ferry: Calais (France) - Dover (UK), Harwich (UK) - Hoek van Holland (Netherlands).\n" +
                            "Funicular: Montmartre in Paris.\n" +
                            "5 included lunches in: San Sebastián, Chambord, Paris, English Channel, Hamelin.\n" +
                            "5 included dinners in: Madrid, London, Ferry Colchester Volendam, Amsterdam, Berlin.\n"
                    },
                    {
                        name: "Nordic Jewels",
                        duration: "15 days",
                        days: [
                            {
                                name: "01 - TUE. Copenhagen.",
                                description: "Welcome. Transfer to the hotel and free time."
                            },
                            {
                                name: "02 - WED. Copenhagen - Rostock - Szczecin.",
                                description: "From Gedser, in southern Scandinavia, we board a ferry to cross the Baltic Sea to Germany. Free time in Rostock, the largest German port on the Baltic, with a beautiful city center. We continue to Poland to spend the night in Szczecin, a historic university city on the banks of the Oder River with a beautiful castle. Free time to stroll."
                            },
                            {
                                name: "03 - THU. Szczecin - Bydgoszcz - Torun - Warsaw.",
                                description: "Crossing the peaceful regions of western Poland, we make our first stop in Bydgoszcz, a historic city connecting the Vistula and Oder rivers; its beauty, parks, and canals invite a pleasant walk. We continue to the walled Gothic city of Torun, a UNESCO World Heritage site. We will arrive in Warsaw, the capital of Poland, at the end of the day."
                            },
                            {
                                name: "04 - FRI. Warsaw.",
                                description: "Panoramic tour of Warsaw, the capital of Poland."
                            },
                            {
                                name: "05 - SAT. Warsaw - Olstzynek - Elblag Canal - Malbork - Gdansk.",
                                description: "We explore northern Poland. In Olsztynek, we visit the open-air museum of folk architecture. We will stop at the Elblag navigable canal and its museum. In Malbork, we will enter its impressive medieval Teutonic Order castle. Accommodation in Gdansk, where we suggest a walk through its beautiful historic center."
                            },
                            {
                                name: "06 - SUN. Gdansk - Sopot - Ferry.",
                                description: "In Gdansk, we will visit the European Solidarity Center and the recently inaugurated World War II museum, which reminds us of the terrible impact of this war on the country. After some time to walk around, we head to nearby Sopot, an elegant seaside resort on the Baltic Sea, to board a ferry to Sweden. Enjoy the journey."
                            },
                            {
                                name: "07 - MON. Ferry - Nynashamn - Stockholm.",
                                description: "Around 12:00 p.m., arrival by ferry in Nynashamn, Sweden. Continue to Stockholm. Arrival around lunchtime. Free afternoon, where we recommend a stroll through its historic center and visiting its museums."
                            },
                            {
                                name: "08 - TUE. Stockholm.",
                                description: "Panoramic tour of Stockholm, a beautiful capital built on 14 islands."
                            },
                            {
                                name: "09 - WED. Stockholm - Ferry, Overnight on board.",
                                description: "Free day in Stockholm. In the late afternoon, we board a ferry for an overnight journey to Finland."
                            },
                            {
                                name: "10 - THU. Turku - Naantali - Helsinki.",
                                description: "Enjoy the Finnish coast with hundreds of islands from the ferry deck before disembarking. Arrival in Turku and free time for a walk. Stop in Naantali, a beautiful typical Nordic village. Continue to Helsinki and enjoy a free afternoon."
                            },
                            {
                                name: "11 - FRI. Helsinki.",
                                description: "Boat trip to the Suomenlinna Islands, protected by a fortress, now a UNESCO World Heritage site. Free time."
                            },
                            {
                                name: "11 - FRI. Helsinki - Tallinn.",
                                description: "After the boat trip to Suomenlinna Islands, we take a ferry to Estonia in the afternoon. Enjoy the coastal and marine scenery as we depart Helsinki. Arrival in Tallinn, the capital of Estonia."
                            },
                            {
                                name: "12 - SAT. Tallinn.",
                                description: "Panoramic tour of Tallinn, the capital of Estonia. Free afternoon."
                            }
                        ],
                        services_included: "Coach tour with a Spanish-speaking guide, basic travel insurance, and buffet breakfast.\n" +
                            "Includes:\n" +
                            "Arrival transfer.\n" +
                            "Boat: To the Suomenlinna Islands in Helsinki.\n" +
                            "Panoramic tour in: Warsaw, Stockholm.\n" +
                            "Entries: Open-air Museum of Folk Architecture in Olsztynek, Elblag Canal Museum, Malbork Castle, European Solidarity Center, and World War II Museum in Gdansk.\n" +
                            "Ferry: Gedser (Denmark) / Rostock (Germany), Sopot (Poland) / Nynashamn (Sweden), Stockholm (Sweden) / Turku (Finland).\n",
                        services_additional: "Boat: To the Suomenlinna Islands in Helsinki.\n" +
                            "Panoramic tour in: Tallinn.\n" +
                            "Ferry: Helsinki (Finland) / Tallinn (Estonia).\n" +
                            "Panoramic tour in: Riga.\n" +
                            "Transfer: Memorial in Salaspils.\n" +
                            "Entries: Turaida Castle.\n" +
                            "1 Lunch or Dinner Included in: Turaida\n"
                    },
                    {
                        name: "Fantastic Scandinavia",
                        duration: "16 days",
                        days: [
                            {
                                name: "01 - THU. Warsaw.",
                                description: "Welcome. Transfer to the hotel and free time."
                            },
                            {
                                name: "02 - FRI. Warsaw.",
                                description: "Panoramic tour of Warsaw, the capital of Poland."
                            },
                            {
                                name: "03 - SAT. Warsaw - Olstzynek - Elblag Canal - Malbork - Gdansk.",
                                description: "We explore northern Poland. In Olsztynek, we will visit the open-air museum of folk architecture. We will stop at the navigable Elblag Canal and its museum. In Malbork, we will enter its impressive medieval castle of the Teutonic Order. Accommodation in Gdansk, where we suggest a stroll through its beautiful historic center."
                            },
                            {
                                name: "04 - SUN. Gdansk - Sopot - Ferry.",
                                description: "In Gdansk, we will visit the European Solidarity Center and the recently inaugurated World War II museum, which reminds us of the terrible impact of this war on the country. After some time to walk around, we head to nearby Sopot, an elegant seaside resort on the Baltic Sea, to board a ferry to Sweden. Enjoy the journey."
                            },
                            {
                                name: "05 - MON. Ferry - Nynashamn - Stockholm.",
                                description: "Around 12:00 p.m., arrival by ferry in Nynashamn, Sweden. Continue to Stockholm. Arrival around lunchtime. Free afternoon, where we recommend a stroll through its historic center and visiting its museums."
                            },
                            {
                                name: "06 - TUE. Stockholm.",
                                description: "Panoramic tour of Stockholm, a beautiful capital built on 14 islands."
                            },
                            {
                                name: "06 - TUE. Stockholm - Mora.",
                                description: "After breakfast, we will take a panoramic tour of the city. Afterwards, free time to walk around this city of islands. Departure around 4:00 p.m. to Mora, a small town between lakes with a beautiful promenade."
                            },
                            {
                                name: "07 - WED. Mora - Funasdalen - Roros - Trondheim.",
                                description: "Between lakes and forests, we head to Norway. Stop at the popular ski resort of Funasdalen. Lunch in Roros, considered one of the most beautiful villages in Norway. Arrival in Trondheim and time to walk around the third-largest city in Norway, whose history dates back to the Viking era, featuring its cathedral, bridge, and vibrant nightlife."
                            },
                            {
                                name: "08 - THU. Trondheim - Kristiansund - Kvernes - Atlantic Road - Molde.",
                                description: "Today, we have a day of impressive fjord landscapes; some will be crossed by ferry, others along the ocean road, considered the most beautiful in Norway. Get your camera ready! We will also visit Kristiansund, a beautiful city in a stunning natural setting, visit the wooden church of Kvernes, and stay in Molde, a lively city by its fjord."
                            },
                            {
                                name: "09 - FRI. Molde - Geiranger - Briksdalsbre - Loen.",
                                description: "Another day of fantastic scenery through the Norwegian fjords, a UNESCO World Heritage site. We will take ferries, see snow-capped peaks, and stop at the spectacular Eagle's Viewpoint. Free time in Geiranger, one of the most beautiful scenic spots in the world. We will visit Briksdalsbre, an impressive Nordic glacier. Accommodation in the small town of Loen. Dinner included."
                            },
                            {
                                name: "10 - SAT. Loen - Bergen.",
                                description: "Following the fjord shores, between mountains and ferries, we arrive in Bergen at lunchtime. We include a funicular ride to reach Mount Floyen, where we can enjoy stunning views over the city. Free time."
                            },
                            {
                                name: "11 - SUN. Bergen - Eidfjord - Voringfossen - Geilo - Oslo.",
                                description: "We travel inland in Norway and, before entering the high, cold plateaus with lakes and snowfields, we stop in the small town of Eidfjord, by its fjord, and visit the impressive Voringfossen waterfall. Time for lunch at the Geilo ski resort. Arrival in Oslo, the capital of Norway, in the late afternoon. We recommend a walk through its center."
                            },
                            {
                                name: "12 - MON. Oslo - Ferry.",
                                description: "After breakfast, a panoramic tour of Oslo and free time to enjoy the city. After lunch, we board a night ferry to Denmark. Overnight on board."
                            },
                            {
                                name: "13 - TUE. Ferry - Copenhagen.",
                                description: "Arrival in Copenhagen in the morning and, after disembarking, a panoramic tour of the Danish capital. Free afternoon."
                            },
                            {
                                name: "14 - WED. Copenhagen - Rostock - Szczecin.",
                                description: "From Gedser, in southern Scandinavia, we board a ferry to cross the Baltic Sea to Germany. Time in Rostock, the largest German port on the Baltic with a beautiful center. We continue to Poland to spend the night in Szczecin, a historic university city on the Oder River with a beautiful castle. Free time to walk around."
                            },
                            {
                                name: "15 - THU. Szczecin - Bydgoszcz - Torun - Warsaw.",
                                description: "Crossing the peaceful regions of western Poland, we make our first stop in Bydgoszcz, a historic city connecting the Vistula and Oder rivers; its beauty, parks, and canals invite a stroll. We continue to the walled Gothic city of Torun, today a UNESCO World Heritage site. We arrive in Warsaw, the capital of Poland, at the end of the day."
                            },
                            {
                                name: "16 - FRI. Warsaw.",
                                description: "After breakfast, end of services."
                            }
                        ],
                        services_included: "Coach tour with guide in Spanish, basic travel insurance, and buffet breakfast.\n" +
                            "Includes:\n" +
                            "Arrival transfer.\n" +
                            "Panoramic tour in: Warsaw, Stockholm.\n" +
                            "Entries: Open-air Museum of Folk Architecture in Olsztynek, Elblag Canal Museum, Malbork Castle, European Solidarity Center, and World War II Museum in Gdansk.\n" +
                            "Ferry: Sopot (Poland) / Nynashamn (Sweden).\n",
                        services_additional: "Panoramic tour in: Stockholm, Oslo.\n" +
                            "Entries: Wooden church of Kvernes.\n" +
                            "Ferry: Fjord near Kristiansund, Romsdal Fjord, Storjfjorden Fjord.\n" +
                            "Funicular: Floyen Mountain funicular in Bergen.\n" +
                            "1 lunch or dinner included in: Loen.\n" +
                            "Panoramic tour in: Oslo, Copenhagen.\n" +
                            "Ferry: Oslo / Copenhagen.\n" +
                            "Ferry: Gedser (Denmark) / Rostock (Germany).\n"
                    }
                ]
            }
        ]
    }
}

export {
    awards as awards_en,
    about_us as about_us_en,
    destinations as destinations_en,
    tours as tours_en,
    footer as footer_en,
    gastronomy as gastronomy_en,
    menu as menu_en,
    world_destinations as world_destinations_en,
}