import React from 'react';
import styles from './styles.module.css';

function Day({data, index, header}) {
    return (
        <div className={styles.day} key={index}>
            <div>{data?.name}</div>
            <div>{data?.description}</div>
            {data?.departure &&
                <div className={'d-flex align-items-center'}>
                    <div className={'me-2'}><b>Departure: </b></div>
                    <div>{data?.departure}</div>
                </div>
            }
            {data?.route &&
                <div className={'d-flex align-items-start mt-2'}>
                    <div className={'me-2'}><b>Route: </b></div>
                    <div>{data?.route}</div>
                </div>
            }
            {data?.itinerary &&
                <div className={'d-flex align-items-start mt-2'}>
                    <div className={'me-2'}><b>Itinerary: </b></div>
                    <div>{data?.itinerary}</div>
                </div>
            }
            {data?.services_included &&
                <div className={'d-flex align-items-start mt-2'}>
                    <div className={'me-2'}><b>Included Services: </b></div>
                    <div>{data?.services_included}</div>
                </div>
            }
            {data?.services_not_included &&
                <div className={'d-flex align-items-start mt-2'}>
                    <div className={'me-2'}><b>Not Included Services: </b></div>
                    <div>{data?.services_not_included}</div>
                </div>
            }
            {data?.services_additional &&
                <div className={'d-flex align-items-start mt-2'}>
                    <div className={'me-2'}><b>Additional Services: </b></div>
                    <div>{data?.services_additional}</div>
                </div>
            }
            {data?.days?.map((day, index2) => (
                <Day key={index2} data={day} index={index2}/>
            ))}
        </div>
    )
}

export default function ExperienceBox({data, continent, countryName, index}) {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={`/packages/${continent}/${countryName}/${index + 1}.png`} alt={'header'}/>
                <div className={styles.headerText}>
                    <div className={styles.duration}>{data?.duration}</div>
                    <div>{data?.name}</div>
                </div>
            </div>
            <div className={styles.body}>
            {data?.days?.map((day, index) => (
                    <Day data={day} key={index} index={index} />
                ))}
                {data?.options?.map((day, index) => (
                    <Day data={day} key={index} index={index} />
                ))}
                {(data?.departure || data?.route || data?.itinerary) && <Day data={data}/>}
            </div>
        </div>
    )
}