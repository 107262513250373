import React from 'react';
import styles from "../welcome/index.module.css";

export default function LanguageContainer() {
    return (
        <div className={styles.languageContainer}>
            <div onClick={() => {
                localStorage.setItem("language_index", "2");
                window.location.reload();
            }}>CH
            </div>
            <div onClick={() => {
                localStorage.setItem("language_index", "1");
                window.location.reload();
            }}>EN
            </div>
            <div onClick={() => {
                localStorage.setItem("language_index", "0");
                window.location.reload();
            }}>ES
            </div>
        </div>
    )
}